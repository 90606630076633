import { motion } from "framer-motion";

type StatusProp = {
  closeStatus: () => void;
  selectStatus: (value: string) => void;
};
const Status = ({ closeStatus, selectStatus }: StatusProp) => {
  const statuses = [
    {
      label: "Dispatching",
      value: "dispatch"
    },
    {
      label: "Delivered",
      value: "complete"
    },
    {
      label: "Canceled",
      value: "cancel"
    },
    {
      label: "Rejected",
      value: "reject"
    },
  ];
  const animate = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: "100%",
    },
  };
  return (
    <motion.div
      variants={animate}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed sm:max-w-[600px] mx-auto z-10 bottom-0 inset-x-0 py-10 px-5 bg-white border rounded-t-[40px] overflow-hidden"
    >
      <div className="absolute inset-x-0 top-3 -translate-x-1/2 left-1/2 w-14 h-1.5 rounded-full bg-grey-30" onClick={closeStatus}
      ></div>
      <h2 className="font-bold mb-3">Update Status</h2>
      {statuses.map((status, index) => (
        <label
          htmlFor={String(index)}
          key={status.label}
          className="my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center"
        >
          <p>{status.label}</p>
          <input
            id={String(index)}
            name="status"
            type="radio"
            value={status.value}
            onChange={() => selectStatus(status.value)}
            onClick={closeStatus}
          />
        </label>
      ))}
    </motion.div>
  );
};

export { Status };
