import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "../../../composables";
import { useNavigate } from "react-router";
import { ArrowLeft } from "iconsax-react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useState } from "react";
import { useChangeUserPassword } from "utils/api";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { useQueryClient } from "react-query";
import { userChangePasswordSchema } from "validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { Success } from "assets/icon";

const LoginAndSecurity = () => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const form = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(userChangePasswordSchema),
  });
  const navigate = useNavigate();
  const client = useQueryClient();
  const { mutateAsync, isLoading } = useChangeUserPassword();
  const handleSubmit = async (data: any) => {
    const payload = {
      currentPassword: data.oldPassword,
      password: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Password Updated Successfully", {
            icon: Success,
          });
          navigate(-1);
        },
        onSettled: () => {
          client.refetchQueries("get_user");
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };
  return (
    <main className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Login & Security</h1>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            handleSubmit(data);
          })}
          className="mt-10"
        >
          {/* Old Password */}
          <FormField
            control={form.control}
            name="oldPassword"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Old Password</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="******"
                      type={showOldPassword ? "text" : "password"}
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                    <div
                      className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                      onClick={() => setShowOldPassword((prev) => !prev)}
                    >
                      {showOldPassword ? (
                        <LuEyeOff size={20}></LuEyeOff>
                      ) : (
                        <LuEye size={20}></LuEye>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* New password */}
          <FormField
            control={form.control}
            name="newPassword"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel> New Password</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="******"
                      type={showPassword ? "text" : "password"}
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                    <div
                      className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <LuEyeOff size={20}></LuEyeOff>
                      ) : (
                        <LuEye size={20}></LuEye>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* confirmPassword */}
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel> Confirm Password</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="******"
                      type={showConfirmPassword ? "text" : "password"}
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                    <div
                      className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      {showConfirmPassword ? (
                        <LuEyeOff size={20}></LuEyeOff>
                      ) : (
                        <LuEye size={20}></LuEye>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="mt-10 w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
          >
            {isLoading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Update"
            )}
          </Button>
        </form>
      </Form>
    </main>
  );
};

export default LoginAndSecurity;
