import { cn } from "../../../../../lib";


export function PredefinedQueryWidget({
  className,
  name,
  image,
}: {
  className: string;
  name: string;
  image: string;
}) {
  return (
    <div className={cn("w-fit flex items-center rounded-3xl justify-center h-10 px-2 py-1 gap-x-2", className)}>
      <img src={image} alt="l" className="h-6 w-6 rounded-full" />
      <p>{name}</p>
    </div>
  );
}
