import { useEffect, useState } from "react";

import VerificationInput from "react-verification-input";
import { resetVerificationOTP, verifyOTP } from "utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { cn } from "../../../lib";
import { Error, Success } from "assets/icon";

function Verification() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  // Access query parameters
  const userId = queryParams.get("userId");
  const userType = queryParams.get("userType");
  const [values, setValues] = useState("");

  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false); // 5 minutes in seconds
  const [loadingResendOtp, setLoadingResendOtp] = useState(false); // 5 minutes in seconds
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleResetOTP() {
    try {
      setLoadingResendOtp(true);
      await resetVerificationOTP({ userId }).then(() => {
        setLoadingResendOtp(false);
        toast.success("New verification code sent", {
          icon: Success,
        });
        setCountdown(300);
      });
    } catch (error) {
      setLoadingResendOtp(false);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleOTPVerification() {
    if (userId) {
      try {
        setLoadingVerifyOtp(true);
        await verifyOTP({ userId, otp: values }).then(() => {
          toast.success("Verification successful", {
            icon: Success,
          });
          handleSkipVerification();
          setLoadingVerifyOtp(false);
        });
      } catch (error) {
        setLoadingVerifyOtp(false);
        errorLogger(error);
        console.log(error);
      }
    } else {
      toast.error("No user Id found", {
        icon: Error,
      });
    }
  }

  const handleSkipVerification = () => {
    if (userType === "Customer") {
      navigate("/customer/home");
    }
    if (userType === "Vendor") {
      navigate("/vendor/home");
    }
    if (userType === "Rider") {
      navigate("/rider/home");
    }
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown > 3) {
        const updatedMinutes = Math.floor(countdown / 60);
        const updatedSeconds = countdown % 60;

        setMinutes(updatedMinutes);
        setSeconds(updatedSeconds);
        setCountdown(countdown - 1);
      } else {
        // Countdown has reached 3sec, you can perform additional actions here (e.g., reset OTP)
        handleResetOTP();

        //reload
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => clearInterval(countdownInterval); // Cleanup on component unmount
  }, [countdown, handleResetOTP]);

  // useEffect(() => {
  //   if (values.length > 3) {
  //     console.log("Fires");

  //     handleOTPVerification()
  //   }
  //   console.log(values.length);

  // }, [handleOTPVerification, values])

  return (
    <div className="w-full sm:max-w-[600px] mx-auto py-8 px-5">
      <div className="">
        <h1 className="text-xl font-bold text-grey-900">Verification</h1>
        <p className="mt-5 text-grey-200 text-sm">
          🔒 <span className="font-bold">Your JekaEat OTP Is:</span> Essential
          for Your Food Wallet Safety!
        </p>
        <p className="mt-2 text-grey-200 text-sm">
          🛡️Ensure secure transactions and prevent unauthorized orders. Your
          trust and safety matter to us.
        </p>
      </div>

      <div className="mt-10">
        <div className="py-8 flex justify-center px-2">
          <VerificationInput
            length={4}
            classNames={{
              container: "box-container",
              character: "character",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
              characterFilled: "character--filled",
            }}
            onChange={(value: string) => {
              setValues(value);
            }}
          />
        </div>
        <div className="my-10 text-grey-200 text-center">
          Code expires in {String(minutes).padStart(2, "0")}:
          {String(seconds).padStart(2, "0")}
        </div>
        <div className="mt-5 flex flex-col items-center">
          <button
            onClick={handleResetOTP}
            className="my-5 w-full py-2 px-3  text-grey-900"
          >
            {loadingResendOtp ? "Re-sending..." : "Resend Code"}
          </button>
          <button
            onClick={handleOTPVerification}
            disabled={values.length < 4}
            className={cn(
              values.length === 4
                ? "bg-primary-100"
                : "bg-gray-300 cursor-not-allowed",
              "w-full py-2 px-3 rounded-xl text-white"
            )}
          >
            {loadingVerifyOtp ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Verify Me"
            )}
          </button>

          <button
            onClick={handleSkipVerification}
            className="w-full py-2 px-3 rounded-xl mt-5  text-primary-100"
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
}

export default Verification;
