import { Button } from "../../../../composables";
import { IoIosStar, IoMdTime, IoIosArrowRoundBack } from "react-icons/io";
import { PiVanLight } from "react-icons/pi";
import { HiMiniMinusSmall, HiMiniPlusSmall } from "react-icons/hi2";
import Share from "../.././../../../assets/Svg/share.svg";
import { MealItemsEntity } from "types/global.interface";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addFoodItemsToCart,
  decreaseSingleCartItems,
  increaseSingleCartItems,
} from "Redux/actions/cart";
import { RootState } from "Redux/reducer";
import { ICartItems, MealsEntity } from "Redux/reducer/cart";
import ShareButtons from "components/composables/Share";
import { useState } from "react";
import { getNairaEquivalent, isWebview } from "utils/helper";
import vendorbg from "assets/images/vendorbg.jpg";
import { Error } from "assets/icon";

export function MealDetail({
  close,
  meal,
}: {
  close: () => void;
  meal: MealItemsEntity;
}) {
  const checkIsWebview = isWebview();

  const [isShareOpen, setIsShareOpen] = useState(false);
  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    return baseUrl;
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, currentOrderIndex } = useSelector(
    (state: RootState) => state.cart
  );
  const getSinglePersonItem = cartItems?.find(
    (item) => item.order_id === currentOrderIndex
  ) as ICartItems;
  const getMeal = getSinglePersonItem?.items?.find(
    (item) => item.product === meal._id
  ) as MealsEntity;

  function verifyCurrentVendor(vendorId: string) {
    if (cartItems.length > 0) {
      if (
        cartItems[0]?.items?.length > 0 &&
        cartItems[0]?.items[0]?.vendorId === vendorId
      ) {
        //check the owner of the item in cart
        return true;
      } else return false;
    } else return true;
  }
  const isGuest = location.pathname.includes("guest");
  const handleOrderItem = (item: MealItemsEntity) => {
    if (isGuest) {
      toast.error("Please login to place your order", {
        icon: Error,
      });
      navigate("/auth/signin");
      return;
    }
    if (!verifyCurrentVendor(item.vendor._id)) {
      toast.error(
        "You can't order from 2 different vendor, if you wish to continue do empty your cart",
        { icon: Error }
      );
      return;
    }
    console.log(item);
    const payload = {
      name: item.name,
      image: item.image,
      product: item._id,
      quantity: 1,
      unitPrice: item.unitPrice,
      vendorId: item.vendor._id,
    };
    dispatch(addFoodItemsToCart(payload));
  };
  const handleShare = () => {
    navigate(
      `/customer/vendor/${meal?.vendor?._id
      }?app_action_share=Check out this amazing Jekaeat partner restaurant: ${getBaseUrl()}/guest/vendor/${meal?.vendor?._id
      }`
    );
  };

  return (
    <div className="bg-white w-full fixed  inset-0 h-full max-w-[600px] mx-auto z-[9999]">
      <div className="w-full fixed h-[50%] inset-x-0 top-0 max-w-[600px] mx-auto">
        <img
          src={meal?.image?.url ?? vendorbg}
          alt="restaurant"
          className="w-full h-full object-cover"
        />
        <div className="w-full top-6 inset-x-0 absolute flex items-center justify-between px-5">
          <Button
            onClick={() => {
              close();
              // navigate(`/${isGuest ? "guest" : "customer"}/vendor/${meal.vendor._id}`)
            }}
            className="bg-white px-0 h-10 w-10 rounded-full p-1"
          >
            <IoIosArrowRoundBack className="text-[22px]" />
          </Button>
          <Button
            onClick={() => {
              if (checkIsWebview) {
                handleShare();
              } else {
                setIsShareOpen(!isShareOpen);
              }
            }}
            className="bg-white px-0 h-10 w-10 rounded-full p-1"
          >
            <img src={Share} alt="Share" className="text-[22px]" />
          </Button>
          {isShareOpen && (
            <div className="z-10 absolute right-5 top-12">
              <ShareButtons
                url={`${getBaseUrl}/guest/vendor/${meal.vendor._id}`}
                title={`Check out this amazing Jekaeat partner restaurant: ${getBaseUrl}/guest/vendor/${meal.vendor._id} `}
              />{" "}
            </div>
          )}
          {isShareOpen && (
            <div
              className="fixed inset-0 z-[9] bg-transparent"
              onClick={() => {
                setIsShareOpen(!isShareOpen);
              }}
            />
          )}
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-between max-w-[600px] mx-auto z-50 h-[60%] fixed inset-x-0 bottom-0 bg-white rounded-t-[2rem] py-6 px-5">
        <div className="flex flex-col gap-y-4 w-full items-start justify-start">
          <div className="flex items-center justify-between w-full">
            <p className="flex flex-wrap w-4/5 font-semibold">{meal.name}</p>

            <p className="font-semibold">
              ₦{getNairaEquivalent(meal.unitPrice)}
            </p>
          </div>

          <div className="flex items-center text-xs justify-around w-full">
            <div className="flex  font-light items-center gap-x-1">
              <IoIosStar className="text-lg text-yellow-500" />
              <span className="font-normal">4.5</span>
              <span className="text-gray-600">(0 reviews)</span>
            </div>

            <div className="flex items-center gap-x-2">
              <PiVanLight className="text-lg" />
              <p>₦350</p>
            </div>

            <div className="flex items-center gap-x-2">
              <IoMdTime className="text-lg" />
              <p>20 - 25 Min</p>
            </div>
          </div>

          <p className="text-xs">{meal?.description ?? "No Description"}</p>
        </div>

        <div className="w-full grid grid-cols-5 items-center gap-3">
          <div className="px-0 py-0 flex items-center col-span-2 border-black border rounded-xl h-11">
            <button
              onClick={() => {
                dispatch(
                  decreaseSingleCartItems(currentOrderIndex as string, meal._id)
                );
              }}
              className="w-1/3 items-center justify-center flex h-full"
            >
              <HiMiniMinusSmall className="text-xl" />
            </button>
            <span className=" h-full flex items-center justify-center w-1/3">
              {getMeal ? getMeal.quantity : 1}
            </span>
            <button
              onClick={() => {
                dispatch(
                  increaseSingleCartItems(currentOrderIndex as string, meal._id)
                );
              }}
              className="w-1/3 items-center justify-center flex h-full"
            >
              <HiMiniPlusSmall className="text-xl" />
            </button>
          </div>
          <Button
            disabled={!meal.inStock}
            onClick={() => handleOrderItem(meal)}
            className={`${meal.inStock ? "bg-primary-100" : "bg-gray-400 cursor-not-allowed"
              } rounded-xl text-white h-11 w-full col-span-3`}
          >
            {meal.inStock ? "Add to Cart" : "Not Available"}
          </Button>
        </div>
      </div>
    </div>
  );
}
