import { useState } from "react";
import Navbar from "../Navbar";
import FoodMenu from "./FoodMenu";
import { Link } from "react-router-dom";
import { Add } from "iconsax-react";
import { useFetchVendorMeals } from "utils/api";
import { useSelector } from "react-redux";
import { MenuListSkeleton } from "components/composables/Skeletons/menuList";
import { MealItemsEntity } from "types/global.interface";

export const Menu = () => {
  const { currentUser } = useSelector((state: any) => state.user);

  const [tab, setTab] = useState("mealKit");
  const [mealKit,] = useState<MealItemsEntity[] | undefined>([]);
  const [harvestPacks,] = useState<
    MealItemsEntity[] | undefined
  >([]);
  const { isLoading } = useFetchVendorMeals(currentUser._id);



  return (
    <main className="mx-auto px-5 pb-24">
      <Navbar />
      <h1 className="font-semibold text-xl">Menu</h1>

      {/* Tab Headers */}
      <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full">
        <div
          className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "mealKit" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
            }`}
          onClick={() => {
            setTab("mealKit");
          }}
        >
          Meal Kit
        </div>
        <div
          className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "harvestPacks"
            ? "bg-[#70AD43] text-white"
            : "text-[#6A6A6A]"
            }`}
          onClick={() => {
            setTab("harvestPacks");
          }}
        >
          Harvest Packs
        </div>
      </div>

      {/* Tabs Contents */}

      {!isLoading ? (
        <div>
          {
            <FoodMenu
              section={tab}
              meals={
                tab === "mealKit"
                  ? mealKit
                  : tab === "harvestPacks"
                    ? harvestPacks
                    : undefined
              }
            />
          }
        </div>
      ) : (
        [1, 2, 3].map((v) => <MenuListSkeleton key={v} />)
      )}

      {/* add new food */}
      <div className="fixed bottom-24 right-5 z-50">
        <Link
          to={`/vendor/add-menu?menutype=${tab !== "preOrder" ? "general" : "preorder"
            }`}
          className="p-5 flex rounded-xl bg-[#7BBE4A] shadow-[-1px_8px_10px_0px_#00000029] text-white"
        >
          <Add />
        </Link>
      </div>
    </main>
  );
};
