import { Bag2, Chart, Home, Note, ProfileCircle } from "iconsax-react";
import { Link, useLocation } from "react-router-dom";

type LinkType = {
  path: string;
  name: string;
  Icon: React.ElementType;
};

const links: LinkType[] = [
  {
    path: "/farmer/home",
    name: "Home",
    Icon: Home,
  },
  {
    path: "/farmer/orders",
    name: "Order",
    Icon: Bag2,
  },
  {
    path: "/farmer/menu",
    name: "Menu",
    Icon: Note,
  },
  {
    path: "/farmer/deliveries",
    name: "Deliveries",
    Icon: Chart,
  },
  {
    path: "/farmer/profile",
    name: "Profile",
    Icon: ProfileCircle,
  },
];

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <nav className="pb-3 pt-5 px-5 fixed bottom-0 inset-x-0 z-50 bg-white border-t border-t-[#e9e9e9] flex justify-between items-center">
      {links.map(({ path, name, Icon }) => (
        <Link to={path} key={name}>
          <div
            className={`flex flex-col items-center gap-y-2 ${pathname.includes(path) ? "text-[#70AD43]" : "text-grey-400"
              }`}
          >
            <Icon size={30} />
            <span>{name}</span>
          </div>
        </Link>
      ))}
    </nav>
  );
};

export default Navbar;
