import { useState } from "react";
import { Button } from "../../../../composables";
import { cn } from "../../../../../lib";
import { MainMeal } from "./MainMeal";
import { SideMeal } from "./SideMeal";
import { MealItemsEntity } from "types/global.interface";

export function FoodStack({ meals }: { meals: MealItemsEntity[] | undefined }) {
  const [active, setActive] = useState(0);
  const foodStack = ["Main Meals", "Side Meals"];
  return (
    <div className="w-full h-full pb-[15rem]">
      <div className="w-full bg-white py-2 grid grid-cols-2  gap-6">
        {foodStack.map((meal, index) => (
          <Button
            onClick={() => setActive(index)}
            key={meal}
            className={cn(
              "rounded-none py-1 border-b-2 border-gray-200",
              active === index && "border-[#7bbe4a]"
            )}
          >
            {meal}
          </Button>
        ))}
      </div>

      {active === 0 && <MainMeal meals={meals?.filter(v=>v.type === "main_meal")} />}
      {active === 1 && <SideMeal meals={meals?.filter(v=>v.type === "side_meal")} />}
    </div>
  );
}
