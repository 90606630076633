import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router";
import {
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  FormMessage,
  FormLabel,
  Button,
  VehicleType,
} from "../../../composables";
import { useForm } from "react-hook-form";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";

import { useGetRiderVehicle, useUpdateRiderVehicle } from "utils/api";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { errorLogger } from "utils/helper";
import { yupResolver } from "@hookform/resolvers/yup";
import { riderVehicleUpdateValidationSchema } from "validations";
import { Success } from "assets/icon";

const Vehicle = () => {
  const client = useQueryClient();
  const [isVehicleTypeOpen, setIsVehicleTypeOpen] = useState(false);
  const navigate = useNavigate();
  const { data: vehicleData } = useGetRiderVehicle();
  const form = useForm({
    defaultValues: {
      vehicleType: vehicleData?.vehicleType ?? "",
      plateNumber: vehicleData?.vehicleNumberPlate ?? "",
      driverLicense: vehicleData?.licenseNumber ?? "",
    },
    resolver: yupResolver(riderVehicleUpdateValidationSchema),
  });

  const { mutateAsync, isLoading } = useUpdateRiderVehicle();

  const handleSubmit = async (data: any) => {
    const payload = {
      licenseNumber: data.driverLicense,
      vehicleNumberPlate: data.plateNumber,
      vehicleType: data.vehicleType,
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Vehicle Info Updated Successfully", { icon: Success });
        },
        onSettled: () => {
          client.refetchQueries("get_user");
          navigate(-1);
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };

  return (
    <main className="p-5 min-h-screen">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Vehicle</h1>
      </div>

      {/* Vehicle Info edit form */}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            handleSubmit(data);
          })}
          className="mt-5"
        >
          <FormField
            control={form.control}
            name="vehicleType"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Vehicle Type</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Select Vehicle Type"
                    readOnly
                    type="text"
                    onFocus={() => {
                      setIsVehicleTypeOpen(true);
                    }}
                    className="w-full mt-2 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <AnimatePresence initial={false} mode="wait">
            {isVehicleTypeOpen && (
              <VehicleType
                closeVehicleType={() => {
                  setIsVehicleTypeOpen((prev) => !prev);
                }}
                selectVehicleType={(value) =>
                  form.setValue("vehicleType", value)
                }
              />
            )}
          </AnimatePresence>

          <FormField
            control={form.control}
            name="plateNumber"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs">Plate Number</FormLabel>
                <FormControl>
                  <Input
                    placeholder="ABC123456"
                    type="text"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="driverLicense"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs">Driver's License</FormLabel>
                <FormControl>
                  <Input
                    placeholder="123456789"
                    type="text"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* vehicle picture */}

          <Button
            type="submit"
            className="mt-10 w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
          >
            {isLoading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Update"
            )}
          </Button>
        </form>
      </Form>
    </main>
  );
};

export default Vehicle;
