import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../composables";
import { IoMdArrowBack } from "react-icons/io";
import pack from "../../../../../assets/images/bapack.png";
import { DeliveryTimeLine } from "../orderDetailWidgets";
import { OrderSummary } from "../../../_component";
import { useFetchSingleOrder } from "utils/api";
import { FaSpinner } from "react-icons/fa6";
import { digitFormatter, getNairaEquivalent } from "utils/helper";
import { calculateTotalPrice } from 'paystack-transaction-charges-to-cus';
import { SERVICE_CHARGE } from "utils/constant.util";

export function OrderDetail() {
  const navigate = useNavigate();

  const { id } = useParams();

  const { data, isLoading } = useFetchSingleOrder(id ?? "");
  const totalWithCharges = Math.ceil(calculateTotalPrice(getNairaEquivalent(data?.total) + getNairaEquivalent(data?.deliveryInfo?.fee) + getNairaEquivalent(data?.serviceFee)))
  const totalWithoutCharge = getNairaEquivalent(data?.total) + getNairaEquivalent(data?.deliveryInfo?.fee) + getNairaEquivalent(data?.serviceFee)


  if (isLoading) {
    return (
      <div className="grid place-content-center p-10">
        <FaSpinner />
      </div>
    );
  } else {
    return (
      <div className="w-full items-start justify-start  flex flex-col gap-y-6">
        <Button onClick={() => navigate(-1)} className="px-4 gap-x-2 h-fit">
          <IoMdArrowBack className="text-[22px]" />
          <p className="font-semibold text-[15px]">Order#{data?._id?.substring(0, 5)}</p>
        </Button>
        <div className="w-full px-4">
          <div className="w-full  bg-[#F2F9ED] h-28 rounded-3xl px-8 overflow-hidden relative">
            <div className="flex flex-col gap-y-2 h-full items-start justify-center w-fit">
              <p className="text-xs text-[#578735]">Order Status</p>
              <p className="font-semibold text-base text-[#446929] capitalize">
                {data?.status === "completed" ? "Completed" : data?.status}
              </p>
            </div>
            <div className="absolute bottom-0 right-4">
              <img src={pack} alt="pack" />
            </div>
          </div>
        </div>

        <div className="flex items-start px-4 pb-8 border-b-4 border-gray-300 justify-start w-full  flex-col gap-y-3">
          <div className="flex items-start justify-start flex-col gap-y-1">
            <p className="text-xs">Restaurant</p>
            <p className="font-medium">{data?.vendor.businessName}</p>
          </div>

          <div className="flex items-start justify-start flex-col gap-y-1">
            <p className="text-xs">Location</p>
            <p className="font-medium"> {data?.vendor?.address.streetAddress} </p>
          </div>
        </div>

        <DeliveryTimeLine updates={data?.statusUpdate as any[]} createdAt={data?.createdAt as string} />

        <div className="w-full flex flex-col gap-y-3 px-4 items-start justify-start">
          <p className="font-semibold">Order:#{data?._id?.substring(0, 5)}</p>
          {(data?.packages ?? []).map((item, key) => (
            <div className="w-full space-y-3  border-b border-gray-300">
              <h2 className="font-bold">Order {key + 1}</h2>
              {item.items?.map((v, key) =>
                <SingleOrderWidget
                  key={key}
                  order={v?.product?.name}
                  numOfOrders={v.quantity}
                  price={getNairaEquivalent(v.total)}
                />
              )}
            </div>
          ))}
        </div>

        {data?.status === "completed" && <OrderSummary
          orderFee={getNairaEquivalent(data?.total)}
          deliveryFee={getNairaEquivalent(data?.deliveryInfo.fee)}
          serviceCharge={getNairaEquivalent(data?.payment?.method === 'card' ? (totalWithCharges - totalWithoutCharge) + SERVICE_CHARGE : data.serviceFee)}
          total={totalWithCharges}

        />}
      </div>
    );
  }
}

function SingleOrderWidget({
  price,
  order,
  numOfOrders,
}: {
  price?: number;
  order: string;
  numOfOrders: number;
}) {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-x-2">
        <p className="w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
          {order} x{numOfOrders}
        </p>

      </div>

      <p>₦{digitFormatter(price ?? 0) || "N/A"}</p>
    </div>
  );
}
