export const VendorStatSkeleton = () => (
    <div className="mt-5 animate-pulse">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
        <div className="mt-2 h-6 bg-gray-200 rounded w-1/2"></div>
        <div className="mt-5 pb-10 border-b-4 border-gray-300 px-2 flex justify-between items-center h-72">
            <div className="h-full flex flex-col item-center gap-2">
                <div className="w-6 h-full rounded-full overflow-hidden flex flex-col justify-end bg-gray-300">
                    <div className="w-full bg-green-400 rounded-full"></div>
                </div>
                <div className="h-4 bg-gray-200 rounded"></div>
            </div>

        </div>
        <div className="flex justify-between items-center py-4 border-b border-gray-300">
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
        </div>
        <div className="flex justify-between items-center py-4 border-b border-gray-300">
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
        </div>
        <div className="flex justify-between items-center py-4 border-b border-gray-300">
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
            <div className="w-1/3 h-4 bg-gray-200 rounded"></div>
        </div>
    </div>
)