"use client";
import { motion } from "framer-motion";

import { Link, NavLink } from "react-router-dom";

const animate = {
  hidden: { x: "100%" },
  visible: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: { x: "100%", opacity: 0 },
};

const MobileNav = () => {
  return (
    <motion.nav
      initial={animate.hidden}
      animate={animate.visible}
      exit={animate.exit}
      className="fixed z-[99999] inset-x-0 inset-y-0 top-16  flex w-screen flex-col bg-white px-5 lg:px-10 lg:hidden"
    >
      <div className="h-16 bg-transparent"></div>
      <div className="flex-col flex text-[18px] font-medium gap-8">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "text-primary-100 font-bold" : "text-gray-500 hover:text-primary-100/50"
          }
        >
          Company
        </NavLink>
        <NavLink
          to="/vendors"
          className={({ isActive }) =>
            isActive ? "text-primary-100 font-bold" : "text-gray-500 hover:text-primary-100/50"
          }
        >
          Vendors
        </NavLink>
        <NavLink
          to="/riders"
          className={({ isActive }) =>
            isActive ? "text-primary-100 font-bold" : "text-gray-500 hover:text-primary-100/50"
          }
        >
          Riders
        </NavLink>
        <NavLink
          to="/newsletter"
          className={({ isActive }) =>
            isActive ? "text-primary-100 font-bold" : "text-gray-500 hover:text-primary-100/50"
          }
        >
          NewsLetter
        </NavLink>
      </div>

      <div className="flex flex-col items-center gap-4 w-full mt-8">
        <Link
          className="rounded-full border border-primary-100 w-full flex items-center justify-center text-primary-100 h-10"
          to={"/auth/signin"}
        >
          Sign In
        </Link>
        <Link
          className="rounded-full w-full flex items-center justify-center bg-primary-100 text-white h-10"
          to={"/"}
        >
          Sign Up
        </Link>
      </div>
    </motion.nav>
  );
};

export default MobileNav;
