import vendorbg from "../../../../assets/images/vendorbg.jpg";
import { Button } from "../../../composables";
import { cn } from "../../../../lib";
import { IoIosStar } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IFavVendorsItem } from "types/global.interface";
import { useDispatch, useSelector } from "react-redux";
import * as type from "../../../../Redux/actions/Types";
import { RootState } from "Redux/reducer";
import { useAddToFavoriteVendor } from "utils/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { Heart } from "iconsax-react";
import { Success } from "assets/icon";

export function VendorCard({
  className,
  item: vendor,
  isFav,
}: {
  className?: string;
  item?: IFavVendorsItem;
  isFav?: boolean;
}) {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLiked, setIsLiked] = useState(false);

  const { mutateAsync } = useAddToFavoriteVendor();
  const handleVendorFav = (mealId: string) => {
    try {
      mutateAsync(mealId, {
        onSuccess: () => {
          toast.success("Vendor added to favorites", {
            icon: Success,
          });
          setIsLiked(true);
        },
      });
    } catch (error) {
      errorLogger(error);
    }
  };
  return (
    <div
      onClick={() => {
        navigate(
          `${
            currentUser
              ? `/customer/vendor/${isFav ? vendor?.vendor._id : vendor?._id}`
              : `/guest/vendor/${isFav ? vendor?.vendor._id : vendor?._id}`
          }`
        );
        dispatch({
          type: type.GET_ACTIVE_VENDOR,
          payload: vendor,
        });
      }}
      className={cn("flex flex-col gap-y-1 mb-8", className)}
    >
      <div
        className={cn(
          "rounded-2xl h-40 w-full relative overflow-hidden bg-center bg-cover"
        )}
        style={{
          backgroundImage: `url(${vendor?.profileImage?.url ?? vendorbg})`,
        }}
      >
        <div className="bg-white absolute top-3 right-3 rounded-3xl px-2 py-1 flex items-center justify-center gap-x-1">
          <IoIosStar className="text-lg text-yellow-500" />
          <span>4.5</span>
          <span className="text-gray-600">(0)</span>
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation(); // Prevent the event from bubbling up
            handleVendorFav(vendor?._id ?? "");
          }}
          className="h-7 absolute bottom-3 right-3 w-7 rounded-full bg-white"
        >
          <Heart
            className={`${isLiked || isFav ? "text-red-500 fill-red-500" : ""}`}
            size={16}
          />
        </Button>
      </div>
      <div className="py-1 gap-y-1 px-2 flex flex-col items-start justify-start">
        <div className="flex items-center justify-between w-full">
          <div className="4/5 truncate">
            <h2 className="font-medium truncate text-base capitalize">
              {!isFav ? vendor?.businessName : vendor?.vendor?.businessName}
            </h2>
          </div>
          <div className="1/5">
            <p className="capitalize">{!isFav ? vendor?.location : ""}</p>
          </div>
        </div>
        <div className="flex items-center  gap-x-2">
          <div className="text-gray-400 pr-4 border-r border-gray-400">
            Delivery: <span className=" text-black">N350</span>
          </div>
          <p>20 -25 Min</p>
        </div>
      </div>
    </div>
  );
}
