import { Link, To } from "react-router-dom";

interface GroupWidgetProp {
  header: string;
  children: React.ReactNode;
  link: To;
}
export function GroupWidgetWrapper({
  header,
  children,
  link,
}: GroupWidgetProp) {
  return (
    <div className="w-full flex flex-col gap-y-3 ">
      <div className="w-full capitalize flex px-2 items-center justify-between ">
        <h2 className=" font-semibold text-base ">{header}</h2>
        <Link to={link} className="text-[#7BBE4A] font-medium text-xs">
          See All
        </Link>
      </div>
      {children}
    </div>
  );
}
