import { Success } from "assets/icon";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "components/composables";
import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetPassword } from "utils/api";
import { errorLogger } from "utils/helper";

const ResetPassword = () => {
  const form = useForm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const userEmail = queryParam.get("email");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { mutateAsync, isLoading } = useResetPassword();
  const handleSubmit = async (data: any) => {
    const payload = {
      email: userEmail,
      otp: data.otp,
      password: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Password Reset Successfully", {
            icon: Success,
          });
          navigate("/auth/signin");
        },
        onSettled: () => {},
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };
  return (
    <main className="p-5">
      <div>
        <h1 className="text-xl font-bold text-grey-900">Forget Password</h1>
        <p className="mt-3 text-grey-200 text-sm">Reset your password</p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            handleSubmit(data);
          })}
          className="mt-10"
        >
          {/* OTP */}
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>OTP</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="123456"
                      type="text"
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* New password */}
          <FormField
            control={form.control}
            name="newPassword"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel> New Password</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="******"
                      type={showPassword ? "text" : "password"}
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                    <div
                      className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <EyeSlash size={20}></EyeSlash>
                      ) : (
                        <Eye size={20}></Eye>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* confirmPassword */}
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel> Confirm Password</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="******"
                      type={showConfirmPassword ? "text" : "password"}
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                    <div
                      className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      {showConfirmPassword ? (
                        <EyeSlash size={20}></EyeSlash>
                      ) : (
                        <Eye size={20}></Eye>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="mt-10 w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
          >
            {isLoading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Reset Password"
            )}
          </Button>
        </form>
      </Form>
    </main>
  );
};

export default ResetPassword;
