import { useFetchMyOrders } from "utils/api";
import Navbar from "../components/Navbar";
import Order from "./Order";
import emptyBox from "../../../assets/Svg/emptyBox.svg";

import { useEffect, useState } from "react";

import { Download } from "lucide-react";
import { FaSpinner } from "react-icons/fa6";
import { ItemsEntity } from "types/global.interface";
import { Button } from "components/composables";
import { cn } from "lib";
import { OrderCardSkeleton } from 'components/composables/Skeletons/OrderCard';

const Orders = () => {

  const [offset, setOffset] = useState(0)
  const orderState = ["Accepted", "Dispatching", "Completed"];
  const [activeState, setActiveState] = useState(0);

  const { data: acceptedOrder, isLoading } = useFetchMyOrders('accepted');
  const { data: dispatchingOrder, isLoading: isDispatchingLoading } = useFetchMyOrders('dispatching');
  const { data: completedOrders, isLoading: isCompletedLoading, isFetching } = useFetchMyOrders('completed', offset);

  const [ordersCount, setOrdersCount] = useState(0)

  useEffect(() => {
    if (acceptedOrder?.items && dispatchingOrder?.items && completedOrders?.items) {
      setOrdersCount(acceptedOrder?.totalCount + dispatchingOrder?.totalCount + completedOrders?.totalCount)
    }
  }, [acceptedOrder?.items, acceptedOrder?.totalCount, completedOrders?.items, completedOrders?.totalCount, dispatchingOrder?.items, dispatchingOrder?.totalCount])

  return (
    <main className="sm:max-w-[600px] mx-auto px-5 pb-24">
      <h1 className="font-semibold text-xl">Orders</h1>
      <div className="w-full bg-[#F4F4F4] grid grid-cols-3 items-center justify-center px-2 rounded-[36px] h-14">
        {orderState.map((state, index) => (
          <Button
            onClick={() => setActiveState(index)}
            className={cn(
              "w-full text-[#6A6A6A] h-11 rounded-[36px]",
              index === activeState && "bg-[#7BBE4A] text-gray-50"
            )}
            key={index}
          >
            {state}
          </Button>
        ))}
      </div>
      <div className="pt-5 flex flex-col gap-4">
        {ordersCount > 0 && !isLoading ? (
          <div>
            {activeState === 0 && (
              <div className="flex flex-col w-full gap-y-2">
                {!isLoading
                  ? ([...(acceptedOrder?.items as ItemsEntity[])] ?? []).map((order, key) => (
                    <Order order={order} key={key} />
                  ))
                  : [1, 2, 3].map((v) => <OrderCardSkeleton key={v} />)}
              </div>
            )}
            {activeState === 1 && (
              <div className="flex flex-col w-full gap-y-2">
                {!isDispatchingLoading
                  ? ([...(dispatchingOrder?.items as ItemsEntity[])] ?? []).map((order, key) => (
                    <Order order={order} key={key} />
                  ))
                  : [1, 2, 3].map((v) => <OrderCardSkeleton key={v} />)}
              </div>
            )}
            {activeState === 2 && (
              <div className="flex flex-col w-full gap-y-2">
                {!isCompletedLoading
                  ? ([...(completedOrders?.items as ItemsEntity[])] ?? []).map((order, key) => (
                    <Order order={order} key={key} />
                  ))
                  : [1, 2, 3].map((v) => <OrderCardSkeleton key={v} />)}

                <div className="flex justify-center items-center py-4">
                  {offset < (completedOrders?.totalCount as number) && <button
                    onClick={() => setOffset(prev => prev + 10)}
                  >
                    {isFetching ? <FaSpinner className="animate-spin text-2xl text-gray-500" /> : <Download className="text-2xl text-gray-500" />}
                  </button>}
                </div>
              </div>
            )}
          </div>

        ) : (
          <div>
            {/* No order */}
            <div className="py-20 flex flex-col items-center">
              <img src={emptyBox} alt="" />
              <h2 className="mt-5 font-semibold text-lg">No Order</h2>
              <p className="mt-2">You don’t have an active order</p>
            </div>
          </div>
        )}
      </div>

      <Navbar />
    </main>
  );
};

export default Orders;
