import ngflag from "../../../assets/Svg/ngflag.svg";
import { useState } from "react";
import { MdNavigateBefore } from "react-icons/md";
import {
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  FormMessage,
  FormLabel,
  Button,
  VendorCategory,
  Location,
  ReactSelect,
} from "../../composables";
import { AnimatePresence, motion } from "framer-motion";
import { pageAnimation } from "../../../utils/pageTransition";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useForm } from "react-hook-form";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registration } from "Redux/actions/auth";
import { errorLogger } from "utils/helper";
import { landmarks } from "utils/AlphaData";
import ToggleSwitch from "components/composables/toggleSwitch/toggleSwitch";
import NaijaStates from "naija-state-local-government";
import { toast } from "react-toastify";
import { Error } from "assets/icon";

function VendorSignup() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [agree, setAgree] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stage, setStage] = useState(1);
  const [isNone, setIsNone] = useState(false);
  const [isLgaOpen, setIsLgaOpen] = useState(false);
  const [location, setLocation] = useState("");
  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      category: "",
      businessName: "",
      password: "",
      confirmPassword: "",
      lga: "",
      address: {
        streetAddress: "",
        nearestLandmark: {
          location: "",
          zone: "",
        },
      },
      cac: "",
      location: "",
    },
    // resolver: yupResolver(vendorRegisterValidationSchema),
  });

  let landMarks: { label: string; value: string }[] = [];
  // eslint-disable-next-line array-callback-return
  landmarks.map((v) => {
    landMarks.push({ label: v.location, value: v.zone });
  });
  const handleSubmit = async (data: any) => {
    if (!agree) {
      toast.error("Terms and conditions agreement needs to be checked");
      return;
    }
    const payload: any = {
      fullName: data.fullName,
      email: data.email.toLowerCase(),
      businessName: data.businessName,
      password: data.password,
      confirmPassword: data.confirmPassword,
      phoneNumber: data.phoneNumber,
      cacNumber: "None",
      category: data.category.toLowerCase(),
      address: {
        streetAddress: data.address.streetAddress ?? "",
        nearestLandmark: {
          location: data.address?.nearestLandmark?.label ?? "",
          zone: data.address?.nearestLandmark?.value ?? "",
        },
      },
      location: data.location.toLowerCase(),
      lga: data.lga,
    };
    if (
      !payload.address.nearestLandmark.location ||
      !payload.address.nearestLandmark.zone
    ) {
      delete payload.address.nearestLandmark;
    }
    try {
      setIsLoading(true);
      dispatch(registration(payload, navigate, setIsLoading, "Vendor"));
    } catch (e: any) {
      errorLogger(e);
      console.log(e);
    }
  };

  return (
    <motion.div
      variants={pageAnimation}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      className="w-full h-screen sm:max-w-[600px] mx-auto py-8 px-5"
    >
      <div className="">
        {stage > 1 && (
          <Button
            onClick={() => {
              if (stage > 1) setStage((curr) => curr - 1);
            }}
            className="gap-x-1 bg- mb-5 -ml-2"
          >
            <MdNavigateBefore size={30} className="text-xl text-primary-100" />
            <span>Back</span>
          </Button>
        )}

        <h1 className="text-xl font-bold text-grey-900">Create an Account</h1>
        <h2 className="mt-1 text-grey-200 text-sm max-w-[300px]">
          Join our delicious journey today!
        </h2>

        {/* form progress indicator */}
        <div className="mt-5 flex gap-x-5 h-0.5">
          <div className="h-full basis-0 bg-primary-100 grow"></div>
          <div
            className={`h-full basis-0 grow ${stage === 2 ? "bg-primary-100" : "bg-grey-30"
              }`}
          ></div>
        </div>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            handleSubmit(data);
          })}
          className="mt-5"
        >
          {stage === 1 && (
            //  stage one
            <div>
              <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Full Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="John Adebayo"
                        type="text"
                        className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="businessName"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Business Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="ABC restaurant"
                        type="text"
                        className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="John@gmail.com"
                        type="email"
                        className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <div className="relative w-full h-10">
                        <div className="absolute top-2 left-2">
                          {" "}
                          <img className="px-3 py-2" src={ngflag} alt="" />
                        </div>

                        <Input
                          placeholder="09083234532"
                          type="tel"
                          className="w-full mt-2 pl-14 pr-5 bg-grey-30 rounded-lg"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <div className="w-full relative h-10">
                        <Input
                          placeholder="******"
                          type={showPassword ? "text" : "password"}
                          className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                          {...field}
                        />
                        <div
                          className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? (
                            <LuEyeOff size={20}></LuEyeOff>
                          ) : (
                            <LuEye size={20}></LuEye>
                          )}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel> Confirm Password</FormLabel>
                    <FormControl>
                      <div className="w-full relative h-10">
                        <Input
                          placeholder="******"
                          type={showConfirmPassword ? "text" : "password"}
                          className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                          {...field}
                        />
                        <div
                          className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                        >
                          {showConfirmPassword ? (
                            <LuEyeOff size={20}></LuEyeOff>
                          ) : (
                            <LuEye size={20}></LuEye>
                          )}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="mt-10 flex flex-col items-center">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const isValid = await form.trigger([
                      "fullName",
                      "email",
                      "phoneNumber",
                      "password",
                      "confirmPassword",
                    ]);

                    if (isValid) {
                      setStage(2);
                    }
                  }}
                  className="w-full h-10 py-2 px-3 rounded-xl bg-primary-100 text-white"
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {stage === 2 && (
            // stage two
            <div>
              <AnimatePresence initial={false} mode="wait">
                {isLocationOpen && (
                  <Location
                    closeLocation={() => {
                      setIsLocationOpen(!isLocationOpen);
                    }}
                    selectLocation={(value) => {
                      form.setValue("location", value);
                    }}
                  />
                )}
              </AnimatePresence>
              <FormField
                control={form.control}
                name="location"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Location</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Minna"
                        readOnly
                        type="text"
                        onFocus={() => {
                          setIsLocationOpen(!isLocationOpen);
                        }}
                        className="w-full mt-2 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.getValues("location") === "Minna" && (
                <div className="my-5">
                  <FormField
                    control={form.control}
                    name="address.nearestLandmark"
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        label="LandMark"
                        placeHolder="Gidan Kwano"
                        options={landMarks}
                      />
                    )}
                  />
                </div>
              )}

              <FormField
                control={form.control}
                name="address.streetAddress"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Address</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="21, talba street, GK"
                        className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lga"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Local Government</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Surulere"
                        readOnly
                        type="text"
                        onFocus={() => {
                          if (!location) {
                            toast.error("Please select a location first", {
                              icon: Error,
                            });
                            return;
                          }
                          setIsLgaOpen(true);
                        }}
                        className="w-full mt-2 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <AnimatePresence initial={false} mode="wait">
                {isLocationOpen && (
                  <Location
                    closeLocation={() => {
                      setIsLocationOpen(!isLocationOpen);
                    }}
                    selectLocation={(value) => {
                      console.log("location", value);
                      form.setValue("location", value || "");
                      if (value === "Minna") {
                        setLocation("Niger");
                      } else if (value === "Ibadan") {
                        setLocation("Oyo");
                      } else if (value === "Jos") {
                        setLocation("Plateau");
                      } else if (value === "Ilorin") {
                        setLocation("Kwara");
                      } else {
                        setLocation(value);
                      }
                    }}
                  />
                )}
              </AnimatePresence>

              <AnimatePresence initial={false} mode="wait">
                {isLgaOpen && (
                  <Location
                    closeLocation={() => {
                      setIsLgaOpen(!isLgaOpen);
                    }}
                    list={NaijaStates.lgas(location).lgas}
                    selectLocation={(value) => {
                      form.setValue("lga", value || "");
                    }}
                    label="LGA"
                  />
                )}
              </AnimatePresence>

              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Category</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="kitchen"
                        readOnly
                        type="text"
                        onFocus={() => {
                          setIsCategoryOpen(!isCategoryOpen);
                        }}
                        className="w-full mt-2 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <AnimatePresence initial={false} mode="wait">
                {isCategoryOpen && (
                  <VendorCategory
                    closeCategory={() => {
                      setIsCategoryOpen(false);
                    }}
                    selectCategory={(value) => form.setValue("category", value)}
                  ></VendorCategory>
                )}
              </AnimatePresence>

              <FormField
                control={form.control}
                name="cac"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <div className="flex justify-between items-center">
                      <FormLabel>Category</FormLabel>
                      <div className="border-l flex items-center">
                        <span className="mx-2">None</span>
                        <ToggleSwitch
                          onChange={(value) => {
                            setIsNone(value);
                          }}
                        />
                      </div>
                    </div>
                    {!isNone && (
                      <FormControl>
                        <Input
                          placeholder="RC123456"
                          type="text"
                          className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                          {...field}
                        />
                      </FormControl>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-5 flex space-x-2 items-center">
                <input type="checkbox" name="" id="" onChange={
                  (e) => {
                    setAgree(e.target.checked)
                  }
                } />
                <p>I Agree to Jekaeat's <Link className="text-primary-100" to="/privacy">Terms and Condition</Link> </p>
              </div>

              <div className="mt-10 flex flex-col items-center">
                <Button
                  type="submit"
                  className="w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
                >
                  {isLoading ? (
                    <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </div>

            </div>
          )}

          <div className="flex justify-center mt-5">
            <span className="">
              You already have an account?{" "}
              <Link className="font-bold" to={"/auth/signin"}>
                Sign in
              </Link>
            </span>
          </div>
        </form>
      </Form>
    </motion.div>
  );
}

export default VendorSignup;
