import { useState } from "react";
import "./toggleSwitch.css";

type ToggleSwitchProps = {
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
};

const ToggleSwitch = ({ defaultChecked, onChange }: ToggleSwitchProps) => {
  const [checked, setChecked] = useState(defaultChecked || false);

  const handleChange = () => {
    setChecked(!checked);
    onChange && onChange(!checked);
  };

  return (
    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
      <label className="toggle-switch">
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          checked={checked}
          onChange={handleChange}
          className="toggle"
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
