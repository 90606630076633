export const pageAnimation = {
  hidden: { x: window.innerWidth },
  visible: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: { x: -window.innerWidth },
};
