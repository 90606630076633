/* eslint-disable array-callback-return */
import { ArrowLeft } from "iconsax-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ReactSelect,
} from "../../composables";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { useFetchAccountName, useUpdateAccountInfo } from "utils/api";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { useEffect, useState } from "react";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { riderAccountSettlementSchema } from "validations";
import { Success } from "assets/icon";

const AccountSettlement = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [accountNumber, setAccountNumber] = useState("");
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      accountName: currentUser?.payoutSettlement.account?.accountName ?? "",
      accountNumber: currentUser?.payoutSettlement.account?.accountNumber ?? "",
      bankCode: currentUser?.payoutSettlement.account?.bankCode ?? "",
    },
    resolver: yupResolver(riderAccountSettlementSchema),
  });

  const [banks, setBanks] = useState<{ label: string; value: string }[]>([]);
  const getBanks = () => {
    axios
      .get("https://api.paystack.co/bank")
      .then((response) => {
        let allBanks: { label: string; value: string }[] = [];
        console.log(response.data.data);
        response.data.data.map((data: any) => {
          allBanks.push({ label: data.name, value: data.code });
        });
        setBanks(allBanks);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const {
    data,
    isLoading: isFetchingAccountName,
    refetch,
  } = useFetchAccountName(accountNumber, form.getValues("bankCode") as any);

  const { mutateAsync, isLoading } = useUpdateAccountInfo();
  const handleSubmit = async () => {
    let bankCode = form.getValues("bankCode") as any;
    const payload = {
      account: {
        accountName: data?.account_name,
        accountNumber: accountNumber,
        bankCode: bankCode?.value,
      },
      allowPeriodicPayout: true,
      period: "daily",
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Account Info Updated Successfully", { icon: Success });
          navigate(-1);
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };
  console.log(data);
  useEffect(() => {
    getBanks();

    if (accountNumber.length > 9) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber]);

  return (
    <main className="px-5 pt-5">
      {/* header */}
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Account Settlement</h1>
      </div>

      {/* Form */}
      <Form {...form}>
        <form className="mt-5">
          <FormField
            control={form.control}
            name="bankCode"
            render={({ field }) => (
              <ReactSelect
                {...field}
                label="Bank Name"
                placeHolder="Bank Name"
                options={banks}
              />
            )}
          />
          <FormField
            control={form.control}
            name="accountNumber"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Account Number</FormLabel>
                <FormControl>
                  <Input
                    placeholder="012345677"
                    type="text"
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    defaultValue={
                      currentUser?.payoutSettlement.account?.accountNumber ?? ""
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="accountName"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Account Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder=""
                    type="text"
                    readOnly
                    value={
                      data?.account_name ??
                      (currentUser?.payoutSettlement.account
                        ?.accountName as string)
                    }
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    defaultValue={
                      currentUser?.payoutSettlement.account?.accountName ?? ""
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="text-grey-300 italic  text-xs">
            {isFetchingAccountName && "fetching account name..."}
          </div>

          <Button
            type="button"
            onClick={handleSubmit}
            disabled={!data?.account_name}
            className={`${
              data?.account_name ? "bg-primary-100" : "bg-gray-400"
            } mt-10 w-full h-12 py-2 px-3 rounded-xl  text-white`}
          >
            {isLoading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Update Account"
            )}
          </Button>
        </form>
      </Form>
    </main>
  );
};

export { AccountSettlement };
