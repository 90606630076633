/* eslint-disable react-hooks/exhaustive-deps */
// src/components/ImageSlider.jsx
import React, { useState, useEffect } from "react";

const ImageSlider = ({ images, interval = 3000 }: { images: string[], interval?: number }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            goToNext();
        }, interval);
        return () => clearInterval(slideInterval); // Cleanup on unmount
    }, [currentIndex]);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // const goToPrevious = () => {
    //     setCurrentIndex((prevIndex) =>
    //         prevIndex === 0 ? images.length - 1 : prevIndex - 1
    //     );
    // };

    return (
        <div className="md:relative overflow-hidden w-full mx-auto rounded-lg shadow-lg border border-gray-200">
            {/* Slider container */}
            <div
                className="flex transition-transform duration-500"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <div className="w-full flex-shrink-0" key={index}>
                        <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>

            {/* Previous Button */}
            {/* <button
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 hover:bg-gray-600 focus:outline-none"
                onClick={goToPrevious}
            >
                &#10094;
            </button> */}

            {/* Next Button */}
            {/* <button
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 hover:bg-gray-600 focus:outline-none"
                onClick={goToNext}
            >
                &#10095;
            </button> */}

            {/* Indicators */}
            {/* <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`w-3 h-3 rounded-full ${index === currentIndex ? "bg-gray-800" : "bg-gray-400"
                            }`}
                    ></div>
                ))}
            </div> */}
        </div>
    );
};

export default ImageSlider;
