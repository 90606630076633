import { Call, Clock } from "iconsax-react";
import riderImage from "../../../assets/images/RiderImage.png";
import { ItemsEntity } from "types/global.interface";
import moment from "moment";
import { useApproveOrder } from "utils/api";
import { FaSpinner } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isWebview } from "../../../utils/helper";
import { getNairaEquivalent } from "utils/helper";
import { Success } from "assets/icon";

type OrderProps = {
  variant?: string;
  order: ItemsEntity;
};

const OrderCard = ({ order }: OrderProps) => {
  const navigate = useNavigate();

  const handleCall = (phoneNumber: string) => {
    if (isWebview()) {
      navigate(`/rider/orders/${order.id}?app_action_call=${phoneNumber}`);
    } else {
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          // Success: The text has been copied to the clipboard
          toast.success("Number Copied", { icon: Success });
        })
        .catch((err) => {
          // Error: Something went wrong while copying the text
          console.error("Failed to copy text: ", err);
        });
    }
  };

  const { mutateAsync, isLoading } = useApproveOrder();

  return (
    <div className="px-3 py-4 border border-[#E9E9E9] rounded-2xl">
      {/* order info */}
      <div className="flex justify-between items-end">
        <div>
          <div className="flex items-center gap-4">
            <Link to={`/vendor/orders/${order._id}`}>
              <h1 className="font-semibold text-lg">
                Order #{order._id?.substring(0, 6)}
              </h1>
            </Link>
            {order.status === "standard" && (
              <div className="w-2.5 h-2.5 rounded-full bg-[#B0EEC9] flex justify-center items-center">
                <div className="w-1.5 h-1.5 rounded-full bg-[#33D374]"></div>
              </div>
            )}
          </div>
          <p className="mt-2">
            {order.packages[0].items
              .map((obj) => obj?.product?.name)
              .join(", ") ?? ""}
          </p>
          <p className="mt-2"> {order?.customer?.fullName}</p>
        </div>

        <div className="text-right">
          {order.status === "completed" && (
            <div className="bg-[#E6FAEE] text-[#00C851] text-xs border border-[#8AE6AF] rounded-full py-2 px-3">
              Delivered
            </div>
          )}
          <h1 className="font-bold text-lg">
            ₦{getNairaEquivalent(order?.total)}
          </h1>
          <div className="mt-2 flex gap-1 whitespace-nowrap items-center justify-end text-[#999999] text-xs">
            <Clock size={15} />
            <span> {moment(order?.createdAt).format("LT")} </span>
            <span className="px-2">{moment(order?.createdAt).format("L")}</span>
          </div>
        </div>
      </div>

      {/* variants */}
      {/* standard */}
      {order.status === "pending" && (
        <div className="mt-6 flex w-full gap-5">
          <button
            onClick={() => {
              mutateAsync(order._id, {
                onSuccess: () => {
                  toast.success("Rider Requested", { icon: Success });
                },
              });
            }}
            className="basis-0 grow flex justify-center px-4 py-3 text-lg text-[#70AD43] border border-[#70AD43] rounded-2xl"
          >
            Request Rider
          </button>
          <button
            onClick={() => {
              mutateAsync(order._id, {
                onSuccess: () => {
                  toast.success("Order Accepted", { icon: Success });
                },
              });
            }}
            className="basis-0 grow flex justify-center px-4 py-3 text-lg text-white bg-[#70AD43] border border-[#70AD43] rounded-2xl"
          >
            {isLoading ? (
              <FaSpinner className="animate-spin" size={20} />
            ) : (
              "Accept"
            )}
          </button>
        </div>
      )}

      {/* ongoing */}
      {order.status === "accepted" && (
        <div className="pt-4 mt-4 flex justify-between items-center border-t border-t-[#E9E9E9]">
          <div className="flex items-center gap-4">
            <div className="w-10 h-10 rounded-full">
              <img src={riderImage} alt="" />
            </div>
            <div className="text-xs">
              <p>Awaiting rider to pickup order</p>
              <p className="text-[#999999]">{order?.rider?.fullName ?? ""} </p>
            </div>
          </div>

          {/* call button */}
          <button
            onClick={() => {
              handleCall(order?.rider?.phoneNumber ?? "");
            }}
            className="w-10 h-10 rounded-xl flex justify-center items-center bg-[#D6EBC7] text-[#578735]"
          >
            <Call size={20} />
          </button>
        </div>
      )}
      {order.status === "dispatching" && (
        <div className="pt-4 mt-4 flex justify-between items-center border-t border-t-[#E9E9E9]">
          <div className="flex items-center gap-4">
            <div className="w-10 h-10 rounded-full">
              <img src={riderImage} alt="" />
            </div>
            <div className="text-xs">
              <p>Order in transit</p>
              <p className="text-[#999999]">{order?.rider?.fullName ?? ""} </p>
            </div>
          </div>

          {/* call button */}
          <button
            onClick={() => {
              handleCall(order?.rider?.phoneNumber ?? "");
            }}
            className="w-10 h-10 rounded-xl flex justify-center items-center bg-[#D6EBC7] text-[#578735]"
          >
            <Call size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderCard;
