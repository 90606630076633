import { Reducer } from "redux";
import * as type from "../actions/Types";
import { ICustomer, IRider, IVendor } from "../../types/global.interface";

export interface UserState {
  currentUser: (ICustomer & IVendor & IRider) | null;
  token: string | null;
  activeVendor: IVendor | null;
}

export interface UserAction {
  type: string;
  payload?: any;
}

const initialState: UserState = {
  currentUser: null,
  activeVendor: null,
  token: "",
};
const User: Reducer<UserState, UserAction> = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case type.FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case type.GET_ACTIVE_VENDOR:
      return {
        ...state,
        activeVendor: action.payload,
      };

    case type.LOGOUT:
      return {
        ...state,
        currentUser: null,
        token: "",
      };

    default:
      return state;
  }
};

export default User;
