import { useEffect, useState } from "react";
import Navbar from "../Navbar";
import FoodMenu from "./FoodMenu";
import { Link } from "react-router-dom";
import { Add } from "iconsax-react";
import { useFetchVendorMeals } from "utils/api";
import { useSelector } from "react-redux";
import { MenuListSkeleton } from "components/composables/Skeletons/menuList";
import { MEAL_TYPE } from "utils/constant.util";
import { MealItemsEntity } from "types/global.interface";

export const Menu = () => {
  const { currentUser } = useSelector((state: any) => state.user);

  const [tab, setTab] = useState(currentUser.category === 'restaurant' ? "foodStack" : 'meal_kit');
  const [foodStack, setFoodStack] = useState<MealItemsEntity[] | undefined>([]);
  const [mealPack, setMealPack] = useState<MealItemsEntity[] | undefined>([]);
  const [preOrder, setPreOrder] = useState<MealItemsEntity[] | undefined>([]);
  const [mealkit, setMealKit] = useState<MealItemsEntity[] | undefined>([]);
  const [harvest_pack, setHarvest_pack] = useState<MealItemsEntity[] | undefined>([]);
  const { data, isLoading } = useFetchVendorMeals(currentUser._id);

  useEffect(() => {
    if (data) {
      setFoodStack(
        data?.filter((v) => v.category === MEAL_TYPE.FOOD_STACK)
      );
      setMealPack(
        data?.filter((v) => v.category === MEAL_TYPE.MEAL_PACK)
      );
      setPreOrder(
        data?.filter((v) => v.category === MEAL_TYPE.PRE_ORDER)
      );
      setMealKit(
        data?.filter((v) => v.category === MEAL_TYPE.MEAL_KIT)
      );
      setHarvest_pack(
        data?.filter((v) => v.category === MEAL_TYPE.HARVEST_PACK)
      );
    }
  }, [data]);

  return (
    <main className="mx-auto px-5 pb-24">
      <Navbar />
      <h1 className="font-semibold text-xl">Menu</h1>

      {/* Tab Headers */}

      {currentUser.category === 'restaurant' ?

        <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full">
          <div
            className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "foodStack" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
              }`}
            onClick={() => {
              setTab("foodStack");
            }}
          >
            Food Stack
          </div>
          <div
            className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "mealPack" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
              }`}
            onClick={() => {
              setTab("mealPack");
            }}
          >
            Meal Pack
          </div>
          <div
            className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "preOrder" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
              }`}
            onClick={() => {
              setTab("preOrder");
            }}
          >
            Pre-Order
          </div>
        </div> :
        <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full">

          <div
            className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "meal_kit" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
              }`}
            onClick={() => {
              setTab("meal_kit");
            }}
          >
            Meal Kit
          </div>
          <div
            className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "harvest_pack" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
              }`}
            onClick={() => {
              setTab("harvest_pack");
            }}
          >
            Harvest Park
          </div>

        </div>
      }


      {/* Tabs Contents */}

      {!isLoading ? (
        <div>
          {
            <FoodMenu
              section={tab}
              meals={
                tab === "foodStack"
                  ? foodStack
                  : tab === "mealPack"
                    ? mealPack : tab === "meal_kit" ? mealkit : tab === "harvest_pack" ? harvest_pack
                      : preOrder
              }
            />
          }
        </div>
      ) : (
        [1, 2, 3].map((v) => <MenuListSkeleton key={v} />)
      )}

      {/* add new food */}
      <div className="fixed bottom-24 right-5 z-10">
        <Link
          to={`/vendor/add-menu?menutype=${tab !== "preOrder" ? "general" : "preorder"
            }`}
          className="p-5 flex rounded-xl bg-[#7BBE4A] shadow-[-1px_8px_10px_0px_#00000029] text-white"
        >
          <Add />
        </Link>
      </div>
    </main>
  );
};
