import { IoIosArrowRoundBack } from "react-icons/io";
import {
  Form,
  Button,
  FormItem,
  FormMessage,
  FormLabel,
  FormField,
  FormControl,
  Input,
  ReactSelect,
} from "../../composables";
import { landmarks } from "utils/AlphaData";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { toast } from "react-toastify";
import { Error } from "assets/icon";
import { CheckCircle } from "lucide-react";

export function DeliveryDetail({
  onClose,
  form,
  setSelectedDay,
  setSelectedTime,
  selectedDay,
  selectedTime,
  setIsPaymentMethod,
  deliveryMode,
  setDeliveryMode,
  deliveryFee,
  setDCode,
  isDCodeCorrect,
  orderBreakDown
}: {
  onClose: () => void;
  setIsPaymentMethod: (v: boolean) => void;
  setSelectedDay: (v: string) => void;
  setSelectedTime: (v: string) => void;
  setDeliveryMode: (v: string) => void;
  setDCode: (v: string) => void;
  isDCodeCorrect: boolean;
  selectedDay: string;
  selectedTime: string;
  deliveryMode: string;
  deliveryFee: number
  form: any;
  orderBreakDown?: JSX.Element
}) {
  console.log(deliveryFee);

  const { currentUser } = useSelector((state: RootState) => state.user);
  let landMarks: { label: string; value: string }[] = [];
  // eslint-disable-next-line array-callback-return
  landmarks.map((v) => {
    landMarks.push({ label: v.location, value: v.zone });
  });

  const selectedLandmarkIndex = (landMarks ?? []).findIndex(
    (item) => item.label === form.getValues("landMark")?.label
  );
  console.log(landMarks[selectedLandmarkIndex]);

  function generateDateArray() {
    const result = [];
    const today = new Date();
    const oneWeekFromToday = new Date(today);
    oneWeekFromToday.setDate(today.getDate() + 7);

    for (
      let date = new Date(today);
      date <= oneWeekFromToday;
      date.setDate(date.getDate() + 1)
    ) {
      // Skip Sundays
      if (date.getDay() === 0) continue; // 0 represents Sunday in JavaScript Date object

      const day = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        date
      );
      const dayTag = new Intl.DateTimeFormat("en-US", { weekday: "short" })
        .format(date)
        .charAt(0);
      // Format the date with a leading zero for months less than 10
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      const formattedDateTag = `${date.getDate()}`;

      result.push({
        day,
        tag: dayTag.toUpperCase(),
        date: formattedDate,
        date_tag: formattedDateTag,
      });
    }

    return result;
  }

  // Example usage
  const result = generateDateArray();
  console.log(result);

  const time: string[] = [];

  for (let i = 0; i <= 23; i++) {
    const hour = i.toString().padStart(2, "0"); // Convert to string and pad with leading zeros if necessary
    const timeString = `${hour}:00`; // Append ":00" to each hour
    time.push(timeString);
  }

  const generatedDays = generateDateArray();
  console.log(generatedDays);

  return (
    <div className="  bg-white fixed inset-0 w-full mx-auto max-w-[600px] flex flex-col gap-y-3 items-start justify-start px-4 pt-9 pb-4 overflow-auto">
      <Button onClick={onClose} className="px-0 gap-x-2 font-medium">
        <IoIosArrowRoundBack className="text-2xl" />
        <p>Delivery</p>
      </Button>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data: any) => {
            if (form.getValues("landMark")?.value as any) {
              onClose();
              setIsPaymentMethod(true);
            } else {
              toast.error("Please select your landmark", {
                icon: Error,
              });
              return;
            }

            // onSubmit(data);
          })}
          className="w-full flex flex-col items-start justify-start gap-y-2 "
        >
          <h2 className="font-semibold text-base mb-2">Delivery Details</h2>
          <FormField
            control={form.control}
            name="contactName"
            render={({ field }) => (
              <FormItem className="w-full mt-33">
                <FormLabel>Receiver Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Receiver Name"
                    type="text"
                    defaultValue={currentUser?.fullName ?? ""}
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="landMark"
            render={({ field }) => (
              <ReactSelect
                {...field}
                label="LandMark"
                options={landMarks}
                defaultValue={landMarks[selectedLandmarkIndex]}
                placeHolder=""
              />
            )}
          />
          <FormField
            control={form.control}
            name="streetAddress"
            render={({ field }) => (
              <FormItem className="w-full mt-33">
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Input
                    placeholder="address"
                    type="text"
                    defaultValue={currentUser?.address?.streetAddress ?? ""}
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contactPhone1"
            render={({ field }) => (
              <FormItem className="w-full mt-3">
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <Input
                    placeholder="08076438239"
                    type="tel"
                    defaultValue={currentUser?.phoneNumber ?? ""}
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contactPhone2"
            render={({ field }) => (
              <FormItem className="w-full mt-3">
                <FormLabel>Alternative Phone Number</FormLabel>
                <FormControl>
                  <Input
                    placeholder="08076432389"
                    type="tel"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="instructions"
            render={({ field }) => (
              <FormItem className="w-full mt-3">
                <FormLabel>Note</FormLabel>
                <FormControl>
                  <textarea
                    placeholder="I want pineapple flavor of 5Alive!"
                    rows={3}
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <h2 className="font-semibold text-base mb-3">Delivery Time</h2>
          {["Right Away", "Schedule Delivery"].map((value) => (
            // {["Right Away"].map((value) => (
            <FormField
              control={form.control}
              name="deliveryTime"
              render={({ field }) => (
                <label className="drop-container w-full my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center">
                  <span className="capitalize">{value}</span>
                  <input
                    type="radio"
                    {...field}
                    value={value}
                    onChange={(e) => {
                      setDeliveryMode(value);
                    }}
                    className=""
                  />
                  <span className="drop-checkmark"></span>
                </label>
              )}
            />
          ))}

          {deliveryMode === "Schedule Delivery" && (
            <div className="w-full">
              <FormField
                control={form.control}
                name="day"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Day</FormLabel>
                    <FormControl>
                      <div className="flex gap-x-3 items-center overflow-x-scroll no-scrollbar">
                        {generatedDays.map((day, index) => (
                          <label
                            key={index}
                            className={`flex flex-col items-center border border-[#E9E9E9] py-3 px-4 rounded-lg ${selectedDay === day.date
                              ? "bg-primary-100 text-white"
                              : ""
                              }`}
                            htmlFor={day.day}
                            onClick={() => {
                              setSelectedDay(day.date);
                            }}
                          >
                            <input
                              className="w-[0.1px] h-[0.1px] appearance-none"
                              id={day.day}
                              type="radio"
                              name={day.day}
                              value={day.day}
                            />
                            <div
                              className={`  ${selectedDay === day.date
                                ? "text-white"
                                : "text-[#999999]"
                                }`}
                            >
                              <div>{day.tag}</div>
                              <div>{day.date_tag}</div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="time"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>
                      <div className="flex justify-between">Time</div>
                    </FormLabel>
                    <FormControl>
                      <div className="flex gap-x-3 items-center overflow-x-scroll no-scrollbar">
                        {time.map((t, index) => (
                          <label
                            key={index}
                            className={`flex flex-col items-center border border-[#E9E9E9] py-3 px-4 rounded-lg ${t === selectedTime && "bg-primary-100 text-white"
                              } `}
                            htmlFor={String(index)}
                          >
                            <input
                              className="w-[0.1px] h-[0.1px] appearance-none"
                              id={String(index)}
                              type="radio"
                              name="time"
                              value={t}
                              onChange={(e) => {
                                setSelectedTime(e.target.value);
                              }}
                            />
                            <span
                              className={`text-[#999999] ${t === selectedTime &&
                                "bg-primary-100 text-white"
                                } `}
                            >
                              {t}
                            </span>
                          </label>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
          <Input
            placeholder="Discount Code - Optional"
            type="text"
            className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
            onChange={(e) => {
              setDCode(e.target.value);
            }}
          />

          {isDCodeCorrect && <div className="flex space-x-1 text-primary-100 text-sm">
            <CheckCircle size={16} />  <p>Discount Code Applied</p>
          </div>}
          {orderBreakDown && orderBreakDown}
          <div className="mt-3 mb-6 w-full flex flex-col items-center">
            <Button
              type="submit"
              className="w-full py-2 px-3 rounded-lg bg-primary-100 text-white"
            >
              {"" ? (
                <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
