import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const AnimatedText = ({
  textList,
  interval = 3000,
  className = "text-secondary",
}: {
  textList: string[];
  interval?: number;
  className?: string;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length);
    }, interval);

    return () => clearInterval(timer);
  }, [textList, interval]);

  return (
    <span className="inline-flex items-center h-auto overflow-hidden">
      <AnimatePresence mode="wait">
        <motion.span
          key={textList[currentIndex]}
          className={`${className}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6 }}
        >
          {textList[currentIndex]}
        </motion.span>
      </AnimatePresence>
    </span>
  );
};

export default AnimatedText;
