import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useFetchSingleOrder, useUpdateOrder } from "utils/api";
import { FaSpinner } from "react-icons/fa6";
import { digitFormatter, getNairaEquivalent } from "utils/helper";
import { toast } from "react-toastify";
import { Success } from "assets/icon";

export function OrderDetail() {
  const navigate = useNavigate();

  const { id } = useParams();

  const { data, isLoading } = useFetchSingleOrder(id ?? "");
  console.log(data);

  const { mutateAsync, isLoading: isReadyLoading } = useUpdateOrder();

  if (isLoading) {
    return (
      <div className="grid place-content-center p-10">
        <FaSpinner className="text-2xl animate-spin" />
      </div>
    );
  } else {
    return (
      <div className="px-4 w-full items-start justify-start  flex flex-col gap-y-6">
        <button
          onClick={() => navigate(-1)}
          className=" gap-x-2 h-fit flex space-x-2 items-center"
        >
          <IoMdArrowBack className="text-[22px]" />
          <p className="font-semibold text-[15px]">Order</p>
        </button>

        <div className="border-b-4 flex items-center  justify-between w-full pb-6">
          <h2>Status</h2>
          {data?.status === "completed" ? (
            <div className="bg-[#E6FAEE] text-[#00C851] text-xs border border-[#8AE6AF] rounded-full py-2 px-3">
              {data?.status}
            </div>
          ) : (
            <div className="bg-[#FFF8EB] text-[#E8AA2E] text-xs border border-[#E8AA2E] rounded-full py-2 px-3">
              {data?.status}
            </div>
          )}
        </div>

        <div className="flex items-start px-4 pb-8 border-b-4 border-gray-300 justify-start w-full  flex-col gap-y-3">
          <p className="font-semibold">Delivery Info</p>

          <div className="flex justify-between items-center py-4 w-full">
            <p>Name</p>
            <p>{data?.deliveryInfo.contactName}</p>
          </div>
          <div className="flex justify-between items-center py-4 w-full">
            <p>Address</p>
            <p>{data?.deliveryInfo.address.streetAddress}</p>
          </div>
          <div className="flex justify-between items-center py-4 w-full">
            <p>Phone</p>
            <div className="flex space-x-1">
              {" "}
              <p>{data?.deliveryInfo.contactPhone1}</p>{" "}
              {data?.deliveryInfo.contactPhone2 && (
                <p> || {data?.deliveryInfo.contactPhone2}</p>
              )}
            </div>
          </div>
          <div className="mt-3">
            <h3 className="text-xs text-grey-200">Customer Note </h3>
            <p className="mt-2">
              {" "}
              {data?.deliveryInfo.instructions ?? "No Description"}{" "}
            </p>
          </div>
        </div>

        <div className="w-full flex flex-col gap-y-3 px-4 items-start justify-start">
          <p className="font-semibold">Order Details</p>
          {(data?.packages ?? []).map((item, key) => (
            <div className="w-full">
              <h2 className="font-semibold mb-2">Order {key + 1}</h2>
              <div className="space-y-5">
                {item.items?.map((v, key) => (
                  <SingleOrderWidget
                    key={key}
                    order={v.product.name}
                    numOfOrders={v.quantity}
                    price={v.total}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-between items-center mb-6 font-semibold bg-[#F4F4F4]  p-4 rounded-xl w-full">
          <p>Total</p>
          <p>
            ₦{digitFormatter(getNairaEquivalent(data?.total ?? 0)) || "N/A"}
          </p>
        </div>
        {data?._id === "pending" ? (
          <button
            onClick={() => {
              mutateAsync(
                { orderId: data?._id as string, status: "ready" },
                {
                  onSuccess: () => {
                    toast.success("Rider Requested", { icon: Success });
                  },
                }
              );
            }}
            className="basis-0 grow px-4 py-3 text-lg text-[#FFFFFF] w-full flex justify-center bg-[#70AD43] rounded-2xl"
          >
            {isReadyLoading ? (
              <FaSpinner className="text-2xl animate-spin" />
            ) : (
              "Request Rider"
            )}
          </button>
        ) : (
          <button
            disabled
            className="basis-0 cursor-not-allowed grow px-4 py-3 text-lg text-[#FFFFFF] w-full flex justify-center bg-gray-300 rounded-2xl"
          >
            Request Rider
          </button>
        )}
      </div>
    );
  }
}

function SingleOrderWidget({
  price,
  order,
  numOfOrders,
}: {
  price?: number;
  order: string;
  numOfOrders: number;
}) {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-x-2">
        <p className="w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
          {order}... x{numOfOrders}
        </p>
      </div>

      <p>₦{digitFormatter(getNairaEquivalent(price) ?? 0) || "N/A"}</p>
    </div>
  );
}
