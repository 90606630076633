import { AnimatePresence, motion } from "framer-motion";
import {
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  Button,
} from "../../../composables";
import { useForm } from "react-hook-form";
import { useSearchHook } from "../../../../context/searchContext";
import { useEffect } from "react";
import { useUpdateUserProfile } from "utils/api";
import { FaSpinner } from "react-icons/fa6";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { Success } from "assets/icon";

type FormValue = {
  location: string;
};

export default function ChangeLocation({
  toggleLocation,
}: {
  toggleLocation: () => void;
}) {
  const { setLocationValue, locationValue } = useSearchHook();
  const form = useForm<FormValue>({
    defaultValues: {
      location: "",
    },
  });
  const { mutateAsync, isLoading } = useUpdateUserProfile();

  useEffect(() => {
    if (locationValue) {
      form.reset({
        location: locationValue,
      });
    }
  }, [locationValue, form]);

  async function onSubmit(value: FormValue) {
    setLocationValue(value.location);

    try {
      await mutateAsync(
        {
          address: {
            streetAddress: value.location,
          },
        },
        {
          onSuccess: () => {
            toast.success("Location Updated Successfully", {
              icon: Success,
            });
            toggleLocation();
          },
        }
      );
    } catch (e: any) {
      errorLogger(e);
    }
  }

  const animate = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: "100%",
    },
  };
  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        variants={animate}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="fixed sm:max-w-[600px] mx-auto z-40 bottom-0 inset-x-0 py-10 px-5 bg-white border rounded-t-[40px] overflow-hidden"
      >
        <div className="absolute inset-x-0 top-3 -translate-x-1/2 left-1/2 w-14 h-1.5 rounded-full bg-grey-30"></div>
        <h2 className="font-bold mb-3">Edit Location</h2>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col  gap-y-3"
          >
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormControl>
                    <Input
                      placeholder="location"
                      type="text"
                      className="w-full h-12 mt-2 px-4 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
            >
              {isLoading ? <FaSpinner /> : "Change Location"}
            </Button>
          </form>
        </Form>
      </motion.div>
    </AnimatePresence>
  );
}
