/* eslint-disable array-callback-return */
import { MdNavigateBefore } from "react-icons/md";
import { useEffect, useState } from "react";
import { pageAnimation } from "../../../../utils/pageTransition";

import { Button } from "../../../composables";

import { validateData } from "../../../../validations";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../../composables/toggleSwitch/toggleSwitch";
import { motion } from "framer-motion";
import { useGetUserProfile, useUpdateSchedule } from "utils/api";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { FaSpinner } from "react-icons/fa6";
import { Success } from "assets/icon";
const CompleteRegistration = () => {
  const navigate = useNavigate();
  useGetUserProfile();

  const weekDays = [
    {
      day: "sunday",
      tag: "S",
    },
    {
      day: "monday",
      tag: "M",
    },
    {
      day: "tuesday",
      tag: "T",
    },
    {
      day: "wednesday",
      tag: "W",
    },
    {
      day: "thursday",
      tag: "T",
    },
    {
      day: "friday",
      tag: "F",
    },
    {
      day: "saturday",
      tag: "S",
    },
  ];

  const time: string[] = [];

  for (let i = 0; i <= 23; i++) {
    const hour = i.toString().padStart(2, "0"); // Convert to string and pad with leading zeros if necessary
    const timeString = `${hour}:00`; // Append ":00" to each hour
    time.push(timeString);
  }
  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [isAllDay, setIsAllDay] = useState(false);
  const [timeFrame, setTimeFrame] = useState<{
    startTime: string;
    endTime: string;
    [key: string]: string;
  }>({ startTime: "", endTime: "" });

  function handleSelectedDay(day: string) {
    const selectedDaysClone = [...availableDays];
    if (availableDays.includes(day)) {
      const updatedData = selectedDaysClone.filter((v) => v !== day);
      setAvailableDays(updatedData);
    } else {
      setAvailableDays([...availableDays, day]);
    }
  }
  function isTimeGreater(time1: string, time2: string) {
    // Convert the time strings to Date objects
    const date1 = new Date(`1970-01-01T${time1}:00`);
    const date2 = new Date(`1970-01-01T${time2}:00`);

    // Compare the hours and minutes
    return (
      date1.getHours() > date2.getHours() ||
      (date1.getHours() === date2.getHours() &&
        date1.getMinutes() > date2.getMinutes())
    );
  }

  function handleTimeSelection(time: string) {
    if (timeFrame.startTime === "" && timeFrame.endTime === "") {
      setTimeFrame({ ...timeFrame, startTime: time });
    } else {
      if (isTimeGreater(time, timeFrame.startTime)) {
        setTimeFrame({ ...timeFrame, endTime: time });
      } else {
        setTimeFrame({ ...timeFrame, startTime: time });
      }
    }
  }
  function resetTime() {
    setTimeFrame({ startTime: "", endTime: "" });
  }
  const { mutateAsync: mutateAsyncSchedule, isLoading: isScheduleLoading } =
    useUpdateSchedule();

  async function handleScheduleSubmit() {
    const payload = availableDays.map((day) => {
      return { day: day, from: timeFrame.startTime, to: timeFrame.endTime };
    });
    console.log(validateData(payload));
    console.log(payload);
    if (validateData(payload)) {
      try {
        await mutateAsyncSchedule(payload, {
          onSuccess: () => {
            toast.success("Schedule Updated Successfully", {
              icon: Success,
            });
            navigate(-1);
          },
        });
      } catch (e: any) {
        errorLogger(e);
      }
    }
  }

  useEffect(() => {
    if (isAllDay) {
      // eslint-disable-next-line array-callback-return
      let allDay: string[] = [];
      weekDays.map((day) => {
        allDay.push(day.day);
      });
      setAvailableDays(allDay);
    } else {
      setAvailableDays([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllDay]);

  return (
    <div>
      <motion.div
        variants={pageAnimation}
        initial={"hidden"}
        animate={"visible"}
        exit={"exit"}
        className="w-full h-screen sm:max-w-[600px] mx-auto py-5 px-5"
      >
        <Button
          onClick={() => {
            navigate(-1);
          }}
          className="gap-x-1 mb-5 -ml-2"
        >
          <MdNavigateBefore size={30} className="text-xl text-primary-100" />
          <span>Back</span>
        </Button>

        <h1 className="text-xl font-bold text-grey-900">
          Complete Registration
        </h1>
        <h2 className="mt-1 text-grey-200 text-sm max-w-[400px]">
          Finalize registration to start receiving orders and making money
        </h2>
        <div>
          <div className="mt-10">
            <div className="flex justify-between items-center">
              <label>Schedule</label>
              <div className="border-l flex items-center">
                <span className="mx-8">All-Day</span>
                <ToggleSwitch
                  onChange={(value) => {
                    setIsAllDay(value);
                  }}
                />
              </div>
            </div>
            {/* pick a day */}
            <div className="mt-8">
              <label>Pick a Day</label>
              <div className="mt-3 flex justify-between">
                {weekDays.map((day) => (
                  <div
                    key={day.day}
                    className={`px-3 py-2 bg-grey-30 rounded-lg  ${
                      availableDays.includes(day.day) &&
                      "!text-white bg-primary-100"
                    }`}
                    data-value={day.day}
                    onClick={() => {
                      handleSelectedDay(day.day);
                    }}
                  >
                    {day.tag}
                  </div>
                ))}
              </div>
            </div>
            {/* work time */}
            <div className="mt-8 flex flex-col space-y-2">
              <div className="flex justify-between">
                <div>
                  Available Time-frame{" "}
                  <span className="text-gray-300 text-xs">
                    ({timeFrame?.startTime ?? "10:00"} -{" "}
                    {timeFrame?.endTime ?? "18:00"})
                  </span>
                </div>
                {(timeFrame.startTime || timeFrame.endTime) && (
                  <button
                    type="button"
                    className="text-primary-100 text-xs"
                    onClick={resetTime}
                  >
                    Clear Selection
                  </button>
                )}
              </div>
              <div className="flex gap-x-3 items-center overflow-x-scroll no-scrollbar">
                {time.map((t, index) => (
                  <label
                    key={index}
                    className={`flex flex-col items-center border border-[#E9E9E9] py-3 px-4 rounded-lg ${
                      t === timeFrame.startTime && "bg-primary-100 text-white"
                    } ${t === timeFrame.endTime && "bg-black text-white"}`}
                    htmlFor={String(index)}
                  >
                    <input
                      className="w-[0.1px] h-[0.1px] appearance-none"
                      id={String(index)}
                      type="radio"
                      name="time"
                      value={t}
                      onChange={(e) => {
                        handleTimeSelection(e.target.value);
                      }}
                    />
                    <span
                      className={`text-[#999999] ${
                        t === timeFrame.startTime && "bg-primary-100 text-white"
                      } ${t === timeFrame.endTime && "bg-black text-white"}`}
                    >
                      {t}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            <Button
              type="submit"
              className="w-full mt-10 h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
              onClick={() => {
                handleScheduleSubmit();
                // navigate("/rider/home")
              }}
            >
              {isScheduleLoading ? (
                <FaSpinner size={20} className="animate-spin" />
              ) : (
                "Complete Registration"
              )}
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CompleteRegistration;
