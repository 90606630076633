import moment from "moment";
import { IoMdTime } from "react-icons/io";
import { Link } from "react-router-dom";

type completedOrderProps = {
  owner: string;
  time: string;
  date: string;
  fee: number;
  id: string
};

const CompletedOrder = ({ owner, id, time, date, fee }: completedOrderProps) => {
  return (
    <Link to={`/rider/orders/${id}`}>

      <div className="mt-4 p-3 border border-grey-50 flex justify-between rounded-xl">
        <div className="flex flex-col">
          <h2 className="font-semibold text-lg">{owner}</h2>
          <div className="mt-4 flex items-center text-xs text-grey-300 gap-4">
            <div className="flex items-center gap-2 pr-4 border-r border-r-grey-100">
              <IoMdTime size={18} />
              <span>{moment(time).format("LTS")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span>{moment(date).format("L")}</span>
            </div>
          </div>
        </div>

        <h2 className="font-semibold text-lg">₦{fee}</h2>
      </div>
    </Link>
  );
};

export default CompletedOrder;
