export const TransactionSkeleton = () => (
    <div className="w-full flex items-start py-3 justify-between animate-pulse">
        <div className="flex w-full flex-col items-start justify-start gap-y-1">
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            <div className="flex  w-full items-center text-xs gap-x-2 text-gray-500">
                <div className="h-4 w-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-1/3"></div>
            </div>
        </div>
        <div className="h-6 w-20 bg-gray-200 rounded"></div>
    </div>
);
