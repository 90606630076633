import { cn } from "lib";
import { IoCalendarOutline } from "react-icons/io5";
import { IWallet } from "types/global.interface";
import moment from 'moment';
import { digitFormatter, getNairaEquivalent } from "utils/helper";

export function TransactionHistoryCard({ className, tx }: { className: string, tx: IWallet }) {
  return (
    <div
      className={cn("w-full flex items-start py-3 justify-between", className)}
    >
      <div className="flex flex-col items-start justify-start gap-y-1">
        {tx.action === 'cr' ? <p className="text-primary-100">Wallet Top Up</p> : <p className="text-red-500">Wallet Debited</p>}
        <div className="flex items-center text-xs gap-x-2 text-gray-500">
          <IoCalendarOutline className="text-lg" />
          <p> {moment(tx.createdAt).format("lll")} </p>
        </div>
      </div>

      <h2 className="text-base font-semibold">₦{digitFormatter(getNairaEquivalent(tx.amount))} </h2>
    </div>
  );
}
