import Navbar from "../components/Navbar";
import { SlCalender } from "react-icons/sl";
import coin from "../../../assets/Svg/coin.svg";
// import { CiDeliveryTruck } from "react-icons/ci";
// import { IoMdTime } from "react-icons/io";
import CompletedOrder from "./CompletedOrder";
import { useFetchMyOrders } from "utils/api";
import emptyBox from "../../../assets/Svg/emptyBox.svg";
import { getNairaEquivalent } from "utils/helper";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/composables/selectcn/Select";

const Deliveries = () => {
  const { data, isLoading } = useFetchMyOrders("completed");

  return (
    <main className="sm:max-w-[600px] mx-auto px-5 pb-24">
      <h1 className="font-semibold text-xl">Deliveries</h1>

      {/* select timeline */}

      <Select>
        <SelectTrigger className="mt-4 flex py-2 pl-3 items-center gap-2.5 border border-grey-50 focus-within:border-grey-300 rounded-lg max-w-[160px]">
          <SlCalender />
          <SelectValue placeholder="Today" />
        </SelectTrigger>
        <SelectContent className="bg-white rounded-lg">
          <SelectItem className="text-sm py-2.5 border-b" value="today">
            Today
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="yesterday ">
            Yesterday
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="this_week">
            This Week
          </SelectItem>
          <SelectItem className="text-sm py-2.5" value="this_month">
            This Month
          </SelectItem>
        </SelectContent>
      </Select>

      {/* progress indicator/display */}
      <div className="mt-4 rounded-2xl p-4 bg-[#d6ebc780]">
        <div className="flex items-center gap-1.5 text-[#446929]">
          <span className="text-3xl font-bold"> {data?.totalCount}</span>
          <span className="text-xs">Orders</span>
        </div>

        {/* milestone indicator */}
        <div className="relative mt-2 h-6 rounded-full bg-[#34501f41]">
          <div
            className="h-full border opacity-100 border-white rounded-full max-w-[36%]"
            style={{
              background: "linear-gradient(0deg, #7BBE4A 0%, #93E05B 100%)",
            }}
          ></div>
          <div className="absolute inset-0 flex items-center justify-evenly">
            <div className="w-1 h-1 rounded-full bg-[#34501F99]"></div>
            <div className="w-1 h-1 rounded-full bg-[#34501F99]"></div>
            <div className="w-1 h-1 rounded-full bg-[#34501F99]"></div>
            <div className="w-1 h-1 rounded-full bg-[#34501F99]"></div>
            <div className="w-1 h-1 rounded-full bg-[#34501F99]"></div>
          </div>
        </div>
      </div>

      {/* Amount earned */}
      <div className="relative mt-4 rounded-2xl p-4 bg-[#d6ebc780]">
        <h1 className="text-xs text-[#6A6A6A]">Amount Earned</h1>
        <h2 className="mt-2 text-3xl font-bold">₦0.00</h2>
        <img className="absolute right-0 top-0" src={coin} alt="" />
      </div>

      {/* completed orders */}
      <div className="mt-5">
        <h1 className="font-semibold text-xl">Completed Orders</h1>
        {(data?.items ?? [])?.filter((v) => v.status === "completed").length >
          0 && !isLoading ? (
          <div className="flex flex-col gap-3">
            {(data?.items ?? [])
              .filter((v) => v.status === "completed")
              .map((completedOrder, index) => (
                <CompletedOrder
                  owner={`${
                    completedOrder?.deliveryInfo?.contactName ?? "Name - N/A"
                  }`}
                  time={completedOrder.createdAt}
                  date={completedOrder.createdAt}
                  fee={getNairaEquivalent(
                    completedOrder.deliveryInfo.ridersFee
                  )}
                  id={completedOrder._id}
                />
              ))}
          </div>
        ) : (
          <div>
            {/* No order */}
            <div className="py-20 flex flex-col items-center">
              <img src={emptyBox} alt="" />
              <h2 className="mt-5 font-semibold text-lg">No Order</h2>
              <p className="mt-2">You don’t have any completed order</p>
            </div>
          </div>
        )}
      </div>
      <Navbar />
    </main>
  );
};

export default Deliveries;
