import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaSquareInstagram,
  FaXTwitter,
} from "react-icons/fa6";
import logo from "../../../assets/Svg/homelogo.svg";

const Footer = () => {
  return (
    <footer>
      <div className="px-5 lg:px-10 py-10 container mx-auto">
        <div className="flex lg:items-center justify-between flex-col lg:flex-row">
          <div>
            <h1 className="text-lg font-medium">Join Our Community</h1>
            <p className="mt-2 text-grey-200">
              Stay Updated About JekaEat Via Our Newsletter
            </p>
          </div>
          <div className="mt-4 lg:mt-0 flex items-center bg-grey-30 rounded-2xl min-w-80 pr-3 overflow-hidden border border-transparent focus-within:border-primary-100">
            <input
              className="py-3 pl-6 bg-transparent outline-none border-none grow"
              placeholder="Enter your email"
              type="text"
            />
            <button className=" bg-primary-100 text-white h-11 px-4 lg:px-10 rounded-full my-2">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="container px-5 lg:px-10 mx-auto">
        <div className="py-20 flex flex-col lg:flex-row justify-between border-y border-y-grey-50">
          <div className="flex flex-col lg:flex-row gap-2 items-center">
            <img className="w-24 lg:w-16 h-auto" src={logo} alt="" />
            <div className="lg:max-w-60">
              <p className="text-xs lg:leading-4 leading-5 text-grey-400 text-center lg:text-left">
                JekaEat continues to grow, combining technology, strategic
                partnerships, and customer-focused solutions to revolutionize
                food and agricultural produce delivery in Nigeria.
              </p>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row self-start gap-4 lg:my-0 my-8 lg:items-center ">
            <Link className="text-grey-200 hover:text-primary-100" to={"/"}>
              Customer Support
            </Link>
            <Link className="text-grey-200 hover:text-primary-100" to={"/"}>
              FAQ
            </Link>
            <Link className="text-grey-200 hover:text-primary-100" to={"/customer/about"}>
              About Us
            </Link>
            <Link className="text-grey-200 hover:text-primary-100" to={"/privacy"}>
              Terms And Conditions
            </Link>
            <Link className="text-grey-200 hover:text-primary-100" to={"/"}>
              Updates
            </Link>
          </div>
          <div className="flex flex-col lg:items-start items-center">
            <p className="text-xs text-grey-400 lg:max-w-72">
              No. 2, Street name, area, local government, state, country
            </p>
            <div className="flex items-center gap-4 lg:gap-3 mt-4">
              <a className="hover:text-primary-100" href="/">
                <FaFacebook size={25} />
              </a>
              <a className="hover:text-primary-100" href="/">
                <FaLinkedin size={25} />
              </a>
              <a className="hover:text-primary-100" href="/">
                <FaSquareInstagram size={25} />
              </a>
              <a className="hover:text-primary-100" href="/">
                <FaXTwitter size={25} />
              </a>
            </div>
          </div>
        </div>
        <div className="py-4 flex items-center flex-col lg:flex-row justify-between text-sm text-grey-400">
          <span>© Copyright {new Date().getFullYear()}. All Rights Reserved.</span>
          <span>Terms And Conditions</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
