import { useEffect, useState } from "react";
import MenuList from "../MenuList";
import { MealItemsEntity } from "types/global.interface";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../composables";
const FoodMenu = ({
  meals,
  section,
}: {
  meals: MealItemsEntity[] | undefined;
  section: string;
}) => {
  const [tab, setTab] = useState("all");
  const [inStock, setInStock] = useState<MealItemsEntity[] | undefined>([]);
  const [notInStock, setNotInStock] = useState<MealItemsEntity[] | undefined>(
    []
  );

  useEffect(() => {
    setInStock(meals?.filter((v) => v.inStock));
    setNotInStock(meals?.filter((v) => !v.inStock));
  }, [meals]);

  return (
    <div>
      {/* Tab Headers */}
      <div className="mt-5 p-2 flex gap-x-3">
        <div
          className={`py-2 px-6 text-center rounded-full ${
            tab === "all"
              ? "bg-[#70AD43] text-white"
              : "text-[#6A6A6A] bg-grey-30"
          }`}
          onClick={() => {
            setTab("all");
          }}
        >
          All
        </div>
        <div
          className={`py-2 px-6 text-center rounded-full ${
            tab === "inStock"
              ? "bg-[#70AD43] text-white"
              : "text-[#6A6A6A] bg-grey-30"
          }`}
          onClick={() => {
            setTab("inStock");
          }}
        >
          In Stock
        </div>
        <div
          className={`py-2 px-6 text-center rounded-full ${
            tab === "outStock"
              ? "bg-[#70AD43] text-white"
              : "text-[#6A6A6A] bg-grey-30"
          }`}
          onClick={() => {
            setTab("outStock");
          }}
        >
          Out of Stock
        </div>
      </div>

      <div className="mt-5 pb-16">
        {/* Tab Contents */}

        {section !== "foodStack" ? (
          <MenuList
            meals={
              tab === "all" ? meals : tab === "inStock" ? inStock : notInStock
            }
          />
        ) : (
          <Accordion type="multiple" defaultValue={["main-meal"]}>
            <AccordionItem value="main-meal">
              <AccordionTrigger>Main Meal</AccordionTrigger>
              <AccordionContent>
                <MenuList
                  meals={
                    tab === "all"
                      ? meals?.filter(v=>v.type === "main_meal")
                      : tab === "inStock"
                      ? inStock?.filter(v=>v.type === "main_meal")
                      : notInStock?.filter(v=>v.type === "main_meal")
                  }
                />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="side-meal">
              <AccordionTrigger>Side Meal</AccordionTrigger>
              <AccordionContent>
                <MenuList
                  meals={
                    tab === "all"
                      ? meals?.filter(v=>v.type === "side_meal")
                      : tab === "inStock"
                      ? inStock?.filter(v=>v.type === "side_meal")
                      : notInStock?.filter(v=>v.type === "side_meal")
                  }
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default FoodMenu;
