import { IUserOrder, ItemsEntity } from "types/global.interface";
import OrderCard from "./OrderCard";
import { OrderCardSkeleton } from '../../composables/Skeletons/OrderCard';

const NewOrders = ({ order, isLoading }: { order: IUserOrder | any, isLoading: boolean }) => {

  return (
    <div className="pt-5 flex flex-col gap-4">
      {
        isLoading ? <OrderCardSkeleton /> :
          <div className='flex flex-col space-y-3'>
            {order?.items?.map((v: ItemsEntity, key: number) => (
              <OrderCard order={v} key={key} />
            ))}
          </div>
      }
    </div>
  );
};


export default NewOrders;
