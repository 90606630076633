// import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
// import { Button } from "../../../composables";
// import { ICartItems, MealsEntity } from "Redux/reducer/cart";
// import {
//   addFoodItemsToCart,
//   decreaseSingleCartItems,
//   increaseSingleCartItems,
// } from "Redux/actions/cart";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "Redux/reducer";
// import { MealItemsEntity } from "types/global.interface";
// import { toast } from "react-toastify";

// export function FoodItemCounter({ item: currentMeal }: { item?: MealsEntity }) {
//   const dispatch = useDispatch();
//   const { cartItems, currentOrderIndex } = useSelector(
//     (state: RootState) => state.cart
//   );
//   const getSinglePersonItem = cartItems.find(
//     (item) => item.order_id === currentOrderIndex
//   ) as ICartItems;
//   const getMeal = getSinglePersonItem?.items.find(
//     (item) => item.meal === currentMeal?._id as string
//   ) as MealsEntity;

//   console.log(currentOrderIndex);
//   console.log(getSinglePersonItem);

//   function verifyCurrentVendor(vendorId: string) {
//     if (cartItems.length > 0) {
//       if (
//         cartItems[0].items.length > 0 &&
//         cartItems[0].items[0].vendorId === vendorId
//       ) {
//         //check the owner of the item in cart
//         return true;
//       } else return false;
//     } else return true;
//   }
//   const handleOrderItem = (item: MealItemsEntity) => {

//     console.log(item);
//     // item = { ...item, vendor: { _id: item.vendorId as string } }

//     if (!verifyCurrentVendor(item.vendorId || item.vendor._id)) {
//       toast.error(
//         "You can't order from 2 different vendor, if you wish to continue do empty your cart"
//       );
//       return;
//     }
//     console.log(item);
//     const payload = {
//       name: item.name,
//       image: item.image,
//       meal: item._id,
//       quantity: 1,
//       unitPrice: item.unitPrice,
//       vendorId: item.vendorId || item.vendor._id,
//     };
//     dispatch(addFoodItemsToCart(payload));
//   };
//   return (
//     <button
//       onClick={(e) => {
//         e.stopPropagation();
//       }}
//       className="w-fit flex items-center gap-x-3"
//     >
//       <Button
//         onClick={() => {
//           dispatch(
//             decreaseSingleCartItems(
//               currentOrderIndex as string,
//               currentMeal?.meal as string
//             )
//           );
//         }}
//         className="px-0 w-fit h-fit"
//       >
//         <CiCircleMinus className="text-xl" />
//       </Button>
//       <p className=""> {getMeal ? getMeal.quantity : 1} </p>
//       <Button
//         // onClick={() => handleOrderItem(currentMeal as any)}

//         onClick={() => {
//           dispatch(
//             increaseSingleCartItems(
//               currentOrderIndex as string,
//               currentMeal?.meal as string
//             )
//           );
//         }}
//         className="px-0 w-fit h-fit"
//       >
//         <CiCirclePlus className="text-xl" />
//       </Button>
//     </button>
//   );
// }



import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { Button } from "../../../composables";
import { MealsEntity } from "Redux/reducer/cart";
import {
  decreaseSingleCartItems,
  increaseSingleCartItems,
} from "Redux/actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";

export function FoodItemCounter({ item }: { item?: MealsEntity }) {
  const { currentOrderIndex } = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-fit flex items-center gap-x-3"
    >
      <Button
        onClick={() => {
          dispatch(
            decreaseSingleCartItems(
              currentOrderIndex as string,
              item?.product as string
            )
          );
        }}
        className="px-0 w-fit h-fit"
      >
        <CiCircleMinus className="text-xl" />
      </Button>
      <p className=""> {item?.quantity} </p>
      <Button
        onClick={() => {
          dispatch(
            increaseSingleCartItems(
              currentOrderIndex as string,
              item?.product as string
            )
          );
        }}
        className="px-0 w-fit h-fit"
      >
        <CiCirclePlus className="text-xl" />
      </Button>
    </button>
  );
}
