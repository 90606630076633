import bike from "../../../assets/Svg/offlineBike.svg";
import colorBike from "../../../assets/Svg/onlineBike.svg";
import info from "../../../assets/Svg/infoIcon.png";
import emptyBox from "../../../assets/Svg/emptyBox.svg";
import { SlArrowRight } from "react-icons/sl";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Order from "../Order/Order";
import { useFetchAvailableOrders, useUpdateAccountStatus } from "utils/api";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import order from "../../../assets/Svg/orderIcon.svg";
import { IoClose } from "react-icons/io5";

const RiderHome = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [isOnline, setIsOnline] = useState(currentUser?.online);

  const { mutateAsync } = useUpdateAccountStatus();

  const { data, isLoading, refetch } = useFetchAvailableOrders();
  console.log(data);
  useEffect(() => {
    if (isOnline)
      refetch()
  }, [isOnline, refetch])


  return (
    <main className="sm:max-w-[600px] mx-auto px-5 pb-24">
      <Navbar />
      <div className="flex justify-between items-center">
        <div>
          <h1 className="font-semibold text-xl">
            Hi, {currentUser?.fullName.split(" ")[0]}
          </h1>
          <p className="text-grey-200">Welcome Back</p>
        </div>
        <div className="w-8 h-8 flex justify-center items-center rounded-full bg-grey-400">
          <span className="text-white">
            {currentUser?.fullName[0].toUpperCase()}
          </span>
        </div>
      </div>

      {/* Offline Online Control */}
      <div
        className={`relative mt-10 px-8 h-20 flex items-center rounded-3xl overflow-hidden cursor-pointer  ${isOnline ? "bg-primary-300" : "bg-grey-30"
          } `}
        onClick={() => {
          setIsOnline((prev) => !prev);
          mutateAsync();
        }}
      >
        <span className="font-semibold text-xl">
          {isOnline ? "Go Offline" : "Go Online"}
        </span>
        {isOnline ? (
          <img className="absolute right-8 -bottom-0" src={colorBike} alt="" />
        ) : (
          <img className="absolute right-8 -bottom-0" src={bike} alt="" />
        )}
      </div>

      {/* New order */}
      <div>
        <h1 className="mt-10 font-semibold text-xl">New Order</h1>
        {
          (currentUser?.schedule.length === 0 || !currentUser?.payoutSettlement?.account?.accountName) && <Link
            to={"/rider/complete-registration"}
            // onClick={() => setIsRegistrationComplete(true)}
            className="relative mt-3 px-3 h-20 flex justify-between items-center rounded-3xl text-[#B58524] bg-[#FFFBF4] border border-dashed border-[#FFC95C]"
          >
            <img src={info} alt="" />
            <span className="text-xs">
              Complete Onboarding before you can take an order
            </span>
            <SlArrowRight />
          </Link>
        }




        {isOnline && (
          <div>
            {(data?.items ?? [])?.length > 0 && !isLoading ? (
              <>
                {/* // available order notification */}
                <div className="relative mt-3 px-3 h-20 flex justify-between items-center rounded-3xl text-[#A1DDF3] bg-[#EBF8FC] border border-dashed border-[#A1DDF3]">
                  <div className="flex items-center gap-5">
                    <img src={order} alt="" />
                    <span className="text-xs">
                      You have {data?.items?.length ?? 0} available Order
                    </span>
                  </div>
                  <IoClose />
                </div>
                {/* // Orders */}
                <div className="py-5 flex flex-col gap-4">
                  {(data?.items ?? []).map((order, key) => (
                    <Order order={order} key={key} />
                  ))}
                </div>
              </>
            ) : (
              <>
                {/* No order */}
                <div className="py-20 flex flex-col items-center">
                  <img src={emptyBox} alt="" />
                  <h2 className="mt-5 font-semibold text-lg">No Order</h2>
                  <p className="mt-2">You don’t have an active order</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default RiderHome;
