import { ArrowLeft } from "iconsax-react";

import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Location,
} from "../../composables";
import { AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const AddLocation = () => {
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [location, setLocation] = useState<string>();
  const navigate = useNavigate();
  const form = useForm();

  const selectLocation = (value: string) => {
    setLocation(value);
  };

  useEffect(() => {
    form.setValue("location", location);
  }, [form, location]);

  return (
    <main className="min-h-screen px-5 pb-24 ">
      {/* Header */}
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Add Location</h1>
      </div>

      {/* Location 1 */}
      <div className="mt-5 py-5 border-t bordet-t-[#E9E9E9]">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-lg">Location 1</h1>
          <button>
            <SlOptionsVertical size={25} />
          </button>
        </div>

        {/* Location */}
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
              console.log(data);
            })}
            className="mt-5 pb-2"
          >
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Location</FormLabel>
                  <FormControl>
                    <Input
                      defaultValue={location}
                      readOnly
                      type="text"
                      onFocus={() => {
                        setIsLocationOpen(true);
                      }}
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <AnimatePresence initial={false} mode="wait">
              {isLocationOpen && (
                <Location
                  closeLocation={() => {
                    setIsLocationOpen(false);
                  }}
                  selectLocation={selectLocation}
                ></Location>
              )}
            </AnimatePresence>

            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input
                      defaultValue="Shop 10. FUTMinna Shopping Complex, Minna Niger state."
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      readOnly
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>

      {/* Location 2 */}
      <div className="mt-5 py-5 border-t bordet-t-[#E9E9E9]">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-lg">Location 2</h1>
          <button>
            <SlOptionsVertical size={25} />
          </button>
        </div>

        {/* Location */}
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
              console.log(data);
            })}
            className="mt-5 pb-2"
          >
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Location</FormLabel>
                  <FormControl>
                    <Input
                      defaultValue={location}
                      readOnly
                      type="text"
                      onFocus={() => {
                        setIsLocationOpen(true);
                      }}
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <AnimatePresence initial={false} mode="wait">
              {isLocationOpen && (
                <Location
                  closeLocation={() => {
                    setIsLocationOpen(false);
                  }}
                  selectLocation={selectLocation}
                ></Location>
              )}
            </AnimatePresence>

            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input
                      defaultValue="Shop 10. FUTMinna Shopping Complex, Minna Niger state."
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      readOnly
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
    </main>
  );
};

export { AddLocation };
