import { ICartItems } from "Redux/reducer/cart";
import { Button } from "../../../../composables";
import { OrderCard } from "../orderCard/OrderCard";
import { HiMiniPlusSmall } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { updateCurrentOrderIndex } from "Redux/actions/cart";
import { useNavigate } from "react-router-dom";
// updateCurrentOrderIndex
export function OrderPack({
  order,
  packageNo,
}: {
  order: ICartItems;
  packageNo: number;
}) {
  const { activeVendor } = useSelector((state: RootState) => state.user);
  const { currentOrderIndex } = useSelector((state: RootState) => state.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col gap-y-4 px-3 py-5 items-start justify-start border rounded-3xl">
      <h2 className="font-semibold text-base">Order {packageNo}</h2>

      <div className="w-full flex flex-col ">
        {order.items.map((item, key) => (
          <OrderCard item={item} key={key} />
        ))}
      </div>
      <div className="w-full flex items-end justify-end">
        
        <Button
          onClick={() => {
            dispatch(updateCurrentOrderIndex(currentOrderIndex as string));
            navigate(`/customer/vendor/${activeVendor?._id}`);
          }}
          className="border-dashed border border-black  transform transition-all duration-300 ease-in-out rounded-3xl h-11 px-6 gap-x-2"
        >
          <HiMiniPlusSmall className="text-[22px]" />
          <p>Add to Pack</p>
        </Button>
      </div>
    </div>
  );
}
