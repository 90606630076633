import { CiEdit } from "react-icons/ci";
import Navbar from "../components/Navbar";
import { FaStar } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowRight2,
  I24Support,

  LogoutCurve,
  MoneySend,

  // RouteSquare,
  SecurityTime,
  Timer1,
  Truck,
} from "iconsax-react";
import { CiRoute } from "react-icons/ci";

import { User2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { useGetUserProfile } from '../../../utils/api';

const profileLinks = [
  {
    icon: <Truck size={18} />,
    title: "Vehicle",
    path: "/rider/vehicle",
  },
  {
    icon: <CiRoute size={18} />,
    title: "Route Optimization",
    path: "/rider/route-optimization",
  },
  {
    icon: <MoneySend size={18} />,
    title: "Account Settlement",
    path: "/account-settlement",
  },
  {
    icon: <Timer1 size={18} />,
    title: "Schedule",
    path: "/schedule",
  },
  // {
  //   icon: <Wallet size={18} />,
  //   title: "Wallet",
  //   path: "/rider/wallet",
  // },
  {
    icon: <I24Support size={18} />,
    title: "Support & FAQs",
    path: "/rider/support",
  },
  {
    icon: <SecurityTime size={18} />,
    title: "Login & Security",
    path: "/rider/login-security",
  },
  {
    icon: <SecurityTime size={18} />,
    title: "Privacy and Policy",
    path: "/privacy",
  },
  {
    icon: <SecurityTime size={18} />,
    title: "Term of Use",
    path: "/rider/tou",
  },

];

const RiderProfile = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  useGetUserProfile()
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  return (
    <main className="px-5 pb-24">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-xl">Profile</h1>
        <Link to={"/rider/edit-profile"}>
          <CiEdit size={25} />
        </Link>
      </div>

      {/* Avatar and Rider info */}
      <div className="flex flex-col items-center py-10 border-b-[3px] border-b-grey-50">
        {/* Avatar */}
        <div className="w-20 h-20 border grid place-content-center rounded-full overflow-hidden">
          {currentUser?.profileImage ? <img src={currentUser.profileImage.url} alt="profileImage" /> : <User2 size={40} />}
        </div>
        <h2 className="mt-2 font-semibold text-xl">{currentUser?.fullName}</h2>
        <span className="mt-1 text-xs text-[#999999]">
          {currentUser?.phoneNumber}
        </span>
        <div className="mt-1 px-2 1 flex items-center gap-2 rounded-full border border-[#FFD176] bg-[#FFF8EB]">
          <FaStar color="#FFD700" />
          <span>4.5</span>
        </div>
      </div>

      {/* Account settings */}
      <div className="py-6">
        <h1 className="font-semibold text-xl">Account Settings</h1>

        {/* Links */}
        <div className="mt-4">
          {profileLinks.map((link) => (
            <Link
              to={link.path}
              className="flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#f4f4f4]">
                  {link.icon}
                </div>
                <h2>{link.title}</h2>
              </div>
              <ArrowRight2 color="#BABABA" size={20} />
            </Link>
          ))}
          <button
            onClick={() => {
              navigate("/auth/signin");
              dispatch({
                payload: null,
                type: "LOGOUT",
              });
            }}
            className="w-full flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
          >
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#FFECEC]">
                <LogoutCurve color="#E83E3E" size={18} />
              </div>
              <h2>Logout</h2>
            </div>
          </button>
        </div>
      </div>
      <Navbar />
    </main>
  );
};

export default RiderProfile;
