import { Success } from "assets/icon";
import { Button } from "components/composables";
import { useMemo, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { PiBackspaceFill } from "react-icons/pi";
import { useQueryClient } from "react-query";

// import { generateWalletToken } from "Utils/api";
import { toast } from "react-toastify";
import { convertCurrencyStringToNumber, getKoboEquivalent } from "utils/helper";
import { usePaymentHandler } from "utils/usePayment";

export default function TopUp({ close }: { close: () => void }) {
  const client = useQueryClient();
  const [addedPrice, setAddedPrice] = useState<string>("");

  // i don't know the best approach to this
  // I am hoping to change it when i figure out the best approach
  const numbers: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "0",
  ];

  const handleClick = (digit: string) => {
    if (addedPrice === "0") {
      setAddedPrice(digit);
    } else {
      setAddedPrice(`${addedPrice}${digit}`);
    }
  };

  // format
  const formattedPrice = useMemo(() => {
    return `₦${Number(addedPrice)?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }, [addedPrice]);

  // delete didgit
  function handleDelete() {
    if (addedPrice.length === 0) return;
    setAddedPrice(addedPrice.slice(0, -1));
  }

  function onSuccess(ref?: any) {
    close();
    toast.success("Top-up Successful", {
      icon: Success,
    });
    client.refetchQueries("get_user_wallet");
  }

  const payload = {
    amount: convertCurrencyStringToNumber(formattedPrice),
  };

  const { loading, triggerPayment } = usePaymentHandler(
    {
      amount: getKoboEquivalent(payload.amount),
    },
    payload.amount,
    "wallet",
    onSuccess
  );

  return (
    <div className="w-full max-w-[600px] mx-auto fixed inset-0 transform transition-all duration-300  min-h-screen py-8 px-5 bg-white z-[9999] flex flex-col justify-between items-start ">
      <Button onClick={close} className="px-0 w-fit h-fit gap-x-2">
        <FaArrowLeft className="text-[22px]" />
        <h2 className="font-semibold text-base">Enter Amount</h2>
      </Button>

      <div className="w-full flex items-center justify-center">
        <input
          type="text"
          className="border-0 font-medium focus-visible:bg-none outline-none w-fit h-fit text-4xl placeholder:text-gray-400 text-center"
          placeholder="₦0,00"
          value={formattedPrice}
          readOnly
        />
      </div>

      <div className="grid grid-cols-3 items-center gap-x-4 gap-y-8 w-full">
        {numbers.map((value, index) => (
          <div className="w-full items-center justify-center flex" key={index}>
            <Button
              disabled={index === 9 && addedPrice.includes(".")}
              onClick={() => handleClick(value)}
              key={value}
              className="px-2 py-2 h-11 w-11 text-xl font-semibold rounded-full hover:bg-gray-50 text-black"
            >
              {value}
            </Button>
          </div>
        ))}
        <div className="w-full items-center justify-center flex">
          <Button
            onClick={handleDelete}
            className="px-2 py-2 h-11 w-11 rounded-full hover:bg-gray-50"
          >
            <PiBackspaceFill className="text-2xl" />
          </Button>
        </div>
      </div>
      <Button
        className="w-full py-2 px-3 rounded-xl bg-primary-100 text-white"
        onClick={() => {
          triggerPayment();
          // console.log(formattedPrice);
        }}
      >
        {loading ? (
          <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
        ) : (
          "Top Up"
        )}
      </Button>
    </div>
  );
}
