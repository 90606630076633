
import EasyOrder from "../../assets/Svg/easy_order.svg";
import Fastest_delivery from "../../assets/Svg/fastest_delivery.svg";
import Best_quality from "../../assets/Svg/best-quality.svg";
import createstore from "../../assets/Svg/create_store.svg";
import easypayout from "../../assets/Svg/easy_payout.svg";
import ordermanagement from "../../assets/Svg/order_man.svg";
import caterer from "../../assets/images/caterer.png"
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

function Services() {
  return (
    <div id="service" className="md:mt-[300px] py-10 md:px-[100px] px-6">
      <h2 className=" tracking-wider text-primary-100 font-normal text-center mb-3">
        What we Serve
      </h2>
      <div className="flex flex-col justify-center items-center">
        <div className="max-w-[400px] mb-5 text-center md:text-4xl text-3xl font-semibold text-[#464255]">
          Your Favourite Food Delivery Partner
        </div>
        <div className="grid md:grid-cols-3 gap-10 mt-6 mx-auto">
          <div className="max-w-[300px]  flex flex-col  justify-center items-center">
            <img src={EasyOrder} alt="EasyOrder" />
            <h2 className="text-center font-semibold text-xl mt-10 mb-2 text-[#464255]">
              Easy To Order{" "}
            </h2>
            <h5 className="text-center text-base text-[#464255]">
              You only need a few steps in ordering food
            </h5>
          </div>
          <div className="max-w-[300px]  flex flex-col space-y-3 justify-center items-center">
            <img src={Fastest_delivery} alt="EasyOrder" />
            <h2 className="text-center font-semibold text-xl text-[#464255]">
              Fastest Delivery{" "}
            </h2>
            <h5 className="text-center text-base text-[#464255]">
              Delivery that is always ontime even faster{" "}
            </h5>
          </div>
          <div className="max-w-[300px]  flex flex-col space-y-3 justify-center items-center">
            <img src={Best_quality} alt="EasyOrder" />
            <h2 className="text-center font-semibold text-xl text-[#464255]">
              Best Quality{" "}
            </h2>
            <h5 className="text-center text-base text-[#464255]">
              Not only fast for us quality is also number one{" "}
            </h5>
          </div>
        </div>
      </div>

      <div className="md:my-28 mt-16 grid md:grid-cols-2 gap-10">
        <div>
          <img src={caterer} className="border-md" alt="" />
        </div>
        <div className="mt-10 flex flex-col space-y-8">
          <div className="text-[#464255] font-bold md:text-5xl text-3xl max-w-[600px]">
            Nourishing Progress: Partner with
            <span className="jkt-underline"> Jekaeat.</span>
          </div>
          <div className="font-normal   text-[#46425580]">
            Unlock Business Growth Opportunities by Leveraging Enhanced
            Features. Streamline Order Management for Increased Profitability.
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-center space-x-4">
              <img src={createstore} alt="" />
              <h2>Create your own Online Store</h2>
            </div>
            <div className="flex items-center space-x-4">
              <img src={easypayout} alt="" />
              <h2>Easy Payout</h2>
            </div>
            <div className="flex items-center space-x-4">
              <img src={ordermanagement} alt="" />
              <h2>Order Management</h2>
            </div>
          </div>
          <div className="mt-4">
           
            <Link
              className="text-white w-max flex space-x-3 items-center bg-primary-100 rounded-md md:px-8 px-4 md:py-3 py-2"
              to="/vendor/signup"
            >
              <span>Get Started</span>
              <BsArrowRight />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
