import { EmptyTransaction } from "constants/icon";

export function EmptyTransactionCard() {
  return (
    <div className="w-full flex flex-col justify-center items-center h-80 gap-y-2">
      <EmptyTransaction />
      <p className="text-xs font-semibold">No Transaction</p>
      <p className="text-xs max-w-[150px] text-center flex flex-wrap items-center justify-center">
        You haven't made any transactions yet
      </p>
    </div>
  );
}
