import { Button, Form, FormField } from "../../composables";
import { useForm } from "react-hook-form";
import { ArrowLeft, Wallet } from "iconsax-react";
import visa from "../../../assets/images/Visa.png";
import masterCard from "../../../assets/images/Mastercard.png";
import { digitFormatter, getNairaEquivalent } from "utils/helper";

import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";

export function PaymentMethods({
  onClose,
  setPaymentMode,
  walletBalance,
  triggerPayment,
  loading,
}: {
  onClose: () => void;
  setPaymentMode: (v: string) => void;
  walletBalance: number;
  triggerPayment: () => void;
  loading: boolean;
}) {
  const form = useForm();
  const { currentUser } = useSelector((state: RootState) => state.user);

  return (
    <div className="bg-white fixed inset-0 w-full flex flex-col gap-y-3 items-start justify-start px-4 pt-9 pb-4">
      <Button onClick={onClose} className="px-0 gap-x-2 font-medium">
        <ArrowLeft className="text-2xl" />
        <h1 className="font-semibold text-xl">Payment Method</h1>
      </Button>

      <Form {...form}>
        <form
          className="w-full flex flex-col items-start justify-start"
          onSubmit={form.handleSubmit((data) => { })}
        >
          {["Wallet", "Card"].map((value, index) => (
            <FormField
              control={form.control}
              name="paymentMethod"
              render={({ field }) => (
                <label
                  key={value}
                  className="pay-container w-full my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center"
                >
                  <div className="flex items-center gap-x-2">
                    <input
                      type="radio"
                      {...field}
                      value={value}
                      defaultChecked={
                        currentUser?.preferredPaymentMethod?.toLowerCase() ===
                        value.toLowerCase()
                      }
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                      className=""
                    />
                    <span className="pay-checkmark"></span>
                    <span className="pl-8 capitalize">{value}</span>
                  </div>

                  {index === 0 ? (
                    <div className="flex space-x-3 items-center">
                      <div className="font-medium">
                        {" "}
                        ₦
                        {digitFormatter(getNairaEquivalent(walletBalance ?? 0))}
                      </div>
                      <Wallet size="22" color="#BABABA" variant="Outline" />
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-2">
                      <img src={visa} alt="" />
                      <img src={masterCard} alt="" />
                    </div>
                  )}
                </label>
              )}
            />
          ))}

          <Button
            onClick={() => triggerPayment()}
            className="w-full h-10 py-2 px-3 rounded-lg mt-6 bg-primary-100 text-white"
          >
            {loading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Proceed to Pay"
            )}
          </Button>
        </form>
      </Form>
    </div>
  );
}
