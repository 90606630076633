export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_START = "FETCH_USER_START";
export const GET_ACTIVE_VENDOR = "GET_ACTIVE_VENDOR";
export const UPDATE_HANDPICKED_MEAL = "UPDATE_HANDPICKED_MEAL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_START = "LOGIN_START";

export const LOGOUT = "LOGOUT";

export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_ITEMS_COUNT_INCREMENT = "GET_ITEMS_COUNT_INCREMENT";
export const GET_ITEMS_COUNT_DECREMENT = "GET_ITEMS_COUNT_DECREMENT";
export const GET_FOOD_ITEMS = "GET_FOOD_ITEMS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SUM_FOOD_ITEMS = "SUM_FOOD_ITEMS";
export const RESET_STACK_PRICE = "RESET_STACK_PRICE";
export const ADD_FOODITEMS_TO_CART = "ADD_FOODITEMS_TO_CART";
export const ADD_MEALPACK_TO_CART = "ADD_MEALPACK_TO_CART";
export const RESET_CART = "RESET_CART";
export const SHOW_LIVE_CHAT = "SHOW_LIVE_CHAT";
export const GET_VENDOR_INFO = "GET_VENDOR_INFO";
export const INCREASE_CART_ITEMS = "INCREASE_CART_ITEMS";
export const DECREASE_CART_ITEMS = "DECREASE_CART_ITEMS";
export const SHOW_CART_COUNT = "SHOW_CART_COUNT";
export const SEND_ORDER_TO_VENDOR = "SEND_ORDER_TO_VENDOR";
export const RE_ORDER_FROM_VENDOR = "RE_ORDER_FROM_VENDOR";
export const GET_FEATURED_VENDORS = "GET_FEATURED_VENDORS";
export const GET_PREVIOUS_ORDERS = "GET_PREVIOUS_ORDERS";
export const GET_USER_PREVIOUS_ORDER_INFO = "GET_USER_PREVIOUS_ORDER_INFO";
export const GET_USER_PERSONAL_INFORMATION = "GET_USER_PERSONAL_INFORMATION";
export const VIEW_CHAT = "VIEW_CHAT";
