export const VendorListSkeleton = () => (
    <div className="w-full flex flex-col gap-y-1 animate-pulse">
        <div className="w-full rounded-lg h-36 relative bg-white overflow-hidden">
            <div className="w-full h-full bg-gray-200"></div>
            <div className="absolute top-3 right-3 rounded-3xl px-2 py-1">
                <div className="h-4 bg-gray-200 rounded"></div>
            </div>
            <div className="h-7 absolute bottom-3 right-3 w-7 rounded-full bg-gray-200">
                <div className="h-4 bg-gray-200 rounded"></div>
            </div>
        </div>
        <div className="py-1 gap-y-1 px-2 flex flex-col items-start justify-start">
            <div className="flex items-center justify-between w-full mb-3">
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div>
            {/* <div className="flex items-center gap-x-2">
                <div className="text-gray-400 pr-4 border-r border-gray-400">
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div> */}
        </div>
    </div>
)