import { cn } from "../../../../lib";
import { Button } from "../../../composables";

export function OrderSummary({
  isCheckOut,
  orderFee,
  deliveryFee,
  serviceCharge,
  total,
  discountAmount
}: {
  isCheckOut?: boolean;
  orderFee?: number;
  deliveryFee?: number;
  serviceCharge?: number;
  total?: number;
  discountAmount?: number;
}) {
  console.log(discountAmount);

  return (
    <div
      className={cn("w-full px-4 flex flex-col gap-y-8", isCheckOut && "px-0")}
    >
      <div className="w-full  flex flex-col gap-y-2 items-start justify-start p-4 rounded-3xl bg-[#F4F4F4]">
        <div className="w-full flex items-center justify-between">
          <p className=" ">Order Fee</p>
          <p className="font-medium">₦{orderFee?.toFixed(2)}</p>
        </div>
        <div className="w-full flex items-center justify-between">
          <p className=" ">Delivery</p>
          <p className="font-medium">₦{deliveryFee?.toFixed(2)}</p>
        </div>
        <div className="w-full flex items-center justify-between">
          <p className=" ">Discount</p>
          <p className={cn(discountAmount && discountAmount > 0 ? 'font-medium line-through text-gray-500' : 'font-medium ')}>₦{discountAmount ? discountAmount?.toFixed(2) : 0} </p>
        </div>
        {/* <div className="w-full flex items-center justify-between">
          <p className=" ">Discount</p>
          <p className="font-medium">₦0</p>
        </div> */}
        <div className="w-full flex items-center justify-between">
          <p className=" ">Service Charges</p>
          <p className="font-medium">₦{serviceCharge?.toFixed(2)}</p>
        </div>
        <div className="w-full flex items-center justify-between">
          <p className=" ">Total</p>
          <p className="font-semibold text-base">₦{total?.toFixed(2)}</p>
        </div>
      </div>
      {!isCheckOut && (
        <div className="w-full items-center h-fit justify-center grid grid-cols-2 gap-4">
          <Button className="h-11 text-[#7BBE4A] border border-[#7BBE4A] rounded-lg ">
            Edit Order
          </Button>
          <Button className="h-11 text-gray-50 bg-[#7BBE4A] rounded-lg ">
            Re-Order
          </Button>
        </div>
      )}
    </div>
  );
}
