/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ileyan from "../../assets/Svg/ileyan.svg";
import playstore from "../../assets/Svg/playstore.svg";
import apple from "../../assets/Svg/apple.svg";
import hero from "../../assets/Svg/home-hero.svg";
import takeaway from "../../assets/Svg/takeaway.svg";
import sliderImage1 from "../../assets/images/JekaeatImage1.jpeg";
import sliderImage2 from "../../assets/images/JekaeatImage2.jpeg";
import sliderImage3 from "../../assets/images/JekaeatImage3.jpg";
import transparency from "../../assets/icon/transperency.svg";
import empathy from "../../assets/icon/empathy.svg";
import honesty from "../../assets/icon/honesty.svg";
import sincerity from "../../assets/icon/sincerity.svg";
import teamwork from "../../assets/icon/teamwork.svg";
import clearCommunications from "../../assets/icon/clear-communications.svg";
import AnimatedText from "./components/AnimatedText";
import { isWebview } from "utils/helper";
import ImageSlider from "./components/imageslider";

function Home() {
  const navigate = useNavigate();
  // const handleSubmit = () => {
  //   localStorage.setItem("newUser", "false");
  //   navigate("/explore");
  // };

  const isNewUser = localStorage.getItem("newUser");
  const userRole = localStorage.getItem("role");
  const checkIsWebview = isWebview();

  const images = [
    sliderImage1,
    sliderImage2,
    sliderImage3,
  ];

  useEffect(() => {
    if (checkIsWebview) {
      if (isNewUser === "false") {
        navigate("/auth/signin");
      } else {
        // navigate("/explore");
      }
      navigate("/auth/signin");
    } else {
      //Always Show Explore page on Web: Jekaeat.io
      // navigate("/explore");
    }
  }, [isNewUser, navigate, userRole]);



  return (
    <div>
      {/* Home */}
      <section className="md:min-h-[calc(100dvh-58px)] container mx-auto px-5 lg:px-10 flex items-center lg:flex-row flex-col-reverse lg:*:w-1/2 gap-8 lg:h-[500px] lg:min-h-0 py-10">
        <div>
          <h1 className="font-medium text-2xl lg:text-4xl">
            We Deliver{" "}
            <AnimatedText
              textList={["Food", "Groceries"]}
              className="text-primary-100"
            />
            <br />
            Exactly What Is Ordered To{" "}
            <AnimatedText
              textList={["Hotels", "Offices", "Homes", "Restaurant"]}
              className="text-primary-100"
            />
            .
          </h1>
          <p className="mt-6 text-lg text-grey-200">
            At Jekaeat, we ensure what you order is exactly what you get,
            without hidden fees or inflated prices with doorstep delivery.🍔🍝🚀
          </p>
          <div>
            <div className="flex mt-4 gap-4 flex-col lg:flex-row max-w-44 lg:max-w-full">
              <a
                href="https://play.google.com/store/apps/details?id=com.jekaeat.jekaeat"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2"
              >
                <img className="w-8" src={playstore} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">GET IT ON</p>
                  <p className="font-medium text-lg">Google Play</p>
                </div>
              </a>

              <a
                href="https://apps.apple.com/ng/app/jekaeat/id6452400533"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2"
              >
                <img className="w-8" src={apple} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">Download on the</p>
                  <p className="font-medium text-lg">App Store</p>
                </div>
              </a>
            </div>
            <button
              onClick={() => navigate("/auth/signin")}
              className="mt-4 text-primary-100 px-4 py-2 rounded-full border border-primary-100"
            >
              Use Web App To Order
            </button>
          </div>
        </div>
        <div>
          <img className="w-full h-auto" src={hero} alt="" />
        </div>
      </section>

      <section className="container mx-auto">
        <div className="py-20 px-5 lg:px-10 flex lg:gap-y-6 lg:items-center flex-col lg:flex-row">
          <p className="text-3xl lg:text-6xl font-medium lg:w-[400px]">
            Our Partners In Food
          </p>
          <div className="flex items-center justify-between grow overflow-x-auto" style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}>
            <img className="w-20" src={ileyan} alt="" />
            <img className="w-20" src={ileyan} alt="" />
            <img className="w-20" src={ileyan} alt="" />
            <img className="w-20" src={ileyan} alt="" />
            <img className="w-20" src={ileyan} alt="" />
          </div>
        </div>
      </section>

      <section className="container mx-auto">
        <div className="py-20 px-5 lg:px-10 flex flex-col lg:flex-row items-center gap-10">
          <img src={takeaway} alt="" />
          <div className="">
            <h2 className="text-2xl lg:text-[48px] leading-tight font-medium">
              Track Your Orders & Inventory with Ease
            </h2>
            <p className="text-grey-200 mt-6 text-lg">
              You get access to detailed, up-to-date inventories of all your
              foodstuffs, farm produce & food orders with JekaEat to ensure you
              Stay organized and track your spending.
            </p>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-5 lg:px-10 grid place-content-center  gap-8 lg:min-h-0 py-20">
        <div className="lg:max-w-xl">
          <h2 className="text-center text-2xl font-medium">
            Our Values - What Kept Us Growing
          </h2>
          <div className="mt-8 grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-8 text-grey-200">
            <div className="flex flex-col h-24 justify-between items-center gap-2">
              <img className="w-15 h-15" src={transparency} alt="" />
              <p className="text-center">Transparency</p>
            </div>
            <div className="flex flex-col h-24 justify-between items-center gap-2">
              <img className="w-15 h-15" src={honesty} alt="" />
              <p className="text-center">Honesty</p>
            </div>
            <div className="flex flex-col h-24 justify-between items-center gap-2">
              <img className="w-15 h-15" src={sincerity} alt="" />
              <p className="text-center">Sincerity</p>
            </div>
            <div className="flex flex-col h-24 justify-between items-center gap-2">
              <img className="w-15 h-15" src={clearCommunications} alt="" />
              <p className="text-center">Clear Communications</p>
            </div>
            <div className="flex flex-col h-24 justify-between items-center">
              <img className="w-12" src={empathy} alt="" />
              <p className="text-center">Customers & Vendor Empathy</p>
            </div>
            <div className="flex flex-col h-24 justify-between items-center ">
              <img className="h-15" src={teamwork} alt="" />
              <p className="text-center">Teamwork</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-5 lg:px-10 pt-10">
        <h2 className="text-center text-2xl font-medium">
          JekaEat Founder Narrative: How We Got Started
        </h2>
        <div className="mt-8 flex gap-6 flex-col-reverse lg:flex-row items-center lg:*:w-1/2 text-sm lg:text-lg">
          <div>
            <p className=" text-grey-200">
              JekaEat began its journey as Achieversvilla, a logistics and
              delivery service for small and solo businesses in Lagos. In March
              2020, during the peak of the lockdown, it pivoted to an
              agricultural produce delivery startup, specializing in delivering
              rice across Lagos from the North.
            </p>

            <p className="mt-2 text-grey-200">
              By 2021, JekaEat expanded to Minna, scaling food delivery services
              through strategic partnerships and leveraging WhatsApp for
              customer engagement and order coordination.
            </p>
            <p className="mt-2 text-grey-200">
              In 2023, in collaboration with Alley, our Software Engineering
              Lead, we built JekaEat’s first mobile app in just three months.
              This innovation helped us achieve a milestone of 3,000 food orders
              delivered at GK Campus, Federal University of Technology, Minna.
            </p>
          </div>

          <div className="w-full lg:w-60 md:h-72 h-60 grid place-content-center">
            <ImageSlider images={images} />
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
