import { OrderCardSkeleton } from "components/composables/Skeletons/OrderCard";
import { useState } from "react";
import { ItemsEntity, IUserOrder } from "types/global.interface";
import OrderCard from "./OrderCard";

const OngoingOrders = ({ dispatchingOrders, acceptedOrders, processedOrders, isLoading }: {
  dispatchingOrders: IUserOrder; acceptedOrders: IUserOrder; processedOrders?: IUserOrder; isLoading: boolean;
}) => {
  const [tab, setTab] = useState("awaiting");
  return (
    <div>
      {/* Tab Headers */}
      <div className="mt-5 p-2 flex gap-x-5 ">
        <div
          className={`h-full flex items-center justify-center gap-4 basis-0 grow text-center  py-2 border-b-2 ${tab === "awaiting" ? "border-b-[#70AD43]" : "border-b-[#F4F4F4]"
            }`}
          onClick={() => {
            setTab("awaiting");
          }}
        >
          <span>Awaiting Pickup</span>
          <div className="grid place-content-center size-5 text-xs text-white bg-[#FFBB33] rounded-full">
            {acceptedOrders?.items?.length}
          </div>
        </div>
        <div
          className={`h-full flex items-center justify-center gap-4 basis-0 grow text-center  py-2 border-b-2 ${tab === "inTransit" ? "border-b-[#70AD43]" : "border-b-[#F4F4F4]"
            }`}
          onClick={() => {
            setTab("inTransit");
          }}
        >
          <span>In Transit</span>
          <div className="grid place-content-center size-5 text-xs text-white bg-[#70AD43] rounded-full">
            {dispatchingOrders?.items?.length}
          </div>
        </div>
      </div>

      {tab === "awaiting" && (
        <div className="py-4 flex flex-col gap-4">
          {
            isLoading ? <OrderCardSkeleton /> :
              <div className='flex flex-col space-y-3'>
                {(acceptedOrders?.items)?.concat(processedOrders?.items as ItemsEntity[])?.map((v: ItemsEntity, key: number) => (
                  <OrderCard order={v} key={key} />
                ))}
              </div>
          }
        </div>
      )}

      {tab === "inTransit" && (
        <div className="py-4 flex flex-col gap-4">
          {
            isLoading ? <OrderCardSkeleton /> :
              <div className='flex flex-col space-y-3'>
                {dispatchingOrders.items?.map((v: ItemsEntity, key: number) => (
                  <OrderCard order={v} key={key} />
                ))}
              </div>
          }
        </div>
      )}
    </div>
  );
};

export default OngoingOrders;
