import { Link } from "react-router-dom";
import foodie from "../../../assets/Svg/foodie.svg";
import rider from "../../../assets/Svg/rider.svg";
import vendor from "../../../assets/Svg/vendor.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Onboarding5 = () => {
  const navigate = useNavigate();

  const isOnboard = localStorage.getItem("isOnboard");


  useEffect(() => {
    if (isOnboard !== 'true') {
      navigate("/get-started");
    }
  }, [isOnboard, navigate])
  const [role, setRole] = useState<string | null>(null);
  const handleSelectRole = (e: any) => {
    let getRole
    if (e.target.closest(".role").dataset.role === "foodie") {
      setRole("customer");
      getRole = "customer";
    } else if (e.target.closest(".role").dataset.role === "rider") {
      setRole("rider");
      getRole = "rider";
    } else {
      setRole("vendor");
      getRole = "vendor";
    }
    localStorage.setItem("role", getRole)
    localStorage.setItem("newUser", "false")
  };
  return (
    <div className="w-full sm:max-w-[600px] h-screen mx-auto py-8 px-5 flex flex-col">
      <div>
        <h1 className="text-xl font-bold text-grey-900">Register an Account</h1>
        <p className="mt-3 text-grey-200">
          Register an account as a <span className="font-bold">Foodie</span> 🍔
          or as a <span className="font-bold">Rider</span> 🛵 or as a{" "}
          <span className="font-bold">Vendor</span> 🏪 to access all the feature
          on JekaEat.
        </p>
      </div>
      <div className="mt-20 grid grid-cols-2 gap-5">
        <div
          data-role="foodie"
          onClick={(e) => handleSelectRole(e)}
          className={`${role === "customer" ? "bg-secondary-green" : " bg-grey-30"
            } role relative p-5 h-48 w-full rounded-3xl  overflow-hidden `}
        >
          <h1 className="font-bold text-grey-900">Foodie</h1>
          <img
            src={foodie}
            alt="foodie"
            className="absolute right-0 bottom-0"
          />
        </div>

        <div
          data-role="rider"
          onClick={(e) => handleSelectRole(e)}
          className={`${role === "rider" ? "bg-secondary-green" : " bg-grey-30"
            } role relative p-5 h-48 w-full rounded-3xl  overflow-hidden `}
        >
          <h1 className="font-bold text-grey-900">Rider</h1>
          <img src={rider} alt="rider" className="absolute right-0 bottom-0" />
        </div>

        <div
          data-role="vendor"
          onClick={(e) => handleSelectRole(e)}
          className={`col-span-2 ${role === "vendor" ? "bg-secondary-green" : " bg-grey-30"
            } role relative p-5 h-48 w-full rounded-3xl  overflow-hidden`}
        >
          <h1 className="font-bold text-grey-900">Vendor</h1>
          <img
            src={vendor}
            alt="vendor"
            className="absolute right-0 bottom-0"
          />
        </div>
      </div>

      <Link
        to={`/${role}/signup`}
        className={`w-full flex md:mt-10  mt-auto justify-center py-3 px-3 rounded-lg ${!role
          ? "bg-grey-100 text-grey-900 pointer-events-none"
          : "bg-primary-100 text-white"
          } `}
      >
        Continue
      </Link>
    </div>
  );
};

export default Onboarding5;
