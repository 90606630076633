import { useState } from "react";
import Navbar from "../Navbar";
import NewOrders from "./NewOrders";

import { useFetchMyOrders } from "utils/api";

export const Orders = () => {
  const { data: pendingOrders, isLoading } = useFetchMyOrders('pending');
  const { data: ongoingOrders } = useFetchMyOrders('dispatching');
  const { data: completedOrders } = useFetchMyOrders('completed');

  const [tab, setTab] = useState("new");
  return (
    <main className="mx-auto px-5 pb-24">
      <h1 className="font-semibold text-xl">Orders</h1>

      {/* Tab Headers */}
      <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full">
        <div
          className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "new" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
            }`}
          onClick={() => {
            setTab("new");
          }}
        >
          New
        </div>
        <div
          className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "ongoing" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
            }`}
          onClick={() => {
            setTab("ongoing");
          }}
        >
          Ongoing
        </div>
        <div
          className={`h-full basis-0 grow text-center rounded-full py-2 ${tab === "completed" ? "bg-[#70AD43] text-white" : "text-[#6A6A6A]"
            }`}
          onClick={() => {
            setTab("completed");
          }}
        >
          Completed
        </div>
      </div>

      {/*New Orders */}
      {tab === "new" ?
        <NewOrders order={pendingOrders} isLoading={isLoading} />
        : tab === "ongoing" ? <NewOrders order={ongoingOrders} isLoading={isLoading} /> :
          <NewOrders order={completedOrders} isLoading={isLoading} />
      }

      <Navbar />
    </main>
  );
};
