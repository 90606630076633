import {
  createContext,
  SetStateAction,
  useState,
  useContext,
  useMemo,
} from "react";

type SearchContextType = {
  locationValue: string | undefined;
  searchValue: string | undefined;
  setSearchValue: React.Dispatch<SetStateAction<string | undefined>>;
  setLocationValue: React.Dispatch<SetStateAction<string | undefined>>;
};
export const SearchContext = createContext<SearchContextType>(
  {} as SearchContextType
);

export function SearchFilterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [searchValue, setSearchValue] = useState<string>();
  const [locationValue, setLocationValue] = useState<string>();

  // memoized to avoid re-rendering
  const contextValue = useMemo(() => {
    return {
      searchValue,
      setSearchValue,
      setLocationValue,
      locationValue,
    };
  }, [setSearchValue, searchValue, locationValue, setLocationValue]);

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
}

export const useSearchHook = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearchHook must be used within an SearchProvider");
  }
  return context;
};
