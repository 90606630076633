import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
} from "../../../composables";
import { SearchIcon } from "../../../../constants/icon";
import { IoLocationOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useSearchHook } from "../../../../context/searchContext";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../../../Redux/reducer/index';

type FormValue = {
  search: string;
};
export function TopBar({ isSearchPage, toggleLocation }: { toggleLocation: () => void, isSearchPage?: boolean }) {
  const navigate = useNavigate();
  const { searchValue } = useSearchHook();
  const { currentUser } = useSelector((state: RootState) => state.user);

  const form = useForm<FormValue>({
    defaultValues: {
      search: "",
    },
  });

  useEffect(() => {
    if (searchValue) {
      form.reset({
        search: searchValue,
      });
      navigate(`/customer/search?search=${searchValue}`);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  function onSearch(values: FormValue) {
    // console.log(values)
    navigate(`/customer/search?search=${values.search}`);
  }
  return (
    <div className="w-full max-w-[600px] z-20 mx-auto top-0 px-5 bg-white py-4 fixed inset-x-0 flex flex-col gap-y-3 items-center justify-center ">
      {isSearchPage ? (
        <div className="w-full flex items-start justify-start">
          <h2 className="font-semibold capitalize text-lg">Search</h2>
        </div>
      ) : (
        <Button
          onClick={toggleLocation}
          className="h-11 bg-[#F4F4F4] !rounded-xl w-full justify-between">
          <div className="flex items-center pl-3 gap-x-2">
            <IoLocationOutline className="text-[22px] " />
            <span>{currentUser?.address?.streetAddress ?? ""}</span>
          </div>
          <div className="px-4 py-1 border-l border-gray-400">
            <CiEdit className="text-[22px] text-gray-400" />
          </div>
        </Button>
      )}
      <Form {...form}>
        <form className="w-full" onSubmit={form.handleSubmit(onSearch)}>
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative h-11 w-full">
                    <Input
                      placeholder="search"
                      {...field}
                      type="search"
                      className="w-full h-12 pl-10 pr-4 placeholder:text-gray-200 !rounded-xl"
                    />
                    <div className="absolute top-[14px] left-2">
                      <SearchIcon size={20} color="#999999" />
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
}
