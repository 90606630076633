import { motion } from "framer-motion";

type VehicleTypeProp = {
  closeVehicleType: () => void;
  selectVehicleType: (value: string) => void;
};
const VehicleType = ({
  closeVehicleType,
  selectVehicleType,
}: VehicleTypeProp) => {
  const vehicleTypes = ["car", "bus", "motorcycle", "truck", "tricycle", "bicycle"];
  const animate = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: "100%",
    },
  };
  return (
    <motion.div
      variants={animate}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="absolute sm:max-w-[600px] mx-auto z-10 bottom-0 inset-x-0 py-10 px-5 bg-white border rounded-t-[40px] overflow-hidden"
    >
      <div className="absolute inset-x-0 top-3 -translate-x-1/2 left-1/2 w-14 h-1.5 rounded-full bg-grey-30"></div>
      <h2 className="font-bold mb-3">Pick VehicleType</h2>
      {vehicleTypes.map((vehicleType, index) => (
        <label
          htmlFor={String(index)}
          key={index}
          className="my-2 p-3 bg-grey-30 capitalize text-sm rounded-lg flex justify-between items-center"
        >
          <p>{vehicleType}</p>
          <input
          className="capitalize"
            id={String(index)}
            name="vehicleType"
            type="radio"
            value={vehicleType}
            onChange={() => selectVehicleType(vehicleType)}
            onClick={closeVehicleType}
          />
        </label>
      ))}
    </motion.div>
  );
};

export { VehicleType };
