import { useEffect, useState } from "react";
import { cn } from "../../../lib";
import { Button } from "../../composables";
import { Layout } from "../Layout/Layout";
import { OrderCard } from "./_components";
import { useFetchMyOrders } from "utils/api";
import { OrderCardSkeleton } from "components/composables/Skeletons/OrderCard";
import { ItemsEntity } from "types/global.interface";
import { FaSpinner } from 'react-icons/fa6';
import { Download } from 'lucide-react';
import { Bag } from "iconsax-react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { Link } from "react-router-dom";

export function Orders() {
  const [offset, setOffset] = useState(0)
  const { cartItems } = useSelector(
    (state: RootState) => state.cart
  );
  const [allPendingOrders, setAllPendingOrders] = useState<ItemsEntity[]>([]);
  const { data: pendingOrders, isLoading: isPending } = useFetchMyOrders('pending');
  const { data: acceptedOrder, isLoading } = useFetchMyOrders('accepted');
  const { data: dispatchingOrder, isLoading: isDispatchingLoading } = useFetchMyOrders('dispatching');
  const { data: completedOrders, isLoading: isCompletedLoading, isFetching } = useFetchMyOrders('completed', offset);

  const [orderList, setOrderList] = useState<ItemsEntity[]>([])


  const [activeState, setActiveState] = useState(0);

  useEffect(() => {
    if ((acceptedOrder?.items as ItemsEntity[] ?? []).length > 0 && (dispatchingOrder?.items as ItemsEntity[] ?? []).length > 0 && !isLoading && !isDispatchingLoading) {
      setAllPendingOrders((prevItems) => [...prevItems, ...(dispatchingOrder?.items as ItemsEntity[]), ...(acceptedOrder?.items as ItemsEntity[]), ...(pendingOrders?.items as ItemsEntity[])])
    }
    if (completedOrders?.items && completedOrders?.items?.length > 0) {
      setOrderList((prevItems) => [...prevItems, ...(completedOrders?.items as ItemsEntity[])])
    }
    console.log(allPendingOrders);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingOrders, acceptedOrder, dispatchingOrder, completedOrders])
  const orderState = ["On Going", "Completed"];
  return (
    <Layout>
      <div className="w-full flex flex-col gap-y-6 pb-20 items-start justify-start">
        <div className="flex justify-between w-full">
          <h2 className="font-semibold text-base">Orders</h2>
          <Link to='/customer/checkout' className="relative">
            {cartItems.length > 0 &&
              <span className="absolute bg-[#578735] text-white font-normal p-1 size-[12px] rounded-full right-0 top-[0] flex items-center justify-center text-[10px]">
                {cartItems.length}
              </span>
            }
            <Bag />
          </Link>
        </div>

        <div className="w-full bg-[#F4F4F4] grid grid-cols-2 items-center justify-center px-2 rounded-[36px] h-14">
          {orderState.map((state, index) => (
            <Button
              onClick={() => setActiveState(index)}
              className={cn(
                "w-full text-[#6A6A6A] h-11 rounded-[36px]",
                index === activeState && "bg-[#7BBE4A] text-gray-50"
              )}
              key={index}
            >
              {state}
            </Button>
          ))}
        </div>

        {/**onGoing orders */}
        {activeState === 0 && (
          <div className="flex flex-col w-full gap-y-2">
            {!isLoading && !isPending && !isDispatchingLoading
              ? ([...(dispatchingOrder?.items as ItemsEntity[]), ...(acceptedOrder?.items as ItemsEntity[]), ...(pendingOrders?.items as ItemsEntity[])] ?? []).map((order, key) => (
                <OrderCard order={order} key={key} type="on-going" />
              ))
              : [1, 2, 3].map((v) => <OrderCardSkeleton key={v} />)}
          </div>
        )}

        {/**completed orders */}
        {activeState === 1 && (
          <div className="flex flex-col w-full gap-y-2">
            {isCompletedLoading ? [1, 2, 3].map((v) => <OrderCardSkeleton key={v} />) : (orderList ?? []).map((order, key) => (
              <OrderCard order={order} key={key} type="completed" />
            ))}

            <div className="flex justify-center items-center py-4">
              {offset < (completedOrders?.totalCount as number) && <button
                onClick={() => setOffset(prev => prev + 10)}
              >
                {isFetching ? <FaSpinner className="animate-spin text-2xl text-gray-500" /> : <Download className="text-2xl text-gray-500" />}
              </button>}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
