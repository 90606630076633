import { LineIcon } from "../../../../../constants/icon";
import { IoMdTime } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import { cn } from "../../../../../lib";
import moment from "moment";

export function DeliveryTimeLine({ updates, createdAt }: { updates: any[], createdAt: string }) {
  // const pendingInfo = updates?.find(update => update.status === 'pending');
  const acceptedInfo = updates?.find(update => update.status === 'accepted');
  const dispatchingInfo = updates?.find(update => update.status === 'dispatching');
  const completedInfo = updates?.find(update => update.status === 'completed');
  const canceledInfo = updates?.find(update => update.status === 'canceled');


  return (
    <div className="border-b-4 px-4 border-gray-300 gap-y-4 pt-4 pb-10 w-full flex flex-col items-start justify-start">
      <h3 className="font-semibold">Delivery Timeline</h3>

      <div className="px-6  flex flex-col items-start justify-start">
        <OrderStatus
          header="Order placed and Confirmed"
          className="text-[#7BBE4A]"
          showLine
          info={{
            updatedAt: createdAt,
            status: 'pending'
          }}
        />

        {acceptedInfo && <OrderStatus
          header="Order Processed"
          className="text-[#7BBE4A]"
          showLine
          info={acceptedInfo}

        />}

        {dispatchingInfo && <OrderStatus
          header="Order picked up for Delivery"
          className="text-[#7BBE4A]"
          showLine
          info={dispatchingInfo}
        />}
        {dispatchingInfo && <OrderStatus
          header="Order picked up for Delivery"
          className="text-[#7BBE4A]"
          showLine
          info={dispatchingInfo}
        />}

        {completedInfo && <OrderStatus header="Order delivered"
          className="text-[#7BBE4A]"

          info={completedInfo}
        />}
        {canceledInfo && <OrderStatus header="Order Cancelled" className="text-[#7BBE4A]"
        />}
      </div>
    </div>
  );
}

function OrderStatus({
  className,
  header,
  showLine,
  info
}: {
  className?: string;
  header: string;
  showLine?: boolean;
  info?: { status: string; updatedAt: string; }
}) {
  return (
    <div className="flex items-start justify-start gap-x-4">
      <div className="flex justify-start flex-col items-center">
        <FaCircleCheck
          className={cn("text-[#BABABA] text-[15px]", className)}
        />
        {showLine && (
          <div className="w-[14px] flex h-fit items-center justify-center">
            <LineIcon color="#7BBE4A" />
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-start gap-y-1">
        <p>{header}</p>
        <p className=" text-xs flex text-gray-400 items-center">
          <span className="flex items-center gap-x-2 pr-2 border-r border-gray-400">
            <IoMdTime className="text-base " />
            <span>{moment(info?.updatedAt).subtract(1, "hour").format("LT")}</span>
          </span>
          <span className="px-2"> {moment(info?.updatedAt).format("L")}</span>
        </p>
      </div>
    </div>
  );
}
