import { VendorListSkeleton } from "components/composables/Skeletons/vendorlIst";
import { useFetchFoodVendors } from "utils/api";
import { Layout } from "../../Layout/Layout";
import { VendorCard } from "../../_component";

export function Restaurants() {
  const { data, isLoading } = useFetchFoodVendors('restaurant');
  return (
    <Layout hasTopBar>
      <div className="w-full flex flex-col items-start justify-start mt-24 pb-20">
        <h2 className="font-semibold text-xl mb-3">Restaurants</h2>
        <div className="flex flex-col gap-y-4 items-center w-full">
          {isLoading ? (
            <VendorListSkeleton />
          ) : (
            <div className="w-full">
              {data?.items?.map((v, key: number) => (
                <VendorCard className="w-full" key={key} item={v} />
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
