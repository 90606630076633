import { Layout } from "../Layout/Layout";
import res from "../../../assets/images/restaurant.png";

import { PredefinedQueryWidget } from "./_components";
import { useSearchHook } from "../../../context/searchContext";
import { useEffect } from "react";
import { useGeneralSearch } from "utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { MealCard } from '../_component/mealCard/MealCard';
import { MealCardSkeleton } from 'components/composables/Skeletons/MealCard';
import { MealItemsEntity } from "types/global.interface";
import { useDispatch } from "react-redux";
import { VendorListSkeleton } from "components/composables/Skeletons/vendorlIst";
import { VendorCard } from 'components/Customer/_component';
import emptyBox from "../../../assets/Svg/emptyBox.svg";
import axios from "utils/useAxios";

export default function Search() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSearchValue } = useSearchHook();
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const searchQuery = queryParam.get("search");

  const predefinedQuery = [
    { name: "Rice", image: res, color: "bg-[#FFECEC]" },
    { name: "Beans", image: res, color: "bg-[#FFDD9E]" },
    { name: "Drinks", image: res, color: "bg-[#FFECEC]" },
    { name: "Pizza", image: res, color: "bg-[#EBF8FC]" },
    { name: "Jollof Rice", image: res, color: "bg-[#FFDD9E]" },
  ];

  const { data, isLoading } = useGeneralSearch(searchQuery ?? "");


  useEffect(() => {
    console.log(searchQuery);
  }, [searchQuery]);

  async function handleHandPickedSelection(meal: MealItemsEntity) {
    dispatch({
      type: "UPDATE_HANDPICKED_MEAL",
      payload: meal
    })
    const response = await axios.get(`vendors/${meal.vendor._id}`)

    dispatch({
      type: "GET_ACTIVE_VENDOR",
      payload: response.data.data,
    })
    navigate(`/customer/vendor/${meal.vendor._id}?action=openMeal`)
  }
  return (
    <Layout hasTopBar isSearchPage>
      <div className="w-full flex flex-col items-start mt-24  justify-start gap-y-3">
        <div className="w-full overflow-x-auto">
          <div className="min-w-max flex gap-x-4">
            {predefinedQuery.map((v) => (
              <button onClick={() => setSearchValue(v.name)}>
                <PredefinedQueryWidget
                  key={v.name}
                  className={v.color}
                  name={v.name}
                  image={v.image}
                />
              </button>
            ))}
          </div>
        </div>

        <h2 className="text-lg font-semibold">Related Search</h2>

        {(data?.products ?? []).length > 0 && <div className="flex flex-col items-start justify-start w-full gap-y-3 my-4">
          <h2 className=" text-base">Meal({data?.products.length} search found )</h2>
          {!isLoading && <div className="grid grid-cols-2 items-center gap-2 w-full ">
            {data?.products.map((meal: MealItemsEntity, key: number) => (
              <MealCard meal={meal} key={key} onClick={() => handleHandPickedSelection(meal)} />
            ))}
          </div>
          }
        </div>}
        {(data?.restaurants ?? []).length > 0 && <div className="flex flex-col items-start justify-start w-full gap-y-3 my-4">
          <h2 className=" text-base">Restaurant({data?.restaurants.length} search found )</h2>
          {!isLoading ? <div className="w-full ">
            {data?.restaurants.map((v, key: number) => (
              <VendorCard className="w-full" key={key} item={v} />
            ))}
          </div> :

            <div className="grid grid-cols-2 items-center gap-2 w-full ">
              {[1, 2, 3, 4].map((_, key) => (
                <VendorListSkeleton key={key} />
              ))}
            </div>
          }
        </div>}

        {isLoading && <div className="grid grid-cols-2 items-center gap-2 w-full ">
          {[1, 2,].map((_, key) => (
            <MealCardSkeleton key={key} />
          ))}
        </div>}
        {isLoading && <div className=" my-4  w-full ">
          {[1, 2,].map((_, key) => (
            <VendorListSkeleton key={key} />
          ))}
        </div>}

        {!isLoading && data?.products.length === 0 && data?.restaurants.length === 0 &&
          <div className="py-20 flex flex-col w-full justify-center items-center">
            <img src={emptyBox} alt="" />
            <h2 className="mt-5 font-semibold text-lg">Search Result</h2>
            <p className="mt-2">We cant find any match for your search in our repository</p>
          </div>
        }
      </div>
    </Layout>
  );
}
