// import * as type from "../Actions/Types";

import { Reducer } from "redux";
import { MealItemsEntity } from "types/global.interface";

export interface ICartItems {
  order_id?: string;
  name?: string;
  items: MealsEntity[];
  products?: any;
  totalPrice?: number;
}
export interface MealsEntity {
  _id?: string;
  vendorId?: string;
  name: string;
  image: Image;
  product: string;
  quantity: number;
  unitPrice: number;
  mealPrice: number;
}
export interface Image {
  url: string;
}
export interface CartState {
  cartItems: ICartItems[];
  handPicked: MealItemsEntity | null;
  overallPrice: number;
  currentOrderIndex: string | null;
}
export interface CartAction {
  type: string;
  payload?: any;
}

const initialState: CartState = {
  // cartItems: [],
  cartItems: [
    // {
    //   order_id: "North",
    //   name: "Usman",
    //   meals: [
    //     {
    //       name: "Normal TA White Rice + Dry beans (1 portion)",
    //       image: {
    //         url: "https://res.cloudinary.com/ds1ryba2h/image/upload/v1690804414/jekaeat/meals/hkkmmlfzen6dyaninojc.jpg",
    //       },
    //       meal: "649dfe60416e1f791f6f3031",
    //       quantity: 1,
    //       unitPrice: 50,
    //       mealPrice: 50,
    //     },
    //     {
    //       name: "Normal TA White Rice + Dry beans (1 portion)",
    //       image: {
    //         url: "https://res.cloudinary.com/ds1ryba2h/image/upload/v1690804414/jekaeat/meals/hkkmmlfzen6dyaninojc.jpg",
    //       },
    //       meal: "649dfe60416e1f791f6f3031",
    //       quantity: 2,
    //       unitPrice: 100,
    //       mealPrice: 200,
    //     },
    //   ],
    //   totalPrice: 250,
    // },
    // {
    //   order_id: "South",
    //   name: "Ope",
    //   meals: [
    //     {
    //       name: "Normal TA White Rice + Dry beans (1 portion)",
    //       image: {
    //         url: "https://res.cloudinary.com/ds1ryba2h/image/upload/v1690804414/jekaeat/meals/hkkmmlfzen6dyaninojc.jpg",
    //       },
    //       meal: "649dfe60416e1f791f6f3031",
    //       quantity: 1,
    //       unitPrice: 100,
    //       mealPrice: 100,
    //     },
    //   ],
    //   totalPrice: 100,
    // },
  ],
  overallPrice: 0,
  handPicked: null,
  currentOrderIndex: null,
};
const Cart: Reducer<CartState, CartAction> = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_FOODITEMS_TO_CART":
      return {
        ...state,
        cartItems: action.payload,
      };

    case "UPDATE_TOTAL_PRICE":
      return {
        ...state,
        overallPrice: action.payload,
      };
    case "UPDATE_CURRENT_ORDER_INDEX":
      return {
        ...state,
        currentOrderIndex: action.payload,
      };
    case "UPDATE_HANDPICKED_MEAL":
      return {
        ...state,
        handPicked: action.payload,
      };

    default:
      return state;
  }
};

export default Cart;
