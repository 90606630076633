export const MealCardSkeleton = () => (
  <div className="flex rounded-xl w-full p-2 border h-fit flex-col gap-y-1 animate-pulse">
    <div className="w-full rounded-xl h-[110px] relative overflow-hidden"></div>
    <div className="bg-white absolute top-3 right-3 rounded-3xl px-2 py-1 flex items-center justify-center gap-x-1">
      <div className="text-lg text-yellow-500 rounded"></div>
    </div>
    <div className="h-7 absolute bottom-3 right-3 w-7 rounded-full bg-white"></div>
    <div className="py-1 gap-y-1 px-2 flex flex-col items-start justify-start">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="font-semibold text-[15px] h-4 bg-gray-200 rounded w-1/3"></div>
    </div>
  </div>
);
