import restaurant from "../../../assets/images/restaurant.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Button } from "../../composables";
import { useNavigate, useParams } from "react-router-dom";
import { AboutVendor, FoodStack, MealPack, PreOrder } from "./_components";
import { useEffect, useState } from "react";
import { cn } from "../../../lib";
import { OrderIcon } from "../../../constants/icon";
import Share from "../../../assets/Svg/share.svg";
import {
  useFetchSingleVendor,
  useFetchVendorMeals,
  useGetOrderingStatus,
} from "utils/api";
import ShareButtons from "components/composables/Share";
import { IVendor, MealItemsEntity } from "types/global.interface";
import { MEAL_TYPE } from "utils/constant.util";
import { MenuListSkeleton } from "components/composables/Skeletons/menuList";
import { AboutVendorSkeleton } from "components/composables/Skeletons/AboutVendorSkeleton";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { getNairaEquivalent, isShopAvailable, isWebview } from "utils/helper";
import { toast } from "react-toastify";
import { Error } from "assets/icon";
export function Vendor() {
  const { data: orderingStatus } = useGetOrderingStatus();

  const navigate = useNavigate();
  const checkIsWebview = isWebview();

  const { id } = useParams();
  const { data, isLoading } = useFetchSingleVendor(id ?? "");

  const { cartItems, overallPrice } = useSelector(
    (state: RootState) => state.cart
  );
  const { activeVendor } = useSelector((state: RootState) => state.user);
  console.log(activeVendor);

  const { data: Meals, isLoading: isMealLoading } = useFetchVendorMeals(
    id ?? ""
  );
  const [foodStack, setFoodStack] = useState<MealItemsEntity[] | undefined>([]);
  const [mealPack, setMealPack] = useState<MealItemsEntity[] | undefined>([]);
  const [preOrder, setPreOrder] = useState<MealItemsEntity[] | undefined>([]);
  const [mealkit, setMealKit] = useState<MealItemsEntity[] | undefined>([]);
  const [harvest_pack, setHarvest_pack] = useState<
    MealItemsEntity[] | undefined
  >([]);
  useEffect(() => {
    if (orderingStatus !== undefined && !orderingStatus) {
      toast.error("Delivery Closed, Please try again later", {
        icon: Error,
      });
    }
    if (Meals) {
      setFoodStack(Meals?.filter((v) => v.category === MEAL_TYPE.FOOD_STACK));
      setMealPack(Meals?.filter((v) => v.category === MEAL_TYPE.MEAL_PACK));
      setPreOrder(Meals?.filter((v) => v.category === MEAL_TYPE.PRE_ORDER));
      setMealKit(Meals?.filter((v) => v.category === MEAL_TYPE.MEAL_KIT));
      setHarvest_pack(
        Meals?.filter((v) => v.category === MEAL_TYPE.HARVEST_PACK)
      );
    }
  }, [Meals, orderingStatus]);
  const foodType = ["Meal Pack", "Food Stack", "Pre-Order"];
  const freshfoodType = ["Meal Kit", " Harvest Park"];

  const [activeState, setActiveState] = useState(
    activeVendor?.category === "restaurant" ? foodType[0] : freshfoodType[0]
  );
  const [isShareOpen, setIsShareOpen] = useState(false);

  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    return baseUrl;
  };

  const handleShare = () => {
    navigate(
      `/customer/vendor/${data?._id
      }?app_action_share=Check out this amazing Jekaeat partner restaurant: ${getBaseUrl()}/guest/vendor/${data?._id
      }`
    );
    // (window as any).flutter_inappwebview?.callHandler('share', `Check out this amazing Jekaeat partner restaurant: ${getBaseUrl}/guest/vendor/${data?._id}`)
  };

  return (
    <div className="w-full fixed  inset-0 h-full max-w-[600px] mx-auto">
      <div className="w-full fixed h-[22%] inset-x-0 top-0 max-w-[600px] mx-auto">
        <img
          src={data?.profileImage?.url ?? restaurant}
          alt="restaurant"
          className="w-full h-full object-cover"
        />
        <div className="w-full top-6 inset-x-0 absolute flex items-center justify-between px-5">
          <Button
            onClick={() => navigate(-1)}
            className="bg-white px-0 h-10 w-10 rounded-full p-1"
          >
            <IoIosArrowRoundBack className="text-[22px]" />
          </Button>
          <Button
            onClick={() => {
              if (checkIsWebview) {
                handleShare();
              } else {
                setIsShareOpen(!isShareOpen);
              }
            }}
            className="bg-white px-0 h-10 w-10 rounded-full p-1"
          >
            <img src={Share} alt="Share" className="text-[22px]" />
          </Button>
          {isShareOpen && (
            <div className="z-10 absolute right-5 top-12">
              <ShareButtons
                url={`${getBaseUrl()}/guest/vendor/${data?._id}`}
                title={`Check out this amazing Jekaeat partner restaurant: ${getBaseUrl()}/guest/vendor/${data?._id
                  } `}
              />{" "}
            </div>
          )}
          {isShareOpen && (
            <div
              className="fixed inset-0 z-[9] bg-transparent"
              onClick={() => {
                setIsShareOpen(!isShareOpen);
              }}
            />
          )}
        </div>
      </div>
      <div className="w-full flex flex-col items-center max-w-[600px] mx-auto z-50 h-[80%] fixed inset-x-0 bottom-0 bg-white rounded-t-[2rem] py-5 px-5">
        {!isLoading ? (
          <AboutVendor vendor={data as IVendor} />
        ) : (
          <AboutVendorSkeleton />
        )}

        <div className="w-full bg-[#F4F4F4] mt-4 flex items-center justify-center p-1 rounded-[36px] h-12">
          {(activeVendor?.category === "restaurant"
            ? foodType
            : freshfoodType
          ).map((state, index) => (
            <Button
              onClick={() => setActiveState(state)}
              className={cn(
                "w-full text-[#6A6A6A] h-8 rounded-[36px]",
                state === activeState && "bg-[#7BBE4A] text-gray-50"
              )}
              key={state}
            >
              {state}
            </Button>
          ))}
        </div>
        {!isMealLoading ? (
          <div className="w-full mt-4 h-full overflow-y-auto pb-14 no-scrollbar">
            {activeState === "Meal Pack" && <MealPack meals={mealPack} />}
            {activeState === "Food Stack" && <FoodStack meals={foodStack} />}
            {activeState === "Pre-Order" && <PreOrder meals={preOrder} />}
            {activeState === "Meal Kit" && <MealPack meals={mealkit} />}
            {activeState === "Harvest Park" && (
              <MealPack meals={harvest_pack} />
            )}
          </div>
        ) : (
          [1, 2, 3].map((v) => <MenuListSkeleton key={v} />)
        )}

        {cartItems.length > 0 && (
          <div className="absolute bottom-3 z-[100] inset-x-0 w-full px-6">
            <Button
              onClick={() => {
                if (!orderingStatus) {
                  toast.error("Delivery Closed, Please try again later", {
                    icon: Error,
                  });
                  return;
                }
                if (
                  !isShopAvailable(activeVendor?.schedule as any[]) ||
                  activeVendor?.shopClosed
                ) {
                  toast.error("Vendor Closed, Please try again later", {
                    icon: Error,
                  });
                  return;
                }

                navigate("/customer/checkout");
              }}
              className="grid grid-cols-6 bg-[#7BBE4A] justify-start text-white font-semibold w-full items-center h-11 rounded-3xl py-2"
            >
              <span className="col-span-4  w-full text-center">CheckOut</span>
              <span className="col-span-2 gap-x-2 flex items-center px-2 border-l">
                <span className="relative w-fit h-fit">
                  <span className="absolute bg-[#34501F] font-normal p-1 h-3 w-3 rounded-full right-[-2px] top-[-3px] flex items-center justify-center text-[10px]">
                    {cartItems.length}
                  </span>
                  <OrderIcon color="#ffffff" />
                </span>
                <span>₦{getNairaEquivalent(overallPrice)}</span>
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
