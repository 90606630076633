import playstore from "../../assets/Svg/playstore.svg";
import apple from "../../assets/Svg/apple.svg";
import groceries from "../../assets/Svg/groceries-illustration.svg";
import food from "../../assets/Svg/foods-illustration.svg";
import fruitSalad from "../../assets/images/fruit-salad.jpg";
import pasta from "../../assets/images/pasta.jpg";
import watermelon from "../../assets/images/watermelon.png";
import berries from "../../assets/images/berries.jpg";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import AnimatedText from "./components/AnimatedText";

export const Vendors = () => {

  const navigate = useNavigate()
  return (
    <div>
      {" "}
      <section className="md:min-h-[calc(100dvh-58px)] container mx-auto px-5 lg:px-10 flex items-center lg:flex-row flex-col-reverse lg:*:w-1/2 gap-8 lg:h-[500px] lg:min-h-0 py-10">
        <div>
          <h1 className="font-medium text-2xl lg:text-5xl">
            Grow Your Reach, Supply Fresh Farm Produce to <br />{" "}
            <AnimatedText
              textList={["Restaurants", "Hotels", "Stores"]}
              className="text-primary-100"
            />
          </h1>
          <p className="mt-6 text-lg text-grey-200">
            Connect directly with the top hospitality industry on JekaEat.
            Deliver fresh, high-quality produce straight to their kitchens and
            grow your business faster. Start supplying and partnering today!
          </p>
          <div>
            <div className="flex mt-4 gap-4 flex-col lg:flex-row max-w-44 lg:max-w-full">
              <a
                href="https://play.google.com/store/apps/details?id=com.jekaeat.jekaeat"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2"
              >
                <img className="w-8" src={playstore} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">GET IT ON</p>
                  <p className="font-medium text-lg">Google Play</p>
                </div>
              </a>

              <a
                href="https://apps.apple.com/ng/app/jekaeat/id6452400533"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2">
                <img className="w-8" src={apple} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">Download on the</p>
                  <p className="font-medium text-lg">App Store</p>
                </div>
              </a>

            </div>
            <button onClick={() => navigate('/auth/signin')} className="mt-4 text-primary-100 px-4 py-2 rounded-full border border-primary-100">
              Accept Orders With Web App            </button>
          </div>
        </div>
        <div>
          <img className="lg:size-[500px]" src={groceries} alt="" />
        </div>
      </section>
      <section className="mt-20 container mx-auto">
        <h2 className="text-center font-medium text-4xl">
          Check out Farm Suppliers on JekaEat
        </h2>

        <div className="my-20 flex lg:flex-row flex-col items-center gap-10 flex-nowrap px-5 *:shrink-0 overflow-x-scroll">
          {[watermelon, berries, watermelon].map((item, index) => (
            <div
              key={index}
              className="rounded-2xl w-full lg:w-[500px] h-64 lg:h-80 overflow-hidden relative"
              style={{
                backgroundImage: `linear-gradient(250.34deg, rgba(255, 255, 255, 0) 4.41%, rgba(33, 33, 33, 0.36) 43.18%, rgba(33, 33, 33, 0.6) 73.22%),url(${item})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex justify-end">
                <div className="flex items-center p-2 bg-primary-500 rounded-bl-lg">
                  <StarFilledIcon color="#FFBB33" />
                  <p className="text-white ml-2">4.5</p>
                </div>
              </div>
              <div className="absolute bottom-4 inset-x-4 flex items-center justify-between text-white">
                <div>
                  <p className="text-sm text-primary-100">Minna</p>
                  <p className="text-lg font-medium">Henry Services</p>
                  <div className="text-sm">$$$</div>
                </div>
                <button className="bg-primary-100 text-white text-sm px-8 hover:bg-primary-500 py-2 rounded-full">
                  Order Food Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="md:min-h-[calc(100dvh-58px)] container mx-auto px-5 lg:px-10 flex items-center lg:*:w-1/2 gap-8 lg:h-[500px] lg:min-h-0 py-20 flex-col lg:flex-row">
        <div>
          <img className="lg:size-[500px]" src={food} alt="food illustration" />
        </div>
        <div>
          <h1 className="font-medium text-4xl">
            Boost Your Sales, Delivering <br />{" "}
            <AnimatedText
              textList={["Food", "Groceries"]}
              className="text-primary-100"
            />{" "}
            <br />
            To More Customers
          </h1>
          <p className="mt-6 text-lg text-grey-200">
            List your restaurant on JekaEat and reach more customers every day,
            delivering joy to their doorstep with ease. Start selling today—your
            customers are waiting!
          </p>
          <div>
            <div className="flex mt-4 gap-4 flex-col lg:flex-row max-w-44 lg:max-w-full">
              <a
                href="https://play.google.com/store/apps/details?id=com.jekaeat.jekaeat"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2"
              >
                <img className="w-8" src={playstore} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">GET IT ON</p>
                  <p className="font-medium text-lg">Google Play</p>
                </div>
              </a>

              <a
                href="https://apps.apple.com/ng/app/jekaeat/id6452400533"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2">
                <img className="w-8" src={apple} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">Download on the</p>
                  <p className="font-medium text-lg">App Store</p>
                </div>
              </a>

            </div>
            <button onClick={() => navigate('/auth/signin')} className="mt-4 text-primary-100 px-4 py-2 rounded-full border border-primary-100">
              Accept Orders With Web App
            </button>
          </div>
        </div>
      </section>
      <section className="mt-20 container mx-auto">
        <h2 className="text-center font-medium text-4xl">
          Check out Vendors on JekaEat
        </h2>

        <div className="my-20 flex lg:flex-row flex-col items-center gap-10 flex-nowrap px-5 *:shrink-0 overflow-x-scroll">
          {[pasta, fruitSalad, pasta].map((item, index) => (
            <div
              key={index}
              className="rounded-2xl  w-full lg:w-[500px] h-64 lg:h-80 overflow-hidden relative"
              style={{
                backgroundImage: `linear-gradient(250.34deg, rgba(255, 255, 255, 0) 4.41%, rgba(33, 33, 33, 0.36) 43.18%, rgba(33, 33, 33, 0.6) 73.22%),url(${item})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex justify-end">
                <div className="flex items-center p-2 bg-primary-500 rounded-bl-lg">
                  <StarFilledIcon color="#FFBB33" />
                  <p className="text-white ml-2">4.5</p>
                </div>
              </div>
              <div className="absolute bottom-4 inset-x-4 flex items-center justify-between text-white">
                <div>
                  <p className="text-sm text-primary-100">Minna</p>
                  <p className="text-lg font-medium">Henry Services</p>
                  <div className="text-sm">$$$</div>
                </div>
                <button className="bg-primary-100 text-white text-sm px-8 hover:bg-primary-500 py-2 rounded-full">
                  Order Food Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
