import { Button } from "components/composables";
import { ArrowLeft } from "iconsax-react";
import { cn } from "lib";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useFetchMyEarning, useFetchMyReferees } from "utils/api";
import { getNairaEquivalent } from "utils/helper";
import emptyBox from 'assets/Svg/emptyBox.svg';
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { FaCopy } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Success } from "assets/icon";
import copy from 'copy-to-clipboard';

const Referral = () => {
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState(0);
  const handleCopy = () => {
    copy(currentUser?.referralCode ?? "")
    toast.success("Code Copied", { icon: Success })
  }
  const { data, isLoading } = useFetchMyReferees()
  const { data: myEarning, isLoading: fetchingMyEarning } = useFetchMyEarning()
  const { currentUser } = useSelector((state: RootState) => state.user);

  const togglesStates = ["Referees", "Earning"];

  const EmptyRef = () => (<div className="w-full py-40 flex text-center flex-col justify-center items-center text-[#999999]">
    <div className="mb-3"> <img src={emptyBox} alt="emptyBox" /> </div>
    <div className="mb-3">You have no referee</div>
    <div>Share your referral code with friends and family and start earning today</div>
    <div className="mt-1 text-base text-black flex items-center space-x-2">
      <div>Referral Code</div>: <div>{currentUser?.referralCode ?? "N/A"}</div>  <button onClick={handleCopy}><FaCopy className="text-primary-100" /></button>
    </div>
  </div>)

  return (
    <main className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">My Referees</h1>
      </div>

      <div className="w-full mt-5 bg-[#F4F4F4] grid grid-cols-2 items-center justify-center px-2 rounded-[36px] h-14">
        {togglesStates.map((state, index) => (
          <Button
            onClick={() => setActiveState(index)}
            className={cn(
              "w-full text-[#6A6A6A] h-11 rounded-[36px]",
              index === activeState && "bg-[#7BBE4A] text-gray-50"
            )}
            key={index}
          >
            {state}
          </Button>
        ))}
      </div>


      {activeState === 0 && (
        <div>
          {!isLoading ? <div className="mt-3 border rounded-lg">
            {data?.map((v, i) =>
              <div key={i} className="border-b py-2 px-1 flex items-center space-x-2">
                <div>{i + 1}.</div>
                <div>{v.fullName}</div>
              </div>
            )}
            {data?.length === 0 &&
              <EmptyRef />
            }
          </div> :
            <div className="w-full py-40 grid place-content-center">
              <FaSpinner className="animate-spin" size={24} />
            </div>
          }
        </div>
      )}
      {activeState === 1 && (
        <div>
          {!fetchingMyEarning ? <div className="mt-3 border rounded-lg ">
            <div className="border-b font-semibold py-2 px-1 grid grid-cols-3 items-center text-bg-gray-500 ">
              <div>Name</div>
              <div>Amount Earned</div>
              <div>No of Order</div>
            </div>

            {myEarning?.map((v, i) =>
              <div key={i} className="border-b py-2 px-1 grid grid-cols-3 items-center">
                <div className="truncate">{v?.referee?.name ?? "N/A"}</div>
                <div>₦{getNairaEquivalent(v?.totalAmountEarned).toLocaleString() ?? 0}</div>
                <div>{v?.completedOrderCount ?? 0}</div>
              </div>
            )}
            {myEarning?.length === 0 && <EmptyRef />
            }
          </div> :
            <div className="w-full py-40 grid place-content-center">
              <FaSpinner className="animate-spin" size={24} />
            </div>
          }
        </div>
      )}
    </main>
  );
};

export default Referral;

