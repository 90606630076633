import React from 'react'

export const RouteOptimizationSkeleton = () => {
    return (
        <div className="relative mt-4">
            <div className="animate-pulse bg-gray-200 h-12 rounded-md mt-4 px-4 py-3"></div>
            <div className="size-6 bg-primary-100/50 p-3 grid place-content-center rounded-full absolute top-[54px] right-6"></div>
            <div className="animate-pulse bg-gray-200 h-12 rounded-md mt-3 px-4 py-3"></div>
            <div className="animate-pulse bg-gray-200 h-12 rounded-md mt-4 px-4 py-3"></div>
        </div>

    )
}
