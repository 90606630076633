import { Bag2, Home, TruckFast, ProfileCircle } from "iconsax-react";

import { Link, useLocation } from "react-router-dom";

type ILink = {
  path: string;
  name: string;
  icon: React.ReactNode;
};

const links: ILink[] = [
  {
    path: "/rider/home",
    name: "Home",
    icon: <Home size={30} />,
  },
  {
    path: "/rider/orders",
    name: "Order",
    icon: <Bag2 size={30} />,
  },
  {
    path: "/rider/deliveries",
    name: "Deliveries",
    icon: <TruckFast size={30} />,
  },
  {
    path: "/rider/profile",
    name: "Profile",
    icon: <ProfileCircle size={30} />,
  },
];

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <nav className="pb-3 pt-5 px-5 fixed bottom-0 inset-x-0 z-50 bg-white border-t border-t-[#e9e9e9] flex justify-between items-center">
      {links.map((link) => (
        <Link key={link.name} to={link.path}>
          <div
            className={`flex flex-col items-center gap-y-2 ${pathname.includes(link.path) ? "text-[#70AD43]" : "text-grey-400"
              }`}
          >
            {link.icon}
            <span>{link.name}</span>
          </div>
        </Link>
      ))}

    </nav>
  );
};

export default Navbar;
