import { useState } from "react";
import Checkbox from "./checkbox";
import { MealItemsEntity } from "types/global.interface";
import { useToggleVendorMeal } from "utils/api";

const MenuCard = ({ meal }: { meal: MealItemsEntity }) => {
  const { mutateAsync } = useToggleVendorMeal()

  const [inStock, setInStock] = useState(meal.inStock);


  return (
    <div className="px-3 py-4 flex justify-between items-stretch border border-[#E9E9E9] rounded-2xl">
      <div className="flex gap-3">
        <div className="w-16 h-16 rounded-md overflow-hidden">
          <img className="size-full object-cover" src={meal.image.url} alt={meal.name} />
        </div>

        <div className="max-w-[170px]">
          <h4 className="text-xs text-[#212121]">
            {meal.name ?? "N/A"}
          </h4>
          <h5 className="font-semibold text-base">₦ {meal.unitPrice}</h5>
        </div>
      </div>

      <div className="flex flex-col justify-between items-end text-xs">
        {/* checkbox */}
        <div className="relative">
          <Checkbox
            checked={inStock}
            onChange={() => {
              setInStock((prev) => !prev)
              mutateAsync({ vendorId: meal.vendor._id, mealId: meal._id })
            }
            }
          />
        </div>

        <div
          className={`py-2 px-3 rounded-md ${inStock
            ? "bg-[#E6FAEE] text-[#33D374]"
            : "bg-transparent text-[#BABABA] border border-[#BABABA]"
            } `}
        >
          {inStock ? "In stock" : "Out of Stock"}
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
