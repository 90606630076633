import { motion } from "framer-motion";

type MealTagProp = {
  closeTag: () => void;
  selectTag: (value: string) => void;
};
const MealTag = ({ closeTag, selectTag }: MealTagProp) => {
  const categories = ["Proteins", "Drinks", "Desserts"];
  const animate = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: "100%",
    },
  };
  return (
    <motion.div
      variants={animate}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed sm:max-w-[600px] mx-auto z-10 bottom-0 inset-x-0 py-10 px-5 bg-white border rounded-t-[40px] overflow-hidden"
    >
      <div className="fixed inset-x-0 top-3 -translate-x-1/2 left-1/2 w-14 h-1.5 rounded-full bg-grey-30"></div>
      <h2 className="font-bold mb-3">Meal Tag</h2>
      {categories.map((tag, index) => (
        <label
          htmlFor={String(index)}
          key={tag}
          className="my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center"
        >
          <p>{tag}</p>
          <input
            id={String(index)}
            name="tag"
            type="radio"
            value={tag}
            onChange={() => selectTag(tag)}
            onClick={closeTag}
          />
        </label>
      ))}
    </motion.div>
  );
};

export { MealTag };
