export const OrderCardSkeleton = () => (
  <div className="w-full border flex flex-col gap-y-2 items-start justify-start rounded-3xl px-4 py-3 animate-pulse">
    <div className="flex items-center justify-between w-full">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
    </div>
    <div className="flex items-center justify-between text-gray-400 w-full">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
    </div>
  </div>
);
