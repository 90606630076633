// import food from "../../../../../assets/images/restaurant.png";
import jekaeatlogo from "../../../../../assets/images/logo.png";
import { Button } from "../../../../composables";
import { AiOutlinePlus } from "react-icons/ai";
import { cn } from "../../../../../lib";
// import { FoodItemCounter } from "../../../_component";
import { useEffect, useState } from "react";
import { MealDetail } from "../mealDetail/MealDetail";
import { MealItemsEntity } from "types/global.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { getNairaEquivalent } from "utils/helper";
// import { MealsEntity } from "Redux/reducer/cart";
import { toast } from "react-toastify";
import { addFoodItemsToCart } from "Redux/actions/cart";
import { Error } from "assets/icon";

export function VendorMealCard({
  meal,
  isFoodStack,
  isPreOrder,
  isMealPack,
}: {
  meal: MealItemsEntity;
  isFoodStack?: boolean;
  isPreOrder?: boolean;
  isMealPack?: boolean;
}) {
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const actionWord = queryParam.get("action");
  const [isOpen, setOpen] = useState(false);
  const [, setImageSrc] = useState(meal.image?.url);
  const [currentMeal, setCurrentMeal] = useState<MealItemsEntity>(meal);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handPicked, cartItems } = useSelector(
    (state: RootState) => state.cart
  );
  function onClose() {
    if (actionWord === "openMeal") {
      setOpen(false);
      navigate(-1);
      navigate(`/customer/vendor/${meal.vendor._id}`);
    } else {
      setOpen(false);
    }
  }

  function verifyCurrentVendor(vendorId: string) {
    if (cartItems.length > 0) {
      if (
        cartItems[0]?.items?.length > 0 &&
        cartItems[0]?.items[0]?.vendorId === vendorId
      ) {
        //check the owner of the item in cart
        return true;
      } else return false;
    } else return true;
  }
  const handleOrderItem = (item: MealItemsEntity) => {
    console.log(item);
    // item = { ...item, vendor: { _id: item.vendorId as string } }

    if (!verifyCurrentVendor(item.vendorId || item.vendor._id)) {
      toast.error(
        "You can't order from 2 different vendor, if you wish to continue do empty your cart",
        { icon: Error }
      );
      return;
    }
    console.log(item);
    const payload = {
      name: item.name,
      image: item.image,
      product: item._id,
      quantity: 1,
      unitPrice: item.unitPrice,
      vendorId: item.vendorId || item.vendor._id,
    };
    dispatch(addFoodItemsToCart(payload));
  };

  useEffect(() => {
    if (actionWord === "openMeal") {
      setOpen(true);
      setCurrentMeal(handPicked as MealItemsEntity);
    }
  }, [actionWord, handPicked]);

  return (
    <>
      <div className="border px-3 py-2 rounded-[22px] w-full flex items-start gap-x-2 justify-start">
        <div
          onClick={() => {
            setOpen(true);
          }}
          className="w-[90px] h-[75px] rounded-[22px]  overflow-hidden"
        >
          <img
            src={currentMeal?.image?.url ?? jekaeatlogo}
            onError={() => setImageSrc(jekaeatlogo)}
            alt="food"
            className="w-full h-full object-cover"
          />
        </div>
        <div
          className={cn(
            "w-full flex items-end justify-between",
            isPreOrder && "flex-col items-start gap-y-2"
          )}
        >
          <div
            className={cn(
              "flex flex-col w-full items-start  text-start  gap-y-2",
              isPreOrder && "flex-row justify-between gap-y-0"
            )}
          >
            <p
              onClick={() => {
                setOpen(true);
              }}
              className="flex flex-wrap w-4/5 text-xs items-start justify-start"
            >
              {currentMeal.name}
            </p>

            <p className="font-semibold text-base">
              ₦{getNairaEquivalent(currentMeal.unitPrice)}
            </p>
          </div>

          {isPreOrder && (
            <p className="text-gray-500 text-xs">
              {meal.preOrderSchedule?.deliveryExpected}
            </p>
          )}

          {/* {isFoodStack && <FoodItemCounter item={meal as any as MealsEntity} />} */}
          {/* {isMealPack && (
            <Button
              disabled={!currentMeal.inStock}
              onClick={() => handleOrderItem(currentMeal as any)}
              className={cn(" text-white h-9 w-10 rounded-lg px-0", currentMeal.inStock ? 'bg-[#7BBE4A]' : 'bg-gray-300')}>
              <AiOutlinePlus className="text-[22px]" />
            </Button>
          )} */}

          <Button
            disabled={!currentMeal.inStock}
            onClick={() => handleOrderItem(currentMeal as any)}
            className={cn(
              " text-white h-9 w-10 rounded-lg px-0",
              currentMeal.inStock ? "bg-[#7BBE4A]" : "bg-gray-300"
            )}
          >
            <AiOutlinePlus className="text-[22px]" />
          </Button>
        </div>
      </div>

      {isOpen && <MealDetail meal={currentMeal} close={onClose} />}
    </>
  );
}
