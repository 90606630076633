import { Link } from "react-router-dom";
import pasta from "../../assets/images/pasta.jpg";
import { ArrowRight } from "iconsax-react";
const NewsLetter = () => {
  return (
    <div>
      <div className="bg-primary-50 py-10 lg:py-20 px-5 lg:px-10">
        <div className="container mx-auto">
          <h1 className="font-medium text-2xl lg:text-5xl text-center">
            Updates, Exclusive Offers & Discounts, Helpful Tips and Lots More!{" "}
          </h1>

          <div className="mt-4 lg:mt-8 flex lg:items-center justify-between flex-col lg:flex-row">
            <div>
              <h1 className="text-lg font-medium">Join Our Community</h1>
              <p className="mt-2 text-grey-200">
                Stay Updated About JekaEat Via Our Newsletter
              </p>
            </div>
            <div className="mt-4 lg:mt-0 flex items-center bg-grey-30 rounded-2xl min-w-80 pr-3 overflow-hidden border border-transparent focus-within:border-primary-100">
              <input
                className="py-3 pl-6 bg-transparent outline-none border-none grow"
                placeholder="Enter your email"
                type="text"
              />
              <button className=" bg-primary-100 text-white h-11 px-4 lg:px-10 rounded-full my-2">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="mt-4 mb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 container px-10 mx-auto">
          {Array.from({ length: 9 }).map((_, index) => (
            <div key={index}>
              <div className="rounded-lg w-full h-56 overflow-hidden">
                <img
                  src={pasta}
                  alt="pasta"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="mt-4 text-xs text-primary-100">01 Jan 2024</div>
              <h2 className="mt-2 text-2xl font-medium text-grey-600">
                What’s New at JekaEat This Month
              </h2>
              <p className="mt-2 text-grey-200 leading-7">
                Hello Foodies! 👋 Exciting updates are rolling out at JekaEat
                this month! Here’s what you can look forward ...
              </p>

              <Link
                to="/"
                className="mt-4 text-primary-100 hover:text-primary-50000 flex items-center gap-2"
              >
                Read More <ArrowRight size={20} />
              </Link>
            </div>
          ))}
        </div>

        <div className="text-center">
          <button className="border-primary-100 border text-primary-100 hover:text-white hover:bg-primary-100 px-6 py-2 rounded-full">
            Load More
          </button>
        </div>
      </section>
    </div>
  );
};

export default NewsLetter;
