import { Button, FormField } from "../../composables";
import { useForm } from "react-hook-form";
import { ArrowLeft, Wallet } from "iconsax-react";
import visa from "../../../assets/images/Visa.png";
import masterCard from "../../../assets/images/Mastercard.png";
import { useUpdatePaymentMethod } from "utils/api";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { Success } from "assets/icon";

export function PaymentMethods() {
  const { currentUser } = useSelector((state: RootState) => state.user);

  const [PaymentMode, setPaymentMode] = useState<string>(
    currentUser?.preferredPaymentMethod ?? ""
  );
  const navigate = useNavigate();
  const form = useForm();
  const { mutateAsync, isLoading } = useUpdatePaymentMethod();
  const handleSubmit = async () => {
    const payload = {
      method: PaymentMode.toLowerCase(),
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Preferred payment Updated Successfully", {
            icon: Success,
          });
          navigate(-1);
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };

  return (
    <div className="bg-white fixed inset-0 w-full flex flex-col gap-y-3 items-start justify-start px-4 pt-9 pb-4">
      <Button
        onClick={() => navigate(-1)}
        className="px-0 items-center gap-x-2 font-medium"
      >
        <ArrowLeft size={30} />
        <h1 className="font-semibold text-xl">Payment Method</h1>
      </Button>

      <div className="w-full flex flex-col items-start justify-start">
        {["Wallet", "Card"].map((value, index) => (
          <FormField
            control={form.control}
            name="paymentMethod"
            render={({ field }) => (
              <label
                key={value}
                className="pay-container w-full my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center"
              >
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    {...field}
                    value={value}
                    defaultValue={PaymentMode}
                    onChange={(e) => {
                      setPaymentMode(e.target.value);
                    }}
                    className=""
                  />
                  <span className="pay-checkmark"></span>
                  <span className="pl-8 capitalize">{value}</span>
                </div>

                {index === 0 ? (
                  <div className="flex space-x-3 items-center">
                    <Wallet size="22" color="#BABABA" variant="Outline" />
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2">
                    <img src={visa} alt="" />
                    <img src={masterCard} alt="" />
                  </div>
                )}
              </label>
            )}
          />
        ))}

        <Button
          onClick={handleSubmit}
          className="w-full h-10 py-2 px-3 rounded-lg mt-6 bg-primary-100 text-white"
        >
          {isLoading ? (
            <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
}
