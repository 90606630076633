import { ArrowLeft } from "lucide-react";
import amala from "../../../assets/images/amala.png";
import { CiDeliveryTruck } from "react-icons/ci";
import { IoMdTime } from "react-icons/io";
import { BsTelephone } from "react-icons/bs";
import FoodlistItem from "./FoodlistItem";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Status } from "../../composables/status/Status";
import { Button, Input } from "../../composables";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchSingleOrder, useUpdateOrder } from "../../../utils/api";
import { FaSpinner } from "react-icons/fa6";
import { errorLogger, getNairaEquivalent } from "../../../utils/helper";
import { isWebview } from "utils/helper";
import { toast } from "react-toastify";
import moment from "moment";
import { Success } from "assets/icon";

const OrderDetail = () => {
  const { id } = useParams();
  const { data } = useFetchSingleOrder(id ?? "");
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [status, setStatus] = useState<string>();
  const navigate = useNavigate();

  const selectStatus = (value: string) => {
    setStatus(value);
  };

  const handleCall = (phoneNumber: string) => {
    if (isWebview()) {
      navigate(`/rider/orders/${id}?app_action_call=${phoneNumber}`);
    } else {
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          // Success: The text has been copied to the clipboard
          toast.success("Number Copied", {
            icon: Success,
          });
        })
        .catch((err) => {
          // Error: Something went wrong while copying the text
          console.error("Failed to copy text: ", err);
        });
    }
  };
  const { mutateAsync, isLoading } = useUpdateOrder();

  async function handleOrderUpdate() {
    if (status) {
      try {
        await mutateAsync(
          { orderId: id as string, status: status.toLowerCase() },
          {
            onSuccess() {
              toast.success(`Order ${status} successfully 🎉`, {
                icon: Success,
              });
            },

          },

        );
      } catch (error) {
        errorLogger(error);
      }
    }
  }

  return (
    <main className="relative sm:max-w-[600px] mx-auto px-5 pt-10 pb-24">
      <div className="flex items-center gap-3">
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1 className="font-semibold text-xl">
          Order: #{data?._id?.substring(0, 5)}
        </h1>
      </div>

      {/* vendor contact */}
      <div className="flex justify-between items-start mt-5 bg-[#F2F9ED] py-4 px-3 rounded-2xl">
        <div className="">
          <div className="flex justify-between">
            <div className="flex">
              {/* vendor img */}
              <div
                className="size-14 rounded-full bg-center bg-cover bg-no-repeat bg-grey-100"
                style={{
                  backgroundImage: `url(${data?.vendor?.profileImage?.url})`,
                }}
              ></div>
              {/* vendor name and order details */}
              <div className="ml-3.5 flex-col flex justify-between">
                <h2 className="font-semibold text-lg text-[#446929]">
                  {data?.vendor.businessName}
                </h2>
                <p className="mt-2 max-w-[200px] text-[#578735]">
                  {data?.vendor.address.streetAddress}
                </p>
                <div className="mt-4 flex items-center text-xs text-[#446929] gap-4">
                  <div className="flex items-center gap-2">
                    <CiDeliveryTruck size={20} />
                    <span>
                      ₦{getNairaEquivalent(data?.deliveryInfo.ridersFee)}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <IoMdTime size={18} />
                    <span>20 - 25 Min</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* call button */}
        <button
          onClick={() => handleCall(data?.vendor?.phoneNumber as string)}
          className="w-10 h-10 rounded-xl flex justify-center items-center bg-[#D6EBC7] text-[#578735]"
        >
          <BsTelephone size={20} />
        </button>
      </div>

      {/* customer contact */}
      <h1 className="mt-5 text-lg font-semibold">
        Order#{data?._id?.substring(0, 5)}
      </h1>
      <div className="px-3 py-4 flex justify-between border-b-2 border-b-grey-50">
        <div>
          <div>
            <h3 className="text-xs text-grey-200">Customer Details</h3>
            <h4 className="mt-2">{data?.customer?.fullName} </h4>
          </div>
          <div className="mt-3">
            <h3 className="text-xs text-grey-200">Address</h3>
            <h4 className="mt-2">
              {" "}
              {data?.deliveryInfo.address.streetAddress}{" "}
            </h4>
          </div>
          <div className="mt-3">
            <h3 className="text-xs text-grey-200">Phone 1</h3>
            <h4 className="mt-2"> {data?.deliveryInfo.contactPhone1} </h4>
          </div>
          {data?.deliveryInfo?.contactPhone2 && (
            <div className="mt-3">
              <h3 className="text-xs text-grey-200">Phone 2</h3>
              <h4 className="mt-2"> {data?.deliveryInfo.contactPhone2} </h4>
            </div>
          )}
          <div className="mt-3">
            <h3 className="text-xs text-grey-200">Customer Note </h3>
            <p className="mt-2">
              {" "}
              {data?.deliveryInfo.instructions ?? "No Description"}{" "}
            </p>
          </div>
          <div className="mt-3">
            <h3 className="text-xs text-grey-200">Time</h3>
            <h4 className="mt-2">{moment(data?.createdAt).format("llll")}</h4>
          </div>
        </div>

        {/* call button */}
        <button
          onClick={() =>
            handleCall(data?.deliveryInfo?.contactPhone1 as string)
          }
          className="w-10 h-10 rounded-xl flex justify-center items-center bg-[#D6EBC7] text-[#578735]"
        >
          <BsTelephone size={20} />
        </button>
      </div>
      {/* food checklist */}

      <h1 className="mt-5 text-lg font-semibold">Food Checklist</h1>
      <div className="py-4">
        {data?.packages.map((order, key) => (
          <div className="flex flex-col gap-3 py-4 border-b ">
            <h2>Order {key + 1}</h2>
            {order.items.map((fooditem, index) => (
              <FoodlistItem
                name={fooditem.product?.name ?? ""}
                price={fooditem?.unitPrice || 0}
                img={fooditem?.product?.image?.url ?? amala}
                qty={fooditem?.quantity ?? 0}
              />
            ))}
          </div>
        ))}
      </div>
      {data?.status !== "completed" && (
        <div>
          <div className="mt-5">
            <label>Update Status</label>
            <Input
              placeholder="Picked Up"
              readOnly
              type="text"
              value={status}
              onFocus={() => {
                setIsStatusOpen(true);
              }}
              className="w-full mt-2 bg-grey-30 rounded-lg"
            />
          </div>
          <AnimatePresence initial={false} mode="wait">
            {isStatusOpen && (
              <Status
                closeStatus={() => {
                  setIsStatusOpen((prev) => !prev);
                }}
                selectStatus={selectStatus}
              />
            )}
          </AnimatePresence>

          <Button
            onClick={handleOrderUpdate}
            className={`mt-5 w-full flex md:mt-10 justify-center py-3 px-3 rounded-lg bg-primary-100 text-white `}
          >
            {isLoading ? (
              <FaSpinner className="animate-spin" size={20} />
            ) : (
              "Continue"
            )}
          </Button>
        </div>
      )}
    </main>
  );
};

export default OrderDetail;
