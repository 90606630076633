import playstore from "../../assets/Svg/playstore.svg";
import apple from "../../assets/Svg/apple.svg";
import bike from "../../assets/Svg/bike.svg";
// import talent from "../../assets/images/talent.png";
import { useNavigate } from "react-router-dom";
import AnimatedText from "./components/AnimatedText";

export const Riders = () => {
  const navigate = useNavigate();

  return (
    <div>
      <section className="md:min-h-[calc(100dvh-58px)] container mx-auto px-5 lg:px-10 flex items-center lg:flex-row flex-col-reverse lg:*:w-1/2 gap-8 lg:h-[500px] lg:min-h-0 py-10">
        <div>
          <h1 className="font-medium text-2xl lg:text-5xl">
            Become A Ride Partner, Set Your Delivery Fee, Earn Money Delivering{" "}
            <br />{" "}
            <AnimatedText
              textList={["Food", "Groceries"]}
              className="text-primary-100"
            />{" "}
            on Jekaeat
          </h1>
          <p className="mt-6 text-lg text-grey-200">
            Earn daily, weekly and monthly delivering to customers' doorstep in
            Nigeria, you only need to complete your rider profile to get
            started.
          </p>
          <div>
            <div className="flex mt-4 gap-4 flex-col lg:flex-row max-w-44 lg:max-w-full">
              <a
                href="https://play.google.com/store/apps/details?id=com.jekaeat.jekaeat"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2"
              >
                <img className="w-8" src={playstore} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">GET IT ON</p>
                  <p className="font-medium text-lg">Google Play</p>
                </div>
              </a>

              <a
                href="https://apps.apple.com/ng/app/jekaeat/id6452400533"
                target="_blank"
                rel="noreferrer"
                className="flex bg-black text-white p-2 rounded-xl gap-2">
                <img className="w-8" src={apple} alt="" />
                <div className="text-left">
                  <p className="font-light text-xs">Download on the</p>
                  <p className="font-medium text-lg">App Store</p>
                </div>
              </a>

            </div>
            <button onClick={() => navigate('/auth/signin')} className="mt-4 text-primary-100 px-4 py-2 rounded-full border border-primary-100">
              Use Web App To Take Order
            </button>
          </div>
        </div>
        <div>
          <img className="lg:size-[500px]" src={bike} alt="" />
        </div>
      </section>

      {/* <section className="mt-20 py-20 bg-primary-100 text-white">
        <div className="container mx-auto px-10">
          <div className="text-center">
            <h1 className="font-medium text-4xl">Our Team</h1>
            <p>Our talented team of professionals consists of</p>
          </div>

          <div className="mt-8 grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-x-8 gap-y-6">
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
              <div className="flex flex-col items-center text-center">
                <div className="rounded-full size-16 overflow-hidden">
                  <img src={talent} alt="" />
                </div>
                <p className="mt-2 font-medium text-lg">Engineering</p>
                <p className="text-sm">
                  Lorem ipsum dolor sit amet consectetur. Etiam enim a semper
                  malesuada.
                </p>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};
