
import { GetUserWalletTxHistory } from "utils/api";
import { EmptyTransactionCard } from "../emptyTransaction/EmptyTransactionCard";
import { TransactionHistoryCard } from "../transactionHistoryCard/TransactionHistoryCard";
import { IWallet } from '../../../../../types/global.interface';
import { TransactionSkeleton } from "components/composables/Skeletons/Transaction";

export function TransactionHistory() {

  const { data: txHistory, isLoading } = GetUserWalletTxHistory()
  return (
    <div className="w-full flex flex-col items-start justify-start gap-y-6">
      <h2 className="text-base font-semibold">Transaction History</h2>

      {!isLoading ? <div className="flex flex-col w-full items-start justify-start">
        {(txHistory?.items as unknown as IWallet[] ?? []).length > 0 ? (
          (txHistory?.items as unknown as IWallet[] ?? []).map((v, index) => (
            <TransactionHistoryCard
              key={index}
              tx={v}
              className={
                index === (txHistory?.items as unknown as IWallet[]).length - 1
                  ? "border-b-0"
                  : "border-b"
              }
            />
          ))
        ) : (
          <EmptyTransactionCard />
        )}
      </div> :
        [1, 2, 3].map(item => (
          <TransactionSkeleton key={item} />
        ))
      }
    </div>
  );
}
