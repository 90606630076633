import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "components/composables";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForgetPassword } from "utils/api";
import { errorLogger } from "utils/helper";

import { Error, Success } from "assets/icon";

const ForgetPassword = () => {
  const form = useForm();
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useForgetPassword();
  const handleSubmit = async (data: any) => {
    const payload = {
      email: data.email?.toLowerCase(),
    };
    if (!data.email) {
      toast.error("Email field cannot be empty", {
        icon: Error,
      });
      return;
    }
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Check your email for the reset link", {
            icon: Success
          });
          navigate(`/auth/reset-password?email=${data.email}`);
        },
        onSettled: () => { },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };
  return (
    <main className="p-5">
      <div>
        <h1 className="text-xl font-bold text-grey-900">Forget Password</h1>
        <p className="mt-3 text-grey-200 text-sm">
          A link will be sent to the registered mail
        </p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            handleSubmit(data);
          })}
          className="mt-10"
        >
          {/* Email */}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="example@gmail.com"
                      type="email"
                      className="w-full pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="mt-10 w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
          >
            {isLoading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Send Link"
            )}
          </Button>
        </form>
      </Form>
    </main>
  );
};

export default ForgetPassword;
