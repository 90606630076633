import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  FormMessage,
  FormLabel,
} from "../composables";
import { useForm } from "react-hook-form";
import { loginValidationSchema } from "../../validations";

import { useDispatch, useSelector } from "react-redux";
import { errorLogger } from "../../utils/helper";
import { LoginAction } from "../../Redux/actions/auth";
import { RootState } from "Redux/reducer";

function CustomerSignin() {
  const dispatch = useDispatch<any>();

  const { cartItems } = useSelector(
    (state: RootState) => state.cart
  );
  useEffect(() => {
    // if (cartItems[0]?.products) {
    //   dispatch({
    //     payload: null,
    //     type: "LOGOUT",
    //   });
    // }
  }, [cartItems, dispatch])
  const navigate = useNavigate();
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(loginValidationSchema),
  });

  const handleSubmit = async (data?: any) => {
    function handleRememberMe() {
      const userData = {
        isRememberMe,
        email: data.email ?? form.getValues("email"),
        password: data.password ?? form.getValues("password"),
      };

      localStorage.setItem("userData", JSON.stringify(userData));
    }
    try {
      const payload = {
        email:
          data.email.toLowerCase() ?? form.getValues("email").toLowerCase(),
        password: data.password ?? form.getValues("password"),
      };
      setIsLoading(true);
      dispatch(LoginAction(payload, navigate, setIsLoading, handleRememberMe));
    } catch (e) {
      errorLogger(e);
    }
  };

  useEffect(() => {
    const email =
      JSON.parse(localStorage.getItem("userData") as string)?.email || "";
    const password =
      JSON.parse(localStorage.getItem("userData") as string)?.password || "";
    const rememberMe =
      JSON.parse(localStorage.getItem("userData") as string)?.isRememberMe ||
      false;

    form.setValue("email", email ?? "");
    form.setValue("password", password ?? "");

    setIsRememberMe(rememberMe);
    localStorage.setItem("newUser", "false")
    // console.log("email", email);
    // console.log("password", password);
    // console.log("rememberMe", rememberMe);
    rememberMe && handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full sm:max-w-[600px] mx-auto py-8 px-5">
      <div className="">
        <h1 className="text-xl font-bold text-grey-900">
          Hi, Welcome Back!👋{" "}
        </h1>
        <h2 className="text-grey-200 text-sm">We have missed you!</h2>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => handleSubmit(data))}
          className="w-full mt-10"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="John@gmail.com"
                    type="email"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <div className="w-full relative h-10">
                    <Input
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                      {...field}
                    />
                    <div
                      className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <LuEyeOff size={20}></LuEyeOff>
                      ) : (
                        <LuEye size={20}></LuEye>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="mt-5 flex justify-between items-center">
            <div className="flex items-center space-x-3 space-y-0">
              <input
                type="checkbox"
                id="rememberMe"
                checked={!!isRememberMe}
                onChange={(e) => {
                  setIsRememberMe(e.target.checked);
                }}
              />
              <label
                className="text-sm text-grey-900 focus:text-primary-100"
                htmlFor="rememberMe"
              >
                Keep me logged in
              </label>
            </div>

            <Link to="/auth/forgot-password" className="text-sm">
              Forget Password?
            </Link>
          </div>

          <div className="mt-5 flex flex-col items-center">
            <Button className="w-full h-11 py-2 px-3 rounded-xl bg-primary-100 text-white">
              {isLoading ? (
                <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
              ) : (
                "Sign In"
              )}
            </Button>
            <span className="mt-5">
              Don’t have an account?{" "}
              <Link className="font-bold" to={"/get-started/choose-user-type"}>
                Sign Up
              </Link>
            </span>
          </div>
        </form>
      </Form>
    </div>
  );
}

export default CustomerSignin;
