// import { CiEdit } from "react-icons/ci";
import Navbar from "../Navbar";
import { FaStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import {
  ArrowRight,
  ArrowRight2,
  Book,
  I24Support,
  LocationAdd,
  // Logout,
  LogoutCurve,
  MessageQuestion,
  MoneySend,
  SecurityTime,
  Shop,
  Timer1,
} from "iconsax-react";
// import { Wallet } from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetUserProfile } from "utils/api";
import coverImage from "../../../assets/images/restaurantBg.jpg";
import { useState } from "react";
import { Button } from "components/composables";
import ShareButtons from "components/composables/Share";
import { isWebview } from "utils/helper";
import Share from "../../../assets/Svg/share.svg";

const restarantSettings = [
  {
    icon: <Shop size={18} />,
    title: "Farmers Details",
    path: "/farmer/profile/details",
  },
  {
    icon: <LocationAdd size={18} />,
    title: "Add New Location",
    path: "/farmer/add-location",
  },
  {
    icon: <MoneySend size={18} />,
    title: "Account Settlement",
    path: "/account-settlement",
  },
  {
    icon: <Timer1 size={18} />,
    title: "Schedule",
    path: "/schedule",
  },
];

const helpLinks = [
  {
    icon: <I24Support size={18} />,
    title: "Support",
    path: "/rider/support",
  },
  {
    icon: <MessageQuestion size={18} />,
    title: "FAQs",
    path: "/rider/faqs",
  },
  {
    icon: <Book size={18} />,
    title: "About",
    path: "/customer/about",
  },
  {
    icon: <SecurityTime size={18} />,
    title: "Login & Security",
    path: "/farmer/login-security",
  },
];

const Profile = () => {
  const checkIsWebview = isWebview();
  const { currentUser } = useSelector((state: any) => state.user);
  const [coverImgURL] = useState(currentUser?.profileImage?.url ?? coverImage);
  const [isShareOpen, setIsShareOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useGetUserProfile();

  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    return baseUrl;
  };
  const handleShare = () => {
    console.log(getBaseUrl());

    navigate(
      `/customer/farmer/${
        currentUser?._id
      }?app_action_share=Check out this amazing Jekaeat partner restaurant: ${getBaseUrl()}/guest/farmer/${
        currentUser?._id
      }`
    );
    // (window as any).flutter_inappwebview?.callHandler('share', `Check out this amazing Jekaeat partner restaurant: ${getBaseUrl}/guest/farmer/${data?._id}`)
  };

  return (
    <main className="px-5 pb-24">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-xl">Profile</h1>
        <Button
          onClick={() => {
            if (checkIsWebview) {
              handleShare();
            } else {
              setIsShareOpen(!isShareOpen);
            }
          }}
          className="bg-white px-0 h-10 w-10 rounded-full p-1"
        >
          <img src={Share} alt="Share" className="text-[22px]" />
        </Button>
        {isShareOpen && (
          <div className="z-10 absolute right-5 top-12">
            <ShareButtons
              url={`${getBaseUrl()}/guest/farmer/${currentUser?._id}`}
              title={`Check out this amazing Jekaeat partner restaurant: ${getBaseUrl()}/guest/farmer/${
                currentUser?._id
              } `}
            />{" "}
          </div>
        )}
        {isShareOpen && (
          <div
            className="fixed inset-0 z-[9] bg-transparent"
            onClick={() => {
              setIsShareOpen(!isShareOpen);
            }}
          />
        )}
      </div>

      {/* Account info */}
      <div className="mt-4 px-4 py-3 rounded-lg bg-[#F2F9ED]">
        <div className="flex items-center justify-between gap-10">
          <div>
            <h1 className="font-semibold text-lg">
              {currentUser?.businessName ?? "Business Name"}
            </h1>
            <p className="mt-2 text-xs text-[#4D4D4D]">
              {currentUser?.address?.streetAddress ?? "Address"}{" "}
            </p>
          </div>

          <div className="shrink-0 w-14 h-14 rounded-full overflow-hidden">
            <img
              className="w-full h-full"
              src={coverImgURL}
              alt="Profile Img"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <FaStar color="#FFD700" />
            <span>4.5</span>
          </div>
          <button className="flex items-center gap-2">
            <span>Switch Account</span>
            <ArrowRight size={20} />
          </button>
        </div>
      </div>

      {/* Restaurant settings */}
      <div className="py-6">
        <h1 className="font-semibold text-xl">Restaurant Settings</h1>

        {/* Links */}
        <div className="mt-4">
          {restarantSettings.map((link, idx) => (
            <Link
              key={idx}
              to={link.path}
              className="flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#f4f4f4]">
                  {link.icon}
                </div>
                <h2>{link.title}</h2>
              </div>
              <ArrowRight2 color="#BABABA" size={20} />
            </Link>
          ))}
        </div>
      </div>

      {/* Help and Solution */}
      <div className="py-6 border-t-[3px] border-t-[#E9E9E9]">
        <h1 className="font-semibold text-xl">Help and Solutions</h1>

        {/* Links */}
        <div className="mt-4">
          {helpLinks.map((link, idx) => (
            <Link
              key={idx}
              to={link.path}
              className="flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#f4f4f4]">
                  {link.icon}
                </div>
                <h2>{link.title}</h2>
              </div>
              <ArrowRight2 color="#BABABA" size={20} />
            </Link>
          ))}
          <button
            onClick={() => {
              dispatch({
                payload: null,
                type: "LOGOUT",
              });
              sessionStorage.removeItem("token");
              navigate("/auth/signin");
            }}
            className="w-full flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
          >
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#FFECEC]">
                <LogoutCurve color="#E83E3E" size={18} />
              </div>
              <h2>Logout</h2>
            </div>
          </button>
        </div>
      </div>
      <Navbar />
    </main>
  );
};

export { Profile };
