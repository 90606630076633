export const MenuListSkeleton = () => (
  <div className="w-full  my-4 px-3 py-4 flex justify-between items-stretch border border-gray-200 rounded-2xl animate-pulse">
    <div className="flex gap-3 w-full flex-1">
      <div className="w-20 h-16 bg-gray-200 rounded-md" />

      <div className="w-full">
        <div className="h-4 bg-gray-200 rounded w-2/3 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
    </div>

    <div className="flex flex-col justify-between items-end text-xs">
      <div className="h-4 w-4 bg-gray-200 rounded-sm mb-2"></div>

      <div className="py-2 px-3 rounded-md bg-gray-200"></div>
    </div>
  </div>
);
