import { MealItemsEntity } from "types/global.interface";
import { VendorMealCard } from "../vendormealCard/VendorMealCard";

export function MealPack({ meals }: { meals: MealItemsEntity[] | undefined }) {
  return (
    <div className="">
      <div className="flex w-full flex-col gap-y-3">
        {meals?.map((meal, key) => (
          <VendorMealCard meal={meal} key={key} isMealPack />
        ))}
      </div>
    </div>
  );
}
