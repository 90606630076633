import * as type from "../Types";
import axios from "../../../utils/useAxios";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { errorLogger } from "../../../utils/helper";
import { Success } from "assets/icon";

const GetUsersSuccess = (data: any) => {
  return {
    type: type.FETCH_USER_SUCCESS,
    payload: data,
  };
};

const loginSuccess = (data: any) => {
  return {
    type: type.LOGIN_SUCCESS,
    payload: data,
  };
};
// const logout = () => {
//   return {
//     type: type.LOGOUT,
//   };
// };
export const registration = (
  registrationParams: any,
  navigate: (v: string) => void,
  setLoading: (v: boolean) => void,
  role: string
) => {
  return async (dispatch: Dispatch) => {
    setLoading(true);
    await axios
      .post(`/auth/signup/${role.toLowerCase()}`, registrationParams)
      .then((res) => {
        console.log(res.data.data);
        let user = res.data.data.user;
        // let role = user.__t;
        const token = res.data.data.accessToken;
        sessionStorage.setItem("token", token);

        dispatch(getUserPersonalInformation(user));
        dispatch(loginSuccess(token));
        toast.success("Registration Successful", { icon: Success });
        if (role === "Customer") {
          navigate(`/customer/home?app_action_userId=${user._id}`);
        }
        if (role === "Rider") {
          navigate(`/rider/home?app_action_userId=${user._id}`);
        }
        if (role === "Vendor") {
          navigate(`/vendor/home?app_action_userId=${user._id}`);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error.response.data.error.message);
        errorLogger(error);
      });
  };
};

export const LoginAction = (
  loginParams: any,
  navigate: (v: string) => void,
  setLoading: (v: boolean) => void,
  handleRememberMe: () => void
) => {
  return async (dispatch: any) => {
    setLoading(true);
    await axios
      .post("auth", loginParams)
      .then((res) => {
        console.log(res.data.data);
        const { user } = res.data.data;
        dispatch(GetUsersSuccess(user));
        const token = res.data.data.accessToken;
        sessionStorage.setItem("token", token);
        dispatch(loginSuccess(token));
        dispatch(getUserPersonalInformation(user));
        handleRememberMe();
        const role = user.__t;
        if (role === "Customer") {
          navigate(`/customer/home?app_action_userId=${user._id}`);
        }
        if (role === "Rider") {
          navigate(`/rider/home?app_action_userId=${user._id}`);
        }
        if (role === "Vendor") {
          navigate(`/vendor/home?app_action_userId=${user._id}`);
        }
        // (window as any).flutter_inappwebview?.callHandler(
        //   "device_token",
        //   user._id
        // );
        (window as any)?.device_token.postMessage(user._id);

        setLoading(false);
        toast.success("Login successful", { icon: Success });
      })
      .catch((error) => {
        setLoading(false);
        errorLogger(error);
      });
  };
};

export const getUserPersonalInformation = (data: any) => {
  return {
    type: "FETCH_USER_SUCCESS",
    payload: data,
  };
};
