import React from "react";
import Hero from "./Hero";

import Services from "./Services";
import Delivery from "./Delivery";
import Download from "./Download";
import Footer from "./Footer";
import Restaurant from "./Restaurant";

function Landing() {
  return (
    <div className="max-w-[1440px] explore -mt-4 md:mx-auto ">
      <Hero />
      <Download />
      <Services />
      <Restaurant />
      <Delivery />
      <Footer />
    </div>
  );
}

export default Landing;
