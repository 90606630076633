import { ArrowLeft } from "iconsax-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MealCard, VendorCard } from "../_component";
import { MealItemsEntity } from "types/global.interface";
import { useGetMyFavoriteMeal, useGetMyFavoriteVendor } from "utils/api";
import { useDispatch } from "react-redux";
import { MealCardSkeleton } from "components/composables/Skeletons/MealCard";
import { VendorListSkeleton } from "components/composables/Skeletons/vendorlIst";

const Favorites = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tab, setTab] = useState("meals");
  const { data: favVendor, isLoading: isFavVendorLoading } = useGetMyFavoriteVendor()
  const { data: favMeal, isLoading: isFavMealLoading } = useGetMyFavoriteMeal()

  function handleHandPickedSelection(meal: MealItemsEntity) {
    dispatch({
      type: "UPDATE_HANDPICKED_MEAL",
      payload: meal
    })
    dispatch({
      type: "GET_ACTIVE_VENDOR",
      payload: meal.vendor,
    })
    navigate(`/customer/vendor/${meal.vendor._id}?action=openMeal`)
  }

  return (
    <main className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Favorites</h1>
      </div>

      {/* form stage indicator */}
      <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full cursor-pointer">
        <div
          onClick={() => {
            setTab("meals");
          }}
          className={`h-full basis-0 ${tab === "meals" ? "bg-primary-100 text-white" : ""
            } grow text-center rounded-full py-2`}
        >
          Meals
        </div>
        <div
          onClick={() => {
            setTab("vendors");
          }}
          className={`h-full basis-0 grow text-center rounded-full py-2 cursor-pointer ${tab === "vendors" ? "bg-primary-100 text-white" : ""
            }`}
        >
          Vendors
        </div>
      </div>
      {tab === "meals" ? (
        <div className="mt-6">
          <div className="flex flex-col items-start justify-start w-full gap-y-3">
            {!isFavMealLoading ? <div className="grid grid-cols-2 items-center gap-2 w-full ">
              {favMeal?.items.map((meal, key) => (
                <MealCard meal={meal.product} key={key} onClick={() => handleHandPickedSelection(meal)} />
              ))}
            </div> :

              <div className="grid grid-cols-2 items-center gap-2 w-full ">
                {[1, 2, 3, 4].map((_, key) => (
                  <MealCardSkeleton key={key} />
                ))}
              </div>
            }
          </div></div>
      ) : (
        <div className="mt-6">
          <div className="w-full flex flex-col items-start justify-start pb-20">
            <div className="flex flex-col gap-y-4 items-center w-full">
              {isFavVendorLoading ? (
                <VendorListSkeleton />
              ) : (
                <div className="w-full">
                  {favVendor?.items?.map((v, key: number) => (
                    <VendorCard className="w-full" key={key} item={v} isFav />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Favorites;
