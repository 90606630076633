import React from "react";
import download_google from "../../assets/Svg/download_google.svg";
import download_apple from "../../assets/Svg/download_apple.svg";
import download_phone from "../../assets/Svg/download_phone.svg";
import { isWebview } from "../../utils/helper";

function Download() {
  const checkIsWebview = isWebview();

  return (
    <div
      id="download"
      className="py-5 md:px-[100px] px-6 text-white transform  md:translate-y-40"
    >
      <div className="download w-full rounded-lg md:h-[350px] h-[620px] grid md:grid-cols-2 py-10 relative">
        <div className="absolute md:-top-40 md:left-28 w-full md:w-max transform translate-x-[-25%] md:translate-x-0 left-[50%]  ">
          <img
            src={download_phone}
            className="md:w-full md:h-full h-[350px]"
            alt=""
          />
        </div>
        <div className="md:h-0 h-[200px]"></div>
        <div className="space-y-6 px-8 md:pt-0 pt-28 flex flex-col items-center md:items-start">
          <h1 className="font-bold md:text-4xl  text-2xl text-center md:text-left">
            It’s Now More Easy to Order by Our Mobile App
          </h1>
          <p className="font-light md:text-base text-xs text-center md:text-left">
            All you need to do is download the Jekaeat delivery app from the
            option that suits your device, place your order, and relax while
            your delivery reaches you in no time.
          </p>
          {!checkIsWebview ? (
            <div className="flex items-center space-x-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.jekaeat.jekaeat"
                target="_blank"
                rel="noreferrer"
              >
                <img src={download_google} alt="" />
              </a>
              <a
                href="https://apps.apple.com/ng/app/jekaeat/id6452400533"
                target="_blank"
                rel="noreferrer"
              >
                <img src={download_apple} alt="" />
              </a>
            </div>
          ) : (
            <div>
              You already have the Jekaeat delivery app installed, savor the
              juicy offers and experience Jekaeat brings to you.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Download;
