import { IoIosStar, IoMdTime } from "react-icons/io";
import { PiVanLight } from "react-icons/pi";
import { IVendor } from "types/global.interface";
import { isShopAvailable } from "utils/helper";
export const AboutVendor = ({ vendor }: { vendor: IVendor }) => {
  const openAt = vendor && vendor.schedule.length > 0 ? vendor.schedule[0].from : "N/A"
  const closeAt = vendor && vendor.schedule.length > 0 ? vendor.schedule[0].to : "N/A"
  return (
    <div className="w-full flex items-center justify-center flex-col gap-y-2">
      <h2 className="font-semibold text-lg">{vendor?.businessName}</h2>
      <p className="capitalize">{vendor?.address?.streetAddress} - {vendor?.location}</p>

      {isShopAvailable(vendor?.schedule) && !vendor.shopClosed ? <div className="bg-[#F2F9ED] w-fit   text-[#578735] rounded-3xl px-3 py-2 text-xs mt-3">
        <p>Open {openAt} - Close {closeAt}</p>
      </div> : <div className="bg-red-100 w-fit  text-red-400 font-semibold rounded-3xl px-3 py-2 text-xs mt-3">
        <p>Vendor Closed</p>
      </div>}

      <div className="flex items-center text-xs justify-around w-full">
        <div className="flex  font-light items-center gap-x-1">
          <IoIosStar className="text-lg text-yellow-500" />
          <span className="font-normal">4.5</span>
          <span className="text-gray-600">(0 review)</span>
        </div>

        <div className="flex items-center gap-x-2">
          <PiVanLight className="text-lg" />
          <p>₦350</p>
        </div>

        <div className="flex items-center gap-x-2">
          <IoMdTime className="text-lg" />
          <p>20 - 25 Min</p>
        </div>
      </div>
    </div >
  );
};
