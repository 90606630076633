import { SlCalender } from "react-icons/sl";
import coin from "../../../assets/Svg/coin.svg";
// import { CiDeliveryTruck } from "react-icons/ci";
// import { IoMdTime } from "react-icons/io";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import { useFetchMyOrders, useFetchMyPayout } from "utils/api";
import { PayoutItemsEntity } from "types/global.interface";
import moment from "moment";
import { digitFormatter, getNairaEquivalent } from "utils/helper";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/composables/selectcn/Select";

const Deliveries = () => {
  const { data: myOrder } = useFetchMyOrders();
  const { data } = useFetchMyPayout();

  return (
    <main className="sm:max-w-[600px] mx-auto px-5 pb-24">
      <h1 className="font-semibold text-xl">Deliveries</h1>
      {/* select timeline */}

      <Select>
        <SelectTrigger className="mt-4 flex py-2 pl-3 items-center gap-2.5 border border-grey-50 focus-within:border-grey-300 rounded-lg max-w-[160px]">
          <SlCalender />
          <SelectValue placeholder="Today" />
        </SelectTrigger>
        <SelectContent className="bg-white rounded-lg">
          <SelectItem className="text-sm py-2.5 border-b" value="today">
            Today
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="yesterday ">
            Yesterday
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="this_week">
            This Week
          </SelectItem>
          <SelectItem className="text-sm py-2.5" value="this_month">
            This Month
          </SelectItem>
        </SelectContent>
      </Select>

      {/* Total order */}
      <Link
        to={`/vendor/insight?totalOrderCount=${myOrder?.totalCount}`}
        className="mt-5 flex items-center justify-between p-4 bg-[#f4f4f4] rounded-2xl"
      >
        <span className="text-xs text-[#6A6A6A]">Total Order</span>
        <span className="text-xl font-semibold">
          {myOrder?.totalCount ?? 0}
        </span>
      </Link>

      {/* current balance */}
      <Link
        to={"/vendor/balance"}
        className="block relative mt-4 rounded-2xl p-4 bg-[#f4f4f4]"
      >
        <h1 className="text-xs text-[#6A6A6A]">Current Balance</h1>
        <h2 className="mt-2 text-3xl font-bold">₦0.00</h2>
        <img className="absolute right-0 top-0" src={coin} alt="" />
      </Link>

      {/* transaction history */}
      <div>
        <h1 className="mt-5 font-semibold text-xl">Transaction History</h1>

        {(data?.items ?? []).map((item: PayoutItemsEntity, key: number) => (
          <div
            key={key}
            className="py-4 flex items-center justify-between border-b border-b-[#F4F4F4]"
          >
            <div>
              <p>You Received</p>
              <p className="text-[#999999] text-xs">
                {moment(item?.initiatedAt).format("lll")}
              </p>
            </div>
            <div className="font-bold text-lg">
              ₦{digitFormatter(getNairaEquivalent(item.amount ?? 0))}
            </div>
          </div>
        ))}
      </div>

      <Navbar />
    </main>
  );
};

export { Deliveries };
