// Action Creators
import * as type from "../Types";
import { toast } from "react-toastify";
import { generateRandomID } from "../../../utils/helper";
import { Dispatch } from "redux";
import { ICartItems, MealsEntity } from "Redux/reducer/cart";
import { RootAction, RootState } from "Redux/reducer";
import { ThunkAction } from "redux-thunk";
import { Error, Success } from "assets/icon";

const IncreaseCount = (data: any) => {
  return {
    type: type.GET_ITEMS_COUNT_INCREMENT,
    payload: data,
  };
};

const decreaseCount = (data: any) => {
  return {
    type: type.GET_ITEMS_COUNT_DECREMENT,
    payload: data,
  };
};

const getFoodItem = (data: any) => {
  return {
    type: type.GET_FOOD_ITEMS,
    payload: data,
  };
};

const sumFoodItems = (data: any) => {
  return {
    type: type.SUM_FOOD_ITEMS,
    payload: data,
  };
};

const resetStackPrice = () => {
  return {
    type: type.RESET_STACK_PRICE,
  };
};

const getVendorInfo = (data: any) => {
  return {
    type: type.GET_VENDOR_INFO,
    payload: data,
  };
};

const getFeaturedVendors = () => {
  return {
    type: type.GET_FEATURED_VENDORS,
  };
};

const getPreviousOrders = () => {
  return {
    type: type.GET_PREVIOUS_ORDERS,
  };
};

const resetCart = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: type.ADD_FOODITEMS_TO_CART, payload: [] });
    dispatch({ type: "UPDATE_TOTAL_PRICE", payload: 0 });
  };
  // return {
  //   type: type.RESET_CART,
  // };
};

const calculateTotalPrice = (): ThunkAction<
  void,
  RootState,
  unknown,
  RootAction
> => {
  return (dispatch, getState) => {
    const { cartItems } = getState().cart;
    const calculatedTotal = cartItems.reduce(
      (acc, curr) => acc + parseFloat(curr.totalPrice as unknown as string),
      0
    );
    console.log(calculatedTotal);
    dispatch({ type: "UPDATE_TOTAL_PRICE", payload: calculatedTotal });
  };
};

const addFoodItemsToCart = (
  data?: any
): ThunkAction<void, RootState, unknown, RootAction> => {
  //Function -
  // add a new order,
  // add a new meal and
  // increase existing meal - Done
  //then create a new order,
  //if not then update the existing order_meal
  return (dispatch, getState) => {
    const { cartItems, currentOrderIndex } = getState().cart;

    //create first order in cart.
    let newOrder: ICartItems = {
      order_id: "",
      name: "",
      items: [],
      totalPrice: 0,
    };
    console.log(data);
    if (!data) {
      // if ORDER_FOR_NEW_PERSON button is invoked without data yet.
      newOrder.order_id = generateRandomID(10);
      newOrder.name = "name";
      newOrder.items = [];
      newOrder.totalPrice = 0;
      const updatedCart = [...cartItems, newOrder];
      dispatch({ type: "ADD_FOODITEMS_TO_CART", payload: updatedCart });
      dispatch({
        type: "UPDATE_CURRENT_ORDER_INDEX",
        payload: newOrder.order_id,
      });

      return;
    } else {
      const getOrder = cartItems.find(
        (item) => item.order_id === currentOrderIndex
      );
      console.log("getOrder", getOrder);
      const getMeal =
        getOrder &&
        getOrder?.items?.find((item) => item.product === data.product);
      // check if order and and meal exist in cart, if yes then increase the quantity

      if (getOrder && getMeal) {
        // Done
        toast.success("Order updated", { icon: Success });
        dispatch(
          increaseSingleCartItems(getOrder?.order_id as string, getMeal.product)
        );
        return;
      }
      // check if its only order that exists in cart, if yes then add new meal.

      if (getOrder) {
        toast.success("Meal added", { icon: Success });
        dispatch(addNewMealToOrder(data));
        return;
      }

      console.log(data);
      //create the very first order in cart.
      data.mealPrice = data.quantity * data.unitPrice;
      newOrder.order_id = generateRandomID(10);
      newOrder.name = "name";
      newOrder.items = [data];
      newOrder.totalPrice = newOrder.items.reduce(
        (total, item) => total + item.mealPrice,
        0
      );
      const updatedCart = [...cartItems, newOrder];
      toast.success("New Order added successfully", { icon: Success });
      dispatch({ type: "ADD_FOODITEMS_TO_CART", payload: updatedCart });
      dispatch({
        type: "UPDATE_CURRENT_ORDER_INDEX",
        payload: newOrder.order_id,
      });

      dispatch(calculateTotalPrice());
    }
  };
};

const updateCurrentOrderIndex = (order_id: string) => {
  // if ADD_MEAL button is invoked without data yet.
  return (dispatch: Dispatch) => {
    dispatch({ type: "UPDATE_CURRENT_ORDER_INDEX", payload: order_id });
  };
};
const addNewMealToOrder = (
  data: any
): ThunkAction<void, RootState, unknown, RootAction> => {
  //Function -
  // add a new order,

  return (dispatch, getState) => {
    const getCart = getState().cart.cartItems;
    const { cartItems, currentOrderIndex } = getState().cart;
    const getOrder = cartItems.find(
      (item) => item.order_id === currentOrderIndex
    ) as ICartItems;

    console.log(getOrder);
    data.mealPrice = data.quantity * data.unitPrice;
    getOrder.items = [...getOrder?.items, data];
    getOrder.totalPrice = getOrder.items.reduce(
      (total, item) => total + item.mealPrice,
      0
    );
    console.log(getOrder);
    const getItemIndex = getCart.findIndex(
      (item) => item.order_id === currentOrderIndex
    );
    getCart.splice(getItemIndex, 1, getOrder);
    dispatch({ type: "ADD_FOODITEMS_TO_CART", payload: getCart });
    dispatch(calculateTotalPrice());
  };
};

const increaseSingleCartItems = (
  order_id: string,
  meal_id: string
): ThunkAction<void, RootState, unknown, RootAction> => {
  return (dispatch, getState) => {
    console.log("Add item to cart firs");
    const getCart = getState().cart.cartItems;
    const getSinglePersonItem = getCart.find(
      (item) => item.order_id === order_id
    ) as ICartItems;
    const getMeal = getSinglePersonItem?.items.find(
      (item) => item.product === meal_id
    ) as MealsEntity;

    console.log(getSinglePersonItem);
    console.log(getMeal);
    //check if the item to increase or decrease exist inside any package in cart
    if (!getSinglePersonItem || !getMeal) {
      toast.error("Add item to cart first", {
        icon: Error,
      });
      return;
    }

    getMeal.quantity += 1; //main action
    getMeal.mealPrice = getMeal.quantity * getMeal.unitPrice;
    getSinglePersonItem.totalPrice = getSinglePersonItem.items.reduce(
      (total, item) => total + item.mealPrice,
      0
    );
    const getItemIndex = getCart.findIndex(
      (item) => item.order_id === order_id
    );
    getCart.splice(getItemIndex, 1, getSinglePersonItem);
    dispatch({ type: "ADD_FOODITEMS_TO_CART", payload: getCart });
    dispatch(calculateTotalPrice());
  };
};
const decreaseSingleCartItems = (
  order_id: string,
  meal_id: string
): ThunkAction<void, RootState, unknown, RootAction> => {
  return (dispatch, getState) => {
    const getCart = getState().cart.cartItems;
    const getSinglePersonItem = getCart.find(
      (item) => item.order_id === order_id
    ) as ICartItems;
    const getMeal = getSinglePersonItem.items.find(
      (item) => item.product === meal_id
    ) as MealsEntity;

    //check if the item to increase or decrease exist inside any package in cart
    if (!getSinglePersonItem || !getMeal) {
      toast.error("Add item to cart first", { icon: Error });
      return;
    }

    if (getMeal.quantity > 1) {
      getMeal.quantity -= 1;
      getMeal.mealPrice = getMeal.quantity * getMeal.unitPrice;
      getSinglePersonItem.totalPrice = getSinglePersonItem.items.reduce(
        (total, item) => total + item.mealPrice,
        0
      );
      const getItemIndex = getCart.findIndex(
        (item) => item.order_id === order_id
      );
      getCart.splice(getItemIndex, 1, getSinglePersonItem);
      dispatch({ type: "ADD_FOODITEMS_TO_CART", payload: getCart });
      dispatch(calculateTotalPrice());
    }
  };
};

const removeFromCart = (
  order_id: string,
  meal_id: string
): ThunkAction<void, RootState, unknown, RootAction> => {
  return (dispatch, getState) => {
    const getCart = getState().cart.cartItems;
    const getSinglePersonItem = getCart.find(
      (item) => item.order_id === order_id
    ) as ICartItems;

    const getSinglePersonItemResult = getSinglePersonItem.items.filter(
      (item) => item.product !== meal_id
    );
    //if the item to remove is the last item on the list, then the complete order should go
    if (getSinglePersonItemResult.length === 0) {
      dispatch(removeOrder(order_id));
      return;
    }
    getSinglePersonItem.items = getSinglePersonItemResult;
    getSinglePersonItem.totalPrice = getSinglePersonItem.items.reduce(
      (total, item) => total + item.mealPrice,
      0
    );
    console.log(getSinglePersonItem);
    const getItemIndex = getCart.findIndex(
      (item) => item.order_id === order_id
    );
    getCart.splice(getItemIndex, 1, getSinglePersonItem);
    dispatch({ type: type.ADD_FOODITEMS_TO_CART, payload: getCart });
    toast.success("Item removed successfully", { icon: Success });
    dispatch(calculateTotalPrice());
  };
};
const removeOrder = (
  order_id: string
): ThunkAction<void, RootState, unknown, RootAction> => {
  return (dispatch, getState) => {
    const getCart = getState().cart.cartItems;
    // const getItemIndex = getCart.findIndex(
    //   (item) => item.order_id === order_id
    // );

    const updatedCart = getCart.filter((item) => item.order_id !== order_id);
    // if the order to remove is the last order. then empty the cart...
    if (updatedCart.length === 0) {
      dispatch(resetCart());
      return;
    }
    dispatch({ type: type.ADD_FOODITEMS_TO_CART, payload: updatedCart });
    toast.success("Order removed successfully", { icon: Success });
    dispatch(calculateTotalPrice());
  };
};

const addMealPackToCart = (data: any) => {
  return {
    type: type.ADD_MEALPACK_TO_CART,
    payload: data,
  };
};

const showCartCount = () => {
  return {
    type: type.SHOW_CART_COUNT,
  };
};

const sendOrderToVendor = (data: any) => {
  return {
    type: type.SEND_ORDER_TO_VENDOR,
    payload: data,
  };
};
const reOrderFromVendor = (data: any) => {
  return {
    type: type.RE_ORDER_FROM_VENDOR,
    payload: data,
  };
};
const showLiveChat = (data: any) => {
  return {
    type: type.SHOW_LIVE_CHAT,
    payload: data,
  };
};
const viewChat = (data: any) => {
  return {
    type: type.VIEW_CHAT,
    payload: data,
  };
};
const getUserPreviousOrderInfo = (data: any) => {
  return {
    type: type.GET_USER_PREVIOUS_ORDER_INFO,
    payload: data,
  };
};

export {
  getUserPreviousOrderInfo,
  getPreviousOrders,
  reOrderFromVendor,
  getFeaturedVendors,
  showCartCount,
  sendOrderToVendor,
  resetCart,
  viewChat,
  showLiveChat,
  increaseSingleCartItems,
  decreaseSingleCartItems,
  updateCurrentOrderIndex,
  removeOrder,
  getVendorInfo,
  addMealPackToCart,
  sumFoodItems,
  resetStackPrice,
  removeFromCart,
  addFoodItemsToCart,
  getFoodItem,
  decreaseCount,
  IncreaseCount,
};
