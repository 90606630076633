import moment from "moment";
import { IoMdTime } from "react-icons/io";
import { Link } from "react-router-dom";
import { ItemsEntity } from "types/global.interface";
import { getNairaEquivalent } from "utils/helper";

export function OrderCard({
  type,
  order,
}: {
  type: string;
  order?: ItemsEntity;
}) {
  return (
    <Link
      to={`/customer/orders/${order?._id}?type=${type}`}
      className="w-full border flex flex-col gap-y-2 items-start justify-start rounded-3xl px-4 py-3"
    >
      <div className="flex items-center justify-between w-full">
        <p className="font-semibold text-base">{order?.packages[0]?.items.map(obj => obj?.product?.name).join(', ')}</p>
        <p className="font-semibold text-base">₦{getNairaEquivalent(order?.total)}</p>
      </div>
      <div className="flex items-center justify-between text-gray-400 w-full">
        <p className=" text-xs flex items-center">
          <span className="flex items-center gap-x-2 pr-2 border-r border-gray-400">
            <IoMdTime className="text-base " />
            <span>
              {" "}
              {moment(order?.createdAt).format("LT")}{" "}
            </span>
          </span>
          <span className="px-2">
            {moment(order?.createdAt).format("L")}
          </span>
        </p>
        <p className=" text-xs">{order?.vendor.businessName} </p>
      </div>
    </Link>
  );
}
