import {
  ArrowLeft,
  ArrowRight2,
  Facebook,
  Instagram,
  Message2,
  Sms,
  Whatsapp,
} from "iconsax-react";
import { useState } from "react";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../composables";

const supportLinks = [
  {
    icon: <Message2 />,
    title: "Live Chat",
    path: "https://wa.me/2348023139125",
  },
  {
    icon: <Sms />,
    title: "Email",
    path: "jekaeatcorporate.communications@gmail.com",
    mail: true
  },
  {
    icon: <Facebook />,
    title: "Facebook",
    path: "https://web.facebook.com/Jekaeat/",
  },
  {
    icon: <Instagram />,
    title: "Instagram",
    path: "https://www.instagram.com/jekaeat/",
  },
  {
    icon: <BsTwitter />,
    title: "Twitter",
    path: "https://twitter.com/JekaeatHQ",
  },
  {
    icon: <FaLinkedin />,
    title: "Linkedin",
    path: "https://www.linkedin.com/company/jekaeat",
  },
  {
    icon: <Whatsapp />,
    title: "Whatsapp",
    path: "https://wa.me/2348023139125",
  },
];

const faqs = [
  {
    question: "How does JekaEat work?",
    answer: "",
  },
  {
    question: "How do I pay for my food order?",
    answer: "",
  },
  {
    question: "Can I schedule a future food delivery?",
    answer: "",
  },
  {
    question: "What should I do if there's an issue with my order?",
    answer: "",
  },
  {
    question: "Do you offer promotions or discounts?",
    answer: "",
  },
  {
    question: " How do I become a delivery partner or partner with your app?",
    answer: "",
  },
];

const Support = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("support");
  return (
    <main className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Support & FAQs</h1>
      </div>

      {/* form stage indicator */}
      <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full cursor-pointer">
        <div
          onClick={() => {
            setTab("support");
          }}
          className={`h-full basis-0 ${tab === "support" ? "bg-primary-100 text-white" : ""
            } grow text-center rounded-full py-2`}
        >
          Support
        </div>
        <div
          onClick={() => {
            setTab("faq");
          }}
          className={`h-full basis-0 grow text-center rounded-full py-2 cursor-pointer ${tab === "faq" ? "bg-primary-100 text-white" : ""
            }`}
        >
          FAQs
        </div>
      </div>
      {tab === "support" ? (
        <div className="mt-6">
          {supportLinks.map((link) => (
            <Link
              to={!link.mail ? link.path : `mailto:${link.path}`}
              className="flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#f4f4f4]">
                  {link.icon}
                </div>
                <h2>{link.title}</h2>
              </div>
              <ArrowRight2 color="#BABABA" size={20} />
            </Link>
          ))}
        </div>
      ) : (
        <div className="mt-6">
          <h1 className="text-lg font-semibold">
            We are here to help you on anything and everything about JekaEat
          </h1>
          <div className="mt-4">
            {faqs.map((faq) => (
              <Accordion type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger className="text-left">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="flex-0">
                    {faq.answer}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
          </div>
        </div>
      )}
    </main>
  );
};

export default Support;
