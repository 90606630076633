import { useDispatch } from "react-redux";
import { getUserPersonalInformation } from "Redux/actions/auth";
import axios from "utils/useAxios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  IMeal,
  IOrder,
  IUserOrder,
  IVendor,
  ItemsEntity,
  VendorList,
  ISearch,
  IFavVendors,
  IFavMeal,
  IWalletData,
  IStat,
  MealItemsEntity,
  IPayout,
  IRouteOptimization,
  IReferee,
  IEarning,
} from "types/global.interface";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { Success } from "assets/icon";

export async function resetVerificationOTP(payload: { userId: string | null }) {
  return await axios.post(`/auth/${payload.userId}/send-verification-otp`);
}
export async function verifyOTP(payload: {
  userId: string | null;
  otp: string;
}) {
  return await axios.put(`/auth/${payload.userId}/account-verification`, {
    otp: payload.otp,
  });
}
// Utility

export const useGetOrderingStatus = () => {
  return useQuery({
    queryKey: "get_ordering_status",
    queryFn: async () => {
      try {
        const response = await axios.get(`utilities/ordering-status`);

        return response.data.data as any;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useForgetPassword = () => {
  return useMutation({
    mutationKey: "forgot_password",
    mutationFn: async (payload: any) => {
      return await axios.post("/auth/forgot-password", payload);
    },
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationKey: "reset_password",
    mutationFn: async (payload: any) => {
      return await axios.post(`/auth/reset-password`, payload);
    },
  });
};

export const useChangeUserPassword = () => {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_password",
    async (payload: any) => {
      return await axios.patch(`/me/change-password`, payload);
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
};

export const useGetUserProfile = () => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: "get_user",
    queryFn: async () => {
      try {
        const response = await axios.get(`me`);
        const user = response.data.data;
        dispatch(getUserPersonalInformation(user));
        return response.data.data as any;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

export const useGetMealSuggestion = () => {
  return useQuery({
    queryKey: "get_meal_suggestions",
    queryFn: async () => {
      try {
        const response = await axios.get(`utilities/meal-suggestions`);

        return response.data.data as any;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useGeneralSearch = (query: any) => {
  return useQuery({
    queryKey: ["search", query],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `/utilities/search?searchText=${query}`
        );

        return response.data.data as ISearch;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
//rate user
export function useRateUser() {
  const mutation = useMutation({
    mutationKey: "rate_user",
    mutationFn: async (payload: any) => {
      delete payload.vendorId;
      const url = payload.rider
        ? `riders/${payload.userId}/ratings/`
        : `vendors/${payload.userId}/ratings/`;
      return await axios.post(`${url}`, payload, {});
    },
  });
  return mutation;
}

//Add to favourite
export function useAddToFavoriteVendor() {
  const mutation = useMutation({
    mutationKey: "add_fav_vendor",
    mutationFn: async (vendorId: any) => {
      return await axios.post(`/me/favorites/vendors/${vendorId}`, {});
    },
  });
  return mutation;
}

//Add to favourite
export function useGetMyFavoriteVendor() {
  const query = useQuery({
    queryKey: "get_my_fav",
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/favorites/vendors/`);
        return response.data.data as IFavVendors;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
  return query;
}

//Add to favourite
export function useAddToFavoriteMeal() {
  const mutation = useMutation({
    mutationKey: "add_fav_meal",
    mutationFn: async (mealId: any) => {
      return await axios.post(`/me/favorites/products/${mealId}`, {});
    },
  });
  return mutation;
}

//Add to favourite
export function useGetMyFavoriteMeal() {
  const query = useQuery({
    queryKey: "get_my_fav_meal",
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/favorites/products/`);
        return response.data.data as IFavMeal;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
  return query;
}
//Profiles
export function useUpdateCustomerProfile() {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_customer_profile",
    async (payload: any) => {
      return await axios.put(`/me`, payload);
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
}

//Update customer preffered payment method
export const useUpdatePaymentMethod = () => {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_paymeent_method",
    async (payload: any) => {
      return await axios.patch(`/me/payment-method`, payload);
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
};

export function useUpdateUserProfile() {
  const dispatch = useDispatch();
  const mutation = useMutation(
    "update_user_profile",
    async (payload: any) => {
      try {
        return await axios.put(`/me`, payload);
      } catch (error) {
        errorLogger(error);
      }
    },
    {
      onSuccess: async () => {
        try {
          const response = await axios.get(`me`);
          const user = response.data.data;
          dispatch(getUserPersonalInformation(user));
          return response.data.data as any;
        } catch (error) {
          errorLogger(error);
        }
      },
      onError: (err) => {
        console.log(err);
        errorLogger(err);
      },
    }
  );
  return mutation;
}

//GET: Rider vehicle info
export const useGetRiderVehicle = () => {
  return useQuery({
    queryKey: "get_rider_vehicle",
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/vehicle-info`);
        console.log(response.data.data);
        return response.data.data as any;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

//Update Rider Profile/Vehicles
export function useUpdateRiderVehicle() {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_rider_vehicle",
    async (payload: any) => {
      return await axios.put(`/me/vehicle-info`, payload);
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
}

// update account settlement info
export const useUpdateAccountInfo = () => {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_account_info",
    async (payload: any) => {
      return await axios.patch(`/me/settlement-info`, payload);
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
};
// update account online status(rider)
export const useUpdateAccountStatus = () => {
  const dispatch = useDispatch();
  const client = useQueryClient();
  const mutation = useMutation(
    "update_account_info_online",
    async () => {
      return await axios.patch(`/me/online`, {}).then(async () => {
        const response = await axios.get(`me`);
        const user = response.data.data;
        dispatch(getUserPersonalInformation(user));
      });
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
};
// update account online status(rider)
export const useUpdateShopStatus = () => {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_shop_status",
    async () => {
      return await axios.patch(`/me/shop-closed`, {});
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
};

// update schedule
export const useUpdateSchedule = () => {
  const client = useQueryClient();
  const mutation = useMutation(
    "update_schedule",
    async (payload: any) => {
      return await axios.patch(`/me/schedule`, payload);
    },
    {
      onSettled: () => {
        client.refetchQueries(`get_user`);
      },
    }
  );
  return mutation;
};

//Wallet

export const useGetUserWalletBalance = () => {
  return useQuery({
    queryKey: "get_user_wallet",
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/wallet/balance`);
        return response.data.data as number;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const GetUserWalletTxHistory = () => {
  return useQuery({
    queryKey: "get_user_wallet_history",
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/wallet/transactions`);
        return response.data.data as IWalletData;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

//Utility
export function useGeneratePaymentToken(paymentFor: "order" | "wallet") {
  const mutation = useMutation({
    mutationKey: "payment",
    mutationFn: async (payload: IMeal) => {
      if (paymentFor === "wallet") {
        //making wallet deposit
        return await axios.post(`/me/wallet/deposit-token`, payload);
      } else {
        //order payment with paystack
        return await axios.post(`/orders/token`, payload);
      }
    },
  });
  return mutation;
}

export async function fileUploadHandler(payload: FormData) {
  return await axios.post(`/utilities/file-upload`, payload);
}

//VENDOR

export const useFetchFoodVendors = (category: "restaurant" | "fresh_food") => {
  return useQuery({
    queryKey: [`get_food_vendors_${category}`],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `vendors/featured?category=${category}`
        );
        return response.data.data as VendorList;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useFetchSingleVendor = (vendor_id: string) => {
  return useQuery({
    queryKey: ["get_single_vendor", vendor_id],
    queryFn: async () => {
      try {
        const response = await axios.get(`vendors/${vendor_id}`);
        return response.data.data as IVendor;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

export function useAddMeal() {
  const mutation = useMutation({
    mutationKey: "add_meal",
    mutationFn: async (payload: IMeal) => {
      const vendorId = payload.vendorId;
      delete payload.vendorId;
      return await axios.post(`/vendors/${vendorId}/products/`, payload, {});
    },
  });
  return mutation;
}
// vendors/:vendorId/products

export const useFetchVendorMeals = (vendorId: string) => {
  return useQuery({
    queryKey: ["get_vendor_food", vendorId],
    queryFn: async () => {
      if (vendorId)
        try {
          const response = await axios.get(`vendors/${vendorId}/products`);
          return response.data.data as MealItemsEntity[];
        } catch (error) {
          errorLogger(error);
        }
    },
    enabled: vendorId ? true : false,
  });
};
export const useToggleVendorMeal = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationKey: "toggle_meal",
    mutationFn: async ({
      vendorId,
      mealId,
    }: {
      vendorId: string;
      mealId: string;
    }) => {
      try {
        return await axios.patch(
          `vendors/${vendorId}/products/${mealId}/in-stock`
        );
      } catch (error) {
        errorLogger(error);
      }
    },
    onSettled: () => {
      client.refetchQueries(`get_vendor_food`);
    },
  });
  return mutation;
};
export const useEditMeal = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationKey: "edit_meal",
    mutationFn: async ({
      vendorId,
      mealId,
      payload,
    }: {
      vendorId: string;
      mealId: string;
      payload: IMeal;
    }) => {
      try {
        return await axios.put(
          `vendors/${vendorId}/products/${mealId}`,
          payload
        );
      } catch (error) {
        errorLogger(error);
      }
    },
    onSettled: () => {
      client.refetchQueries(`get_vendor_food`);
    },
  });
  return mutation;
};
export const useDeleteVendorMeal = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationKey: "delete_meal",
    mutationFn: async ({
      vendorId,
      mealId,
    }: {
      vendorId: string;
      mealId: string;
    }) => {
      try {
        return await axios.delete(`vendors/${vendorId}/products/${mealId}`);
      } catch (error) {
        errorLogger(error);
      }
    },
    onSettled: () => {
      client.refetchQueries(`get_vendor_food`);
    },
  });
  return mutation;
};

//ORDER
export function useCreateOrder() {
  const mutation = useMutation({
    mutationKey: "create_order",
    mutationFn: async (payload: IOrder) => {
      try {
        return await axios.post(`/orders/token`, payload).then(async (res) => {
          const token = res.data.data;
          await axios.post(`/orders`, { token }).then((response) => {
            console.log(response);
            toast.success("Order created successfully", { icon: Success });
          });
        });
      } catch (error) {
        errorLogger(error);
      }
    },
  });
  return mutation;
}
//Get All My 0rders
export const useFetchMyOrders = (orderStatus?: string, offset?: number) => {
  orderStatus = orderStatus ?? "";
  offset = offset ?? 0;
  return useQuery({
    queryKey: ["get_my_orders", orderStatus, offset],
    keepPreviousData: true,
    queryFn: async () => {
      try {
        const response = await axios.get(
          `orders/?orderStatus=${orderStatus}&offset=${offset}`
        );
        return response.data.data.orders as IUserOrder;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useFetchVendorStat = () => {
  return useQuery({
    queryKey: ["get_my_orders_stats"],
    queryFn: async () => {
      try {
        const response = await axios.get(`orders/stats`);
        return response.data.data as IStat;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useFetchSingleOrder = (id: string) => {
  return useQuery({
    queryKey: ["get_Single_order", id],
    queryFn: async () => {
      try {
        const response = await axios.get(`orders/${id}`);
        return response.data.data as ItemsEntity;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useFetchAvailableOrders = () => {
  return useQuery({
    queryKey: ["get_available_order"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/orders/dispatch`);
        return response.data.data as IUserOrder;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

//Update Order
export function useApproveOrder() {
  // export function useUpdateOrderAvailability() {
  const mutation = useMutation({
    mutationKey: "update_order_approve",
    mutationFn: async (orderId: string) => {
      try {
        return await axios
          .patch(`/orders/${orderId}/approve`)
          .then(async (res) => {});
      } catch (error) {
        errorLogger(error);
      }
    },
  });
  return mutation;
}
//Update Order
export function useAcceptOrder() {
  const mutation = useMutation({
    mutationKey: "update_order_accept",
    mutationFn: async (orderId: string) =>
      await axios.patch(`/orders/${orderId}/accept`).then(async (res) => {}),
  });
  return mutation;
}

//dispatch, reject, complete, ready, canceled
export function useUpdateOrder() {
  const mutation = useMutation({
    mutationKey: "update_order",
    mutationFn: async (payload: {
      orderId: string;
      status:
        | "dispatch"
        | "reject"
        | "complete"
        | "ready"
        | "canceled"
        | string;
    }) => {
      return await axios
        .patch(`/orders/${payload.orderId}/${payload.status}`)
        .then(async (res) => {});
    },
  });
  return mutation;
}

//Fetch Route

export const useFetchMyRoute = () => {
  return useQuery({
    queryKey: ["get_my_route"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/route-optimization/`);
        return response.data.data as IRouteOptimization[];
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

//add_route
export function useAddRoute() {
  const client = useQueryClient();

  const mutation = useMutation({
    mutationKey: "add_route",
    mutationFn: async (payload: any) => {
      try {
        return await axios
          .post(`/route-optimization/`, payload)
          .then(async (res) => {});
      } catch (error) {
        errorLogger(error);
      }
    },
    onSettled: () => {
      client.refetchQueries(`get_my_route`);
    },
  });
  return mutation;
}

export const useDeleteRoute = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationKey: "delete_route",
    mutationFn: async ({ routeId }: { routeId: string }) => {
      try {
        return await axios.delete(`/route-optimization/${routeId}`);
      } catch (error) {
        errorLogger(error);
      }
    },
    onSettled: () => {
      client.refetchQueries(`get_my_route`);
    },
  });
  return mutation;
};
//Referrals
export const useFetchMyReferees = () => {
  return useQuery({
    queryKey: ["useFetchMyReferees"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/my-referees`);
        return response.data.data as IReferee[];
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};
export const useFetchMyEarning = () => {
  return useQuery({
    queryKey: ["useFetchMyEarning"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/me/wallet/my-earnings`);
        return response.data.data as IEarning[];
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

//Payout
export const useFetchMyPayout = () => {
  return useQuery({
    queryKey: ["get_available_payout"],
    queryFn: async () => {
      try {
        const response = await axios.get(`/payouts`);
        return response.data.data as IPayout;
      } catch (error) {
        errorLogger(error);
      }
    },
  });
};

export const useFetchAccountName = (accountNumber: string, bankCode: any) => {
  return useQuery({
    queryKey: ["get_account_detail", accountNumber, bankCode.value],
    queryFn: async () => {
      if (accountNumber.length > 9) {
        try {
          const response = await axios.get(
            `/utilities/resolve-bank-account?accountNumber=${accountNumber}&bankCode=${bankCode.value}`
          );
          return response.data.data as any;
        } catch (error) {
          errorLogger(error);
        }
      }
    },
  });
};
