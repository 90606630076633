import bag from "../../../assets/Svg/vendorBag.svg";
import colorBag from "../../../assets/Svg/colorVendorBag.svg";
import emptyOrder from "../../../assets/Svg/emptyOrder.svg";
import { useState } from "react";
import order from "../../../assets/Svg/orderIcon.svg";
import { IoClose } from "react-icons/io5";
import Order from "../Orders/OrderCard";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFetchMyOrders, useUpdateShopStatus } from "utils/api";
import coverImage from "../../../assets/images/restaurantBg.jpg";

export const Home = () => {
  const { currentUser } = useSelector((state: any) => state.user);
  const [isOnline, setIsOnline] = useState<boolean>(currentUser.shopClosed);
  const [coverImgURL] = useState(currentUser?.profileImage?.url ?? coverImage);
  const { data, isLoading } = useFetchMyOrders("pending");

  const { mutateAsync } = useUpdateShopStatus();
  return (
    <main className="sm:max-w-[600px] mx-auto px-5 pb-24">
      <Navbar />
      <div className="flex justify-between items-center">
        <div>
          <h1 className="font-semibold text-xl">
            {currentUser.businessName} Farm
          </h1>
          <p className="text-grey-200">Welcome Back</p>
        </div>
        <div className="w-8 h-8 flex justify-center items-center rounded-full overflow-hidden bg-grey-400">
          <Link to={"/vendor/profile"}>
            <img src={coverImgURL} alt={currentUser.businessName} />
          </Link>
        </div>
      </div>

      {/* Offline Online Control */}
      <div
        className={`relative mt-10 px-8 h-20 flex items-center rounded-3xl overflow-hidden cursor-pointer  ${
          isOnline ? "bg-primary-300" : "bg-grey-30"
        } `}
        onClick={() => {
          setIsOnline((prev) => !prev);
          mutateAsync();
        }}
      >
        <span className="font-semibold text-xl">
          {isOnline ? "Go Offline" : "Go Online"}
        </span>
        {isOnline ? (
          <img className="absolute right-8 -bottom-0" src={colorBag} alt="" />
        ) : (
          <img className="absolute right-8 -bottom-0" src={bag} alt="" />
        )}
      </div>

      {/* New order */}
      <div>
        <h1 className="mt-10 font-semibold text-xl">New Order</h1>
        {(data?.items ?? [])?.length > 0 && !isLoading ? (
          <>
            {/* // available order notification */}
            <div className="relative mt-3 px-3 h-20 flex justify-between items-center rounded-3xl text-[#A1DDF3] bg-[#EBF8FC] border border-dashed border-[#A1DDF3]">
              <div className="flex items-center gap-5">
                <img src={order} alt="" />
                <span className="text-xs">
                  You have {data?.items?.length ?? 0} available Order
                </span>
              </div>
              <IoClose />
            </div>
            {/* // Orders */}
            <div className="py-5 flex flex-col gap-4">
              {(data?.items ?? []).map((order, key) => (
                <Order order={order} key={key} />
              ))}
            </div>
          </>
        ) : (
          <>
            {/* No order */}
            <div className="py-20 flex flex-col items-center">
              <img src={emptyOrder} alt="" />
              <h2 className="mt-5 font-semibold text-lg">No Order</h2>
              <p className="mt-2">You don’t have an active order</p>
            </div>
          </>
        )}
      </div>
    </main>
  );
};
