/* eslint-disable array-callback-return */
import { MdNavigateBefore } from "react-icons/md";
import { useEffect, useState } from "react";
import { HiArrowRight } from "react-icons/hi2";
import { pageAnimation } from "../../../../utils/pageTransition";

import {
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  FormMessage,
  FormLabel,
  Button,
  VehicleType,
} from "../../../composables";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  riderCompleteRegistrationValidationSchema,
  validateData,
} from "../../../../validations";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../../composables/toggleSwitch/toggleSwitch";
import { AnimatePresence, motion } from "framer-motion";
import {
  useGetUserProfile,
  useUpdateRiderVehicle,
  useUpdateSchedule,
} from "utils/api";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { errorLogger } from "utils/helper";
import { FaSpinner } from "react-icons/fa6";
import { Success } from "assets/icon";
const CompleteRegistration = () => {
  const navigate = useNavigate();
  useGetUserProfile();
  const client = useQueryClient();

  const weekDays = [
    {
      day: "sunday",
      tag: "S",
    },
    {
      day: "monday",
      tag: "M",
    },
    {
      day: "tuesday",
      tag: "T",
    },
    {
      day: "wednesday",
      tag: "W",
    },
    {
      day: "thursday",
      tag: "T",
    },
    {
      day: "friday",
      tag: "F",
    },
    {
      day: "saturday",
      tag: "S",
    },
  ];

  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [stage, setStage] = useState(1);
  const [isVehicleTypeOpen, setIsVehicleTypeOpen] = useState(false);
  const [isAllDay, setIsAllDay] = useState(false);
  const [timeFrame, setTimeFrame] = useState<{
    startTime: string;
    endTime: string;
    [key: string]: string;
  }>({ startTime: "", endTime: "" });

  function handleSelectedDay(day: string) {
    const selectedDaysClone = [...availableDays];
    if (availableDays.includes(day)) {
      const updatedData = selectedDaysClone.filter((v) => v !== day);
      setAvailableDays(updatedData);
    } else {
      setAvailableDays([...availableDays, day]);
    }
  }
  const form = useForm({
    defaultValues: {
      vehicleType: "",
      plateNumber: "",
      driverLicense: "",
    },
    resolver: yupResolver(riderCompleteRegistrationValidationSchema),
  });

  function handleStartTime(time: string) {
    setTimeFrame({ ...timeFrame, startTime: time });
    console.log(timeFrame);
  }
  function handleEndTime(time: string) {
    setTimeFrame({ ...timeFrame, endTime: time });
    console.log(timeFrame);
  }
  const { mutateAsync, isLoading } = useUpdateRiderVehicle();
  const { mutateAsync: mutateAsyncSchedule, isLoading: isScheduleLoading } =
    useUpdateSchedule();

  const handleSubmit = async () => {
    const payload = {
      licenseNumber: form.getValues("driverLicense"),
      vehicleNumberPlate: form.getValues("plateNumber"),
      vehicleType: form.getValues("vehicleType"),
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Vehicle Info Updated Successfully", { icon: Success });
        },
        onSettled: () => {
          client.refetchQueries("get_user");
          setStage(2);
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };
  async function handleScheduleSubmit() {
    const payload = availableDays.map((day) => {
      return { day: day, from: timeFrame.startTime, to: timeFrame.endTime };
    });
    console.log(validateData(payload));
    console.log(payload);
    if (validateData(payload)) {
      try {
        await mutateAsyncSchedule(payload, {
          onSuccess: () => {
            toast.success("Schedule Updated Successfully", {
              icon: Success,
            });
            navigate(-1);
          },
        });
      } catch (e: any) {
        errorLogger(e);
      }
    }
  }

  useEffect(() => {
    if (isAllDay) {
      // eslint-disable-next-line array-callback-return
      let allDay: string[] = [];
      weekDays.map((day) => {
        allDay.push(day.day);
      });
      setAvailableDays(allDay);
    } else {
      setAvailableDays([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllDay]);

  return (
    <div>
      <motion.div
        variants={pageAnimation}
        initial={"hidden"}
        animate={"visible"}
        exit={"exit"}
        className="w-full h-screen sm:max-w-[600px] mx-auto py-5 px-5"
      >
        <div className="">
          <Button
            onClick={() => {
              if (stage > 1) {
                setStage((curr) => curr - 1);
              } else {
                navigate(-1);
              }
            }}
            className="gap-x-1 bg- mb-5 -ml-2"
          >
            <MdNavigateBefore size={30} className="text-xl text-primary-100" />
            <span>Back</span>
          </Button>

          <h1 className="text-xl font-bold text-grey-900">
            Complete Registration
          </h1>
          <h2 className="mt-1 text-grey-200 text-sm max-w-[400px]">
            Finalize registration to start receiving orders and making money
          </h2>

          {/* form stage indicator */}
          <div className="mt-5 p-2 flex gap-x-5 bg-grey-30 rounded-full">
            <div className="h-full basis-0 bg-primary-200 grow text-center rounded-full py-2">
              Vehicle
            </div>
            <div
              className={`h-full basis-0 grow text-center rounded-full py-2 ${
                stage === 2 ? "bg-primary-200" : ""
              }`}
            >
              Schedule
            </div>
          </div>
        </div>

        {/* Form */}
        {stage === 1 && (
          <Form {...form}>
            <form
              // onSubmit={form.handleSubmit((data) => {
              //   handleSubmit(data);
              // })}
              className="mt-5"
            >
              <div>
                <FormField
                  control={form.control}
                  name="businessName"
                  render={({ field }) => (
                    <FormItem className="w-full mt-5">
                      <FormLabel>Business Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="ABC restaurant"
                          type="text"
                          className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="vehicleType"
                  render={({ field }) => (
                    <FormItem className="w-full mt-5">
                      <FormLabel>Vehicle Type</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Select Vehicle Type"
                          readOnly
                          type="text"
                          onFocus={() => {
                            setIsVehicleTypeOpen(true);
                          }}
                          className="w-full mt-2 bg-grey-30 rounded-lg"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <AnimatePresence initial={false} mode="wait">
                  {isVehicleTypeOpen && (
                    <VehicleType
                      closeVehicleType={() => {
                        setIsVehicleTypeOpen((prev) => !prev);
                      }}
                      selectVehicleType={(value) => {
                        form.setValue("vehicleType", value);
                      }}
                    />
                  )}
                </AnimatePresence>

                <FormField
                  control={form.control}
                  name="plateNumber"
                  render={({ field }) => (
                    <FormItem className="w-full mt-5">
                      <FormLabel className="text-xs">Plate Number</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="ABC123456"
                          type="text"
                          className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="driverLicense"
                  render={({ field }) => (
                    <FormItem className="w-full mt-5">
                      <FormLabel className="text-xs">
                        Driver's License
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder="123456789"
                          type="text"
                          className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* todo: upload vehicle picture */}

                <div className="my-10 flex flex-col items-center">
                  <Button
                    type="button"
                    onClick={async (e) => {
                      // setStage(2);
                      e.preventDefault();
                      handleSubmit();
                    }}
                    className="w-full outline-none focus:border-primary-200 border border-transparent py-2 px-3 rounded-xl bg-primary-100 text-white"
                  >
                    {isLoading ? (
                      <FaSpinner size={20} className="animate-spin" />
                    ) : (
                      "Continue"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        )}
        {stage === 2 && (
          // stage two
          <div>
            <div className="mt-10">
              <div className="flex justify-between items-center">
                <label>Schedule</label>
                <div className="border-l flex items-center">
                  <span className="mx-8">All-Day</span>
                  <ToggleSwitch
                    onChange={(value) => {
                      setIsAllDay(value);
                    }}
                  />
                </div>
              </div>
              {/* pick a day */}
              <div className="mt-8">
                <label>Pick a Day</label>
                <div className="mt-3 flex justify-between">
                  {weekDays.map((day) => (
                    <div
                      key={day.day}
                      className={`px-3 py-2 bg-grey-30 rounded-lg  ${
                        availableDays.includes(day.day) &&
                        "!text-white bg-primary-100"
                      }`}
                      data-value={day.day}
                      onClick={() => {
                        handleSelectedDay(day.day);
                      }}
                    >
                      {day.tag}
                    </div>
                  ))}
                </div>
              </div>
              {/* work time */}
              <div className="mt-8">
                <label>Work Time</label>
                <div className="mt-3 flex justify-between items-center">
                  <div className="w-2/5 px-3 bg-grey-30 rounded-lg flex items-center gap-10 border border-transparent focus-within:border-primary-200">
                    <input
                      type="time"
                      className=" w-full pr-3 py-2.5 bg-grey-30 rounded-lg outline-none border-none focus:outline-none focus:border-none"
                      defaultValue="12:00"
                      onChange={(e) => handleStartTime(e.target.value)}
                    />
                  </div>
                  <HiArrowRight />
                  <div className="w-2/5 px-3 bg-grey-30 rounded-lg flex items-center gap-x-10 border border-transparent focus-within:border-primary-200">
                    <input
                      type="time"
                      className="pr-3 py-2.5 bg-grey-30 rounded-lg outline-none border-none focus:outline-none focus:border-none w-full"
                      defaultValue="12:00"
                      onChange={(e) => {
                        handleEndTime(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                className="w-full mt-10 h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
                onClick={() => {
                  handleScheduleSubmit();
                  // navigate("/rider/home")
                }}
              >
                {isScheduleLoading ? (
                  <FaSpinner size={20} className="animate-spin" />
                ) : (
                  "Complete Registration"
                )}
              </Button>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default CompleteRegistration;
