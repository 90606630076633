import { SlCalender } from "react-icons/sl";
import coin from "../../../assets/Svg/coin.svg";
import Navbar from "../Navbar";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useFetchMyPayout } from "utils/api";
import moment from "moment";
import { PayoutItemsEntity } from "types/global.interface";
import { digitFormatter, getNairaEquivalent } from "utils/helper";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/composables/selectcn/Select";


const Balance = () => {
  const navigate = useNavigate();
  const { data } = useFetchMyPayout();

  return (
    <main className="mx-auto px-5 pb-24">
      {/* header */}
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Balance</h1>
      </div>

      {/* select timeline */}

      <Select>
        <SelectTrigger className="mt-4 flex py-2 pl-3 items-center gap-2.5 border border-grey-50 focus-within:border-grey-300 rounded-lg max-w-[160px]">
          <SlCalender />
          <SelectValue placeholder="Today" />
        </SelectTrigger>
        <SelectContent className="bg-white rounded-lg">
          <SelectItem className="text-sm py-2.5 border-b" value="today">
            Today
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="yesterday ">
            Yesterday
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="this_week">
            This Week
          </SelectItem>
          <SelectItem className="text-sm py-2.5" value="this_month">
            This Month
          </SelectItem>
        </SelectContent>
      </Select>

      {/* Current Balance */}
      <div className="relative mt-4 rounded-2xl p-4 bg-[#f4f4f4]">
        <h1 className="text-xs text-[#6A6A6A]">Current Balance</h1>
        <h2 className="mt-2 text-3xl font-bold">₦0.00</h2>
        <img className="absolute right-0 top-0" src={coin} alt="" />
      </div>

      {/* Settled Amount */}
      <div className="relative mt-4 rounded-2xl p-4 bg-[#f4f4f4]">
        <h1 className="text-xs text-[#6A6A6A]">Settled Amount</h1>
        <h2 className="mt-2 text-3xl font-bold">₦0.00</h2>
      </div>

      {/* Total Amount */}
      <div className="relative mt-4 rounded-2xl p-4 bg-[#FFEAC0]">
        <h1 className="text-xs text-[#6A6A6A]">Tota; Amount</h1>
        <h2 className="mt-2 text-3xl font-bold">₦0.00</h2>
      </div>

      {/* transaction history */}
      <div>
        <h1 className="mt-5 font-semibold text-xl">Transaction History</h1>

        {(data?.items ?? []).map((item: PayoutItemsEntity, key: number) => (
          <div
            key={key}
            className="py-4 flex items-center justify-between border-b border-b-[#F4F4F4]"
          >
            <div>
              <p>You Received</p>
              <p className="text-[#999999] text-xs">
                {moment(item?.initiatedAt).format("lll")}
              </p>
            </div>
            <div className="font-bold text-lg">
              ₦{digitFormatter(getNairaEquivalent(item.amount ?? 0))}
            </div>
          </div>
        ))}
      </div>

      <Navbar />
    </main>
  );
};

export { Balance };
