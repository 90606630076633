
import { TransactionHistory, WalletBalance } from "./_components";
import { Layout } from "../Layout/Layout";

function Wallet() {
  return (
    <Layout>
      <div className="w-full flex flex-col gap-y-6 pb-20 items-start justify-start">
        {/* <Button className="px-0 w-fit h-fit gap-x-2">
          <FaArrowLeft className="text-[22px]" />
          <h2 className="font-semibold text-base">Wallet</h2>
        </Button> */}

        <WalletBalance />

        <TransactionHistory />
      </div>
    </Layout>
  );
}

export default Wallet;
