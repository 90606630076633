export const AboutVendorSkeleton = () => (
  <div className="w-full flex items-center justify-center flex-col gap-y-2 animate-pulse">
    <div className="h-6 bg-gray-200 rounded w-1/3"></div>
    <div className="h-6 bg-gray-200 rounded w-1/2"></div>

    <div className="bg-gray-200 w-1/3 rounded-3xl px-3 py-2 text-xs mt-3"></div>

    <div className="flex items-center text-xs justify-around w-full">
      <div className="flex font-light items-center gap-x-1">
        <div className="h-6 bg-gray-200 rounded w-12"></div>
      </div>

      <div className="flex items-center gap-x-2">
        <div className="h-6 bg-gray-200 rounded w-12"></div>
      </div>

      <div className="flex items-center gap-x-2">
        <div className="h-6 bg-gray-200 rounded w-12"></div>
      </div>
    </div>
  </div>
);
