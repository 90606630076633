import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";

type CheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const Checkbox = ({ checked, onChange }: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked || false); // Handle initial checked state

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    onChange && onChange(e.target.checked); // Call onChange prop if provided
  };

  return (
    <div className="checkbox-container">
      <input type="checkbox" checked={isChecked} onChange={handleChange} />
      <span className="checkmark text-white">
        <FaCheck />
      </span>
    </div>
  );
};

export default Checkbox;
