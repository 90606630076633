import React from "react";

import friendly from "../../assets/Svg/friendly.svg";
import earning from "../../assets/Svg/earning.svg";
import ordermanagement from "../../assets/Svg/order_man.svg";
import rider from "../../assets/images/rider.png";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

function Delivery() {
  return (
    <div className="py-10 md:px-[100px] px-6">
      <div className="mt-14 grid md:grid-cols-2 gap-10">
        <div className="mt-10 flex flex-col space-y-8">
          <div className="text-[#464255] font-bold md:text-5xl text-3xl max-w-[600px]">
            Deliver Delight to <span className="jkt-underline"> Doorsteps</span>{" "}
            with Us.
          </div>
          <div className="font-normal   text-[#46425580]">
            Are you a reliable, motivated rider looking for flexible earning
            opportunities? Partner with us to bring smiles to customers' faces
            through timely and efficient deliveries.
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-center space-x-4">
              <img src={friendly} alt="" />
              <h2>User-Friendly App</h2>
            </div>
            <div className="flex items-center space-x-4">
              <img src={earning} alt="" />
              <h2>Competitive Earnings</h2>
            </div>
            <div className="flex items-center space-x-4">
              <img src={ordermanagement} alt="" />
              <h2>Flexible Scheduling</h2>
            </div>
          </div>
          <div className="mt-4">
            <Link
              className="text-white w-max flex space-x-3 items-center bg-primary-100 rounded-md md:px-8 px-4 md:py-3 py-2"
              to="/rider/signup"
            >
              <span>Get Started</span>
              <BsArrowRight />
            </Link>
          </div>
        </div>
        <div className="md:order-last order-first justify-self-end">
          <img src={rider} className="border-md md:w-[500px]" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Delivery;
