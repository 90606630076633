import { BottomNav, TopBar } from "../_component";
import { useState } from "react";
import ChangeLocation from "../_component/changeLocation/ChangeLocation";
export function Layout({
  children,
  hasTopBar,
  isSearchPage,
}: {
  children: React.ReactNode;
  hasTopBar?: boolean;
  isSearchPage?: boolean;
}) {
  const [isLocation, setLocation] = useState(false);

  function toggleLocation() {
    setLocation((prev) => !prev);
  }

  return (
    <>
      <div className="w-full ">
        {hasTopBar && (
          <TopBar toggleLocation={toggleLocation} isSearchPage={isSearchPage} />
        )}
        <div className="py-8 px-5">
          {children}
        </div>
        <BottomNav />
      </div>

      {isLocation && <ChangeLocation toggleLocation={toggleLocation} />}
    </>
  );
}
