import { useState, useEffect } from "react";
import homelogo from "../../assets/Svg/homelogo.svg";
import becomerider from "../../assets/Svg/becomerider.svg";
import hero_rider from "../../assets/images/hero_rider.png";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Navigation from "./Navigation";

import AOS from "aos";
import "aos/dist/aos.css";
function Hero() {
  const [isNavOpen, setisNavOpen] = useState(false);
  useEffect(() => {
    AOS.init();
  }, []);

  function onClose() {
    setisNavOpen((prev) => !prev);
  }
  return (
    <div className="hero_bg md:px-[100px] px-6">
      <div
        title="header"
        className="flex justify-between items-center text-[#464255]"
      >
        <div>
          <img
            src={homelogo}
            alt="logo"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="md:flex items-center space-x-8 hidden">
          <a href="#restaurant">Featured Restaurant</a>
          <a href="#service">Service</a>
          <a href="#download">Download App</a>
        </div>

        <div className="md:flex items-center space-x-8 hidden">
          <Link to="/auth/signin">Sign in</Link>
          <Link
            to={"/get-started/choose-user-type"}
            target="_blank"
            rel="noreferrer"
            className="text-white w-max flex space-x-3 items-center bg-primary-100 rounded-md md:px-8 px-4 md:py-3 py-2"
          >
            Sign Up
          </Link>
        </div>

        <button
          onClick={() => {
            setisNavOpen(!isNavOpen);
          }}
          className="md:hidden block"
        >
          <GiHamburgerMenu size={24} />
        </button>
      </div>

      {isNavOpen && <Navigation isNavOpen={isNavOpen} close={onClose} />}

      <div className=" text-[#464255] grid md:grid-cols-2 gap-6 items-center my-12">
        <div
          className="flex flex-col space-y-6"
          data-aos="fade-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <div className="">
            <Link
              to={"/get-started/choose-user-type"}
              className="w-max md:p-2 p-1 flex items-center space-x-2 bg-[#7BBE4A15] rounded-full border border-primary-100"
            >
              <span className="font-semibold md:text-base text-[10px] text-primary-100">
                Become a Rider
              </span>
              <img
                src={becomerider}
                alt="become_rider"
                className="md:w-6 md:h-6  w-3 h-3"
              />
            </Link>
          </div>
          <div className="font-bold md:text-5xl text-3xl max-w-[500px]">
            Delicious Moments Delivered to Your
            <span className="jkt-underline"> Doorstep.</span>
          </div>
          <div className="font-normal mt-4  text-[#46425580]">
            Hungry? Don't worry, we've got you covered! Welcome to Jekaeat,
            where culinary delights meet convenience. Indulge in a feast of
            flavors from the comfort of your own space.
          </div>

          <div className="flex space-x-4">
            <Link
              to={"/get-started/choose-user-type"}
              target="_self"
              rel="noreferrer"
              className="text-white w-max flex space-x-3 items-center bg-primary-100 rounded-md md:px-8 px-4 md:py-3 py-2"
            >
              Become a vendor
            </Link>
          </div>
        </div>
        <div
          className="transform  md:order-last order-first"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img
            src={hero_rider}
            alt="hero_rider"
            className="md:w-[550px] md:h-[433px] w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
