import { getNairaEquivalent } from "utils/helper";

type FoodlistItemProp = {
  img: any;
  name: string;
  price: number;
  qty: number;
};

const FoodlistItem = ({ img, name, price, qty }: FoodlistItemProp) => {
  return (
    <div className="flex justify-between items-start">
      <div className="flex gap-4">
        <div
          className="w-14 h-14 rounded-lg bg-center bg-cover bg-no-repeat bg-grey-100"
          style={{
            backgroundImage: `url(${img})`,
          }}
        ></div>
        <div className="max-w-[200px]">
          <h4 className="text-xs text-[#212121]">{name}</h4>
          <h5 className="font-semibold text-base">₦{getNairaEquivalent(price)} X {qty}</h5>
        </div>
      </div>

      {/* checkbox */}
      <input
        className="accent-primary-100"
        type="checkbox"
        name="foodItem"
        id=""
      />
    </div>
  );
};

export default FoodlistItem;
