import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import Onboarding1 from "./Onboarding1";
import Onboarding2 from "./Onboarding2";
import Onboarding3 from "./Onboarding3";
import Onboarding4 from "./Onboarding4";
import Onboarding5 from "./Onboarding5";
import { useSwipeable } from "react-swipeable";

const OnboardingIndexPage = () => {

  useEffect(() => {
    localStorage.setItem('isOnboard', 'true')
  }, [])
  const [currentPage, setCurrentPage] = useState<number>(0);

  // const handleNext = () => {
  //     setCurrentPage((prevPage) => Math.min(prevPage + 1, 3));
  // };

  // const handlePrev = () => {
  //     setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  // };

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentPage((prevPage) => Math.min(prevPage + 1, 3)),
    onSwipedRight: () =>
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0)),
    // preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const onboardingScreens = [
    Onboarding1,
    Onboarding2,
    Onboarding3,
    Onboarding4,
    Onboarding5,
  ];

  return (
    <div className="h-screen flex flex-col justify-between overflow-y-auto">
      <AnimatePresence initial={false} mode={"wait"}>
        <div {...handlers}>
          {onboardingScreens.map(
            (Screen, key) => currentPage === key && <Screen key={key} />
          )}

        </div>
      </AnimatePresence>
      <div className="w-full sm:max-w-[600px] mx-auto px-5 pb-5 flex flex-col justify-between items-center">
        <Link
          to="/get-started/choose-user-type"
          className="w-full flex justify-center py-3 px-3  rounded-lg bg-primary-100 text-white"
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default OnboardingIndexPage;
