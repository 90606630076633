import { combineReducers, Reducer } from "redux";
import User, { UserState } from "./user";
import Cart, { CartState } from "./cart";

export interface RootState  {
  user: UserState;
  cart : CartState
};

export type RootAction = {
  type: string;
  payload?: any;
};
const appReducer: Reducer<RootState, RootAction> = combineReducers({
  user: User,
  cart: Cart
});

const rootReducer: Reducer<RootState, RootAction> = (state, action) => {
  if (action.type === "LOGOUT") {
    // storage.removeItem('persist:otherKey')
    localStorage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
