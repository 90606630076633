import { Button } from "components/composables";
import { AiOutlinePlus } from "react-icons/ai";
import walletStrokeA from "../../../../../assets/Svg/walletcardstrokeA.svg";
import walletStrokeB from "../../../../../assets/Svg/walletcardstrokeB.svg";
import { useState } from "react";
import TopUp from "../../TopUp";
import { useGetUserWalletBalance } from "utils/api";
import { digitFormatter, getNairaEquivalent } from "utils/helper";
export function WalletBalance() {

  const [isTopUp, showTopUp] = useState(false);

  const { data: walletBalance, isLoading } = useGetUserWalletBalance();

  return (
    <>
      <div className="w-full flex items-center overflow-hidden justify-center px-6 rounded-2xl relative h-32 wallet-card-bal-gradient">
        <div className="flex w-full items-center relative z-20 justify-between">
          <div className="flex flex-col gap-y-1 items-start justify-start">
            <p className="text-[#578735]">Available Balance</p>
            <p className="font-semibold text-xl text-[#446929]">
              {isLoading ? '₦0.00' : `₦${digitFormatter(getNairaEquivalent(walletBalance ?? 0))}`}
            </p>
          </div>
          <Button
            onClick={() => showTopUp(!isTopUp)}
            className="bg-[#7BBE4A] text-white h-9 w-10 rounded-lg px-0"
          >
            <AiOutlinePlus className="text-[22px]" />
          </Button>
        </div>
        <img
          src={walletStrokeA}
          alt=""
          className="absolute right-0 -bottom-0"
        />
        <img src={walletStrokeB} alt="" className="absolute right-0 -top-0" />
      </div>

      {isTopUp && <TopUp close={() => showTopUp(!isTopUp)} />}
    </>
  );
}
