import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  return (
    <main className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">About</h1>
      </div>

      <div className="mt-5 text-xs">
        <p>
          Welcome to <span className="font-semibold">JekaEat</span>, where
          culinary convenience meets your cravings! Our mission is to redefine
          the way you experience food by bringing your favorite meals from local
          restaurants straight to your doorstep. Here's what sets us apart:
        </p>
        <ul className="mt-5 ml-3 list-disc list-item">
          <li>
            <h2 className="font-semibold">A Feast of Choices:</h2>
            <p>
              Explore a diverse array of cuisines and dishes from the best local
              restaurants in your area. Whether you're in the mood for comfort
              food, international flavors, or healthy options, we've got
              something to satisfy every palate.
            </p>
          </li>
          <li className="mt-3">
            <h2 className="font-semibold">Effortless Ordering:</h2>
            <p>
              Our user-friendly app ensures a seamless ordering experience.
              Simply browse through menus, customize your order, and with a few
              taps, your culinary journey is set in motion. Say goodbye to long
              queues and hello to the ease of ordering from the comfort of your
              home.
            </p>
          </li>
          <li className="mt-3">
            <h2 className="font-semibold">Swift and Reliable Delivery:</h2>
            <p>
              We understand the importance of timely deliveries. Our dedicated
              fleet of delivery partners is committed to bringing your food to
              you with speed and reliability. Track your order in real-time and
              anticipate the moment your meal arrives.
            </p>
          </li>
          <li className="mt-3">
            <h2 className="font-semibold">Flexible Payment Options:</h2>
            <p>
              Paying for your favorite meals is as flexible as your taste
              preferences. Choose from various payment methods, including
              credit/debit cards, digital wallets, or the ease of cash on
              delivery.
            </p>
          </li>
          <li className="mt-3">
            <h2 className="font-semibold">Safety First:</h2>
            <p>
              Your well-being is our priority. Rest assured that we adhere to
              the highest standards of hygiene and safety, both in food
              preparation and delivery. Enjoy your meal with confidence.
            </p>
          </li>
          <li className="mt-3">
            <h2 className="font-semibold">Community-Centric Approach:</h2>
            <p>
              We are more than just a food delivery service; we are an integral
              part of your community. By supporting local restaurants and
              businesses, we contribute to the vibrant tapestry of flavors that
              make your neighborhood unique.
            </p>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default About;
