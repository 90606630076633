/* eslint-disable react-hooks/exhaustive-deps */
import { VendorMealCard } from "../vendormealCard/VendorMealCard";
import { Button } from "../../../../composables";
import { cn } from "../../../../../lib";
import { useEffect, useState } from "react";
import { MealItemsEntity } from "types/global.interface";
export function SideMeal({ meals }: { meals: MealItemsEntity[] | undefined }) {
  const sideMealType = ["all", "protein", "drinks", "desserts"];
  const [active, setActive] = useState("all");
  const [proteins, setProteins] = useState<MealItemsEntity[] | undefined>([]);
  const [desserts, setDesserts] = useState<MealItemsEntity[] | undefined>([]);
  const [drinks, setDrinks] = useState<MealItemsEntity[] | undefined>([]);
  const [mealArray, setMealArray] = useState<MealItemsEntity[] | undefined>(
    meals
  );

  useEffect(() => {
    if (meals) {
      setProteins(meals?.filter((v) => v.tags?.includes("proteins")));
      setDrinks(meals?.filter((v) => v.tags?.includes("drinks")));
      setDesserts(meals?.filter((v) => v.tags?.includes("desserts")));
    }
    if (active === "all") {
      setMealArray(meals);
    } else if (active === "protein") {
      setMealArray(proteins);
    } else if (active === "drinks") {
      setMealArray(drinks);
    } else {
      setMealArray(desserts);
    }
  }, [meals, active]);

  return (
    <div className="w-full h-full">
      <div className="flex items-center gap-x-3 py-2">
        {sideMealType.map((meal, index) => (
          <Button
            onClick={() => setActive(meal)}
            key={index}
            className={cn(
              "rounded-3xl px-4 h-9  bg-gray-200 text-xs capitalize",
              active === meal && "bg-[#7bbe4a] text-white"
            )}
          >
            {meal}
          </Button>
        ))}
      </div>

      <div className="flex w-full flex-col gap-y-3">
        {mealArray?.map((meal, key) => (
          <VendorMealCard meal={meal} key={key} isFoodStack />
        ))}
      </div>
    </div>
  );
}
