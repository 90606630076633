import { useEffect, useState, useCallback } from "react";
import { usePaystackPayment } from "react-paystack";
import { calculateTotalPrice } from "paystack-transaction-charges-to-cus";
import { useSelector } from "react-redux";
import { errorLogger, getKoboEquivalent } from "./helper";
import { useGeneratePaymentToken } from "./api";

export interface IPaymentConfig {
  reference: string;
  email: string;
  amount: number;
  metadata: { token: string; paymentFor: string };
  publicKey: string;
}

export const usePaymentHandler = (
  orderPayload: any,
  amount: number,
  paymentFor: "order" | "wallet",
  onSuccess: (v: any) => void
) => {
  const { currentUser } = useSelector((state: any) => state.user);

  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<IPaymentConfig>();

  const paymentReference = new Date().getTime().toString();

  const { mutateAsync } = useGeneratePaymentToken(paymentFor);

  // Wrap the initiatePayment function in useCallback to ensure it's stable
  const initiatePayment = useCallback(async () => {
    setLoading(true);
    try {
      const res = await mutateAsync(orderPayload);
      const token = res.data.data;
      const paystackConfig = {
        reference: paymentReference,
        email: currentUser.email,
        amount:
          paymentFor === "order" //paystack charges has been charged from the ordering checkout
            ? getKoboEquivalent(Math.ceil(amount))
            : getKoboEquivalent(Math.ceil(calculateTotalPrice(amount))),
        metadata: { token: token, paymentFor },
        publicKey: process.env.REACT_APP_PAYSTACK_PK as string,
      };
      setConfig(paystackConfig);
    } catch (error) {
      console.log(error);
      errorLogger(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPayload, amount]);

  // Initialize Paystack payment
  const initializePayment = usePaystackPayment(config as any);

  // Function to manually trigger the payment process
  const triggerPayment = () => {
    initiatePayment();
  };

  useEffect(() => {
    if (config) {
      initializePayment({
        onSuccess: (ref) => {
          onSuccess(ref);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return {
    loading,
    triggerPayment, // Return the function to manually trigger the payment process
  };
};
