import { Link } from "react-router-dom";
import logo from "../../../assets/Svg/homelogo.svg";
import { useEffect, useState } from "react";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { AnimatePresence } from "framer-motion";
import MobileNav from "./MobileNav";
import { X } from "lucide-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.maxHeight = "100dvh";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  return (
    <nav className="border-b border-b-[#E8E8E8]">
      <div className="container mx-auto flex items-center justify-between py-2.5 px-5 lg:px-10">
        <div className="md:flex items-center gap-5 hidden ">
          <Link className="hover:text-primary-100" to={"/"}>
            Company
          </Link>
          <Link className="hover:text-primary-100" to={"/vendors"}>
            Vendors
          </Link>
          <Link className="hover:text-primary-100" to={"/riders"}>
            Riders
          </Link>
          <Link className="hover:text-primary-100" to={"/newsletter"}>
            NewsLetter
          </Link>
        </div>{" "}
        <Link to={"/"}>
          <img className="w-16 -translate-y-1.5" src={logo} alt="" />
        </Link>
        <div className="md:flex items-center hidden">
          <Link
            className="rounded-full w-32 flex items-center justify-center text-primary-100 hover:border hover:border-primary-500 hover:text-primary-500 duration-200 transition-all h-10"
            to={"/auth/signin"}          >
            Sign In
          </Link>
          <Link
            className="rounded-full w-32 flex items-center justify-center bg-primary-100 hover:bg-primary-500 transition-all duration-200 text-white h-10"
            to={"/"}
          >
            Sign Up
          </Link>
        </div>
        <button className="lg:hidden" onClick={() => toggleMenu()}>
          {isOpen ? <X size={30} /> : <HamburgerMenuIcon className="size-8" />}
        </button>
      </div>
      <AnimatePresence>{isOpen && <MobileNav />}</AnimatePresence>
    </nav>
  );
};

export default NavBar;
