import { IUserOrder, ItemsEntity } from "types/global.interface";
import OrderCard from "./OrderCard";
import { OrderCardSkeleton } from '../../composables/Skeletons/OrderCard';

const NewOrders = ({ order, isLoading }: { order: IUserOrder | any, isLoading: boolean }) => {
  console.log(order);

  return (
    <div className="pt-5 flex flex-col gap-4">
      {
        isLoading ? <OrderCardSkeleton /> :
          <div>
            {order.items?.map((v: ItemsEntity, key: number) => (
              <OrderCard order={v} key={key} />
            ))}
          </div>
      }
    </div>
  );
};


export default NewOrders;
