import homelogo from "../../assets/Svg/homelogo.svg";
import facebook from "../../assets/Svg/facebook.svg";
import twitter from "../../assets/Svg/twitter.svg";
import instagram from "../../assets/Svg/instagram.svg";
import linkedin from "../../assets/Svg/linkedin.svg";

function Footer() {
  return (
    <footer className="md:px-[100px] px-6  text-sm">
      <div className="flex flex-col justify-center space-y-10 items-center">
        <div>
          <img src={homelogo} alt="" />
        </div>
        <div className="flex items-center space-x-8">
          <a href="/">Home</a>
          <a href="#service">Service</a>
          <a href="#download">Download App</a>
        </div>
        <div className="flex items-center space-x-8">
          <a href="https://www.facebook.com/Jekaeat/">
            <img src={facebook} alt="" />
          </a>
          <a href="https://www.instagram.com/jekaeat/">
            <img src={instagram} alt="" />
          </a>
          <a href="https://twitter.com/Jekaeat2">
            <img src={twitter} alt="" />
          </a>
          <a href="https://www.linkedin.com/company/jekaeat/">
            <img src={linkedin} alt="" />
          </a>
        </div>

        <div className="border-t border-primary-100 w-full" />
        <div className="mt-8  pb-8 text-xs">
          <h1>Copyright Jekaeat</h1>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
