import { VendorStatSkeleton } from "components/composables/Skeletons/vendorstat";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/composables/selectcn/Select";
import { ArrowLeft } from "iconsax-react";
import { SlCalender } from "react-icons/sl";
import { useLocation, useNavigate } from "react-router-dom";
import { ChartEntity } from "types/global.interface";
import { useFetchVendorStat } from "utils/api";

const Insight = () => {
  const navigate = useNavigate();
  const { data: orderStat, isLoading } = useFetchVendorStat();
  // get the highest round up value for the chart
  const roundUpOrder = (data: ChartEntity[]) => {
    return Math.ceil(Math.max(...data.map((data) => data.count)) / 50) * 50;
  };

  // calculate the percentage of the round value
  const calcPercentage = (value: number) => {
    console.log(value, roundUpOrder(orderStat?.chart ?? []));
    return (value / roundUpOrder(orderStat?.chart ?? [])) * 100;
  };
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const totalOrderCount = queryParam.get("totalOrderCount");

  return (
    <main className="mx-auto px-5 pb-24">
      {/* header */}
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Insight</h1>
      </div>

      {/* select timeline */}

      <Select>
        <SelectTrigger className="mt-4 flex py-2 pl-3 items-center gap-2.5 border border-grey-50 focus-within:border-grey-300 rounded-lg max-w-[160px]">
          <SlCalender />
          <SelectValue placeholder="Today" />
        </SelectTrigger>
        <SelectContent className="bg-white rounded-lg">
          <SelectItem className="text-sm py-2.5 border-b" value="today">
            Today
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="yesterday ">
            Yesterday
          </SelectItem>
          <SelectItem className="text-sm py-2.5 border-b" value="this_week">
            This Week
          </SelectItem>
          <SelectItem className="text-sm py-2.5" value="this_month">
            This Month
          </SelectItem>
        </SelectContent>
      </Select>

      {/* total order */}
      {isLoading ? (
        <VendorStatSkeleton />
      ) : (
        <div className="mt-5">
          <div className="text-[#6A6A6A] text-xs">Total Order</div>
          <div className="mt-2 text-[#0E0E0E] text-2xl font-bold">
            {totalOrderCount ?? 0}
          </div>
          <div className="mt-5 pb-10 border-b-[3px] border-b-[#E9E9E9] px-2 flex justify-between items-center h-72">
            {(orderStat?.chart ?? []).map((data, index) => (
              <div
                key={index}
                className="h-full flex flex-col item-center gap-2"
              >
                <div
                  title={`${data.count}`}
                  className="w-6 h-full rounded-full overflow-hidden flex flex-col justify-end bg-[#F4F4F4]"
                >
                  <div
                    className={`w-full bg-[#33D374] rounded-full`}
                    style={{ height: `${calcPercentage(data.count)}%` }}
                  ></div>
                </div>
                <div className="text-[#0E0E0E]">{data.day.slice(0, 3)}</div>
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center py-4 border-b border-b-[#E9E9E9]">
            <span className="text-[#6A6A6A]">Peak Order</span>
            <span className="text-[#0E0E0E]">
              {orderStat?.intensity?.peak ?? ""}
            </span>
          </div>
          <div className="flex justify-between items-center py-4 border-b border-b-[#E9E9E9]">
            <span className="text-[#6A6A6A]">Most Ordered Meal</span>
            <span className="text-[#0E0E0E]">
              {orderStat?.intensity?.most?.name ?? ""}
            </span>
          </div>
          <div className="flex justify-between items-center py-4 border-b border-b-[#E9E9E9]">
            <span className="text-[#6A6A6A]">Least Ordered Meal</span>
            <span className="text-[#0E0E0E]">
              {orderStat?.intensity?.least?.name ?? ""}
            </span>
          </div>
        </div>
      )}
    </main>
  );
};

export { Insight };
