import jollof from "../../../assets/images/jollof.png";
import { ArrowLeft, GalleryImport } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "../../composables";
import { useForm } from "react-hook-form";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import DeleteFoodModal from "./DeleteFoodModal";
import { ItemCategory } from "components/composables/itemsCategory/ItemsCategory";

const EditMenu = () => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [category, setCategory] = useState<string>();
  const [mealImage] = useState(jollof);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      foodName: "Rice and Beans",
      price: "1500",
      category: "Food Stack",
      description:
        "Party Jollof Rice with Turkey, plantain, smoke fish, spaghetti plus full sized Pepsi drink served and hot for your satisfaction",
      mealImage: "",
    },
  });

  useEffect(() => {
    form.setValue("category", category || "");
  }, [category, form]);

  const selectCategory = (value: string) => {
    setCategory(value);
  };
  return (
    <main className="px-5 min-h-screen">
      {/* header */}
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Edit Menu</h1>
      </div>

      {/* edit form */}
      <Form {...form}>
        <form className="mt-5">
          <div className="flex flex-col gap-4">
            {/*  */}
            <FormField
              control={form.control}
              name="foodName"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">Food Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Rice and Beans"
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Price */}
            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">Price</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="1500"
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Category */}
            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Category</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Choose Food Category"
                      readOnly
                      type="text"
                      onFocus={() => {
                        setIsCategoryOpen(true);
                      }}
                      className="w-full mt-2 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <AnimatePresence initial={false} mode="wait">
              {isCategoryOpen && (
                <ItemCategory
                  closeCategory={() => {
                    setIsCategoryOpen(false);
                  }}
                  selectCategory={selectCategory}
                ></ItemCategory>
              )}
            </AnimatePresence>

            {/* Description */}
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">
                    Description (optional)
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Tell user about the food"
                      type="text"
                      multiple
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* upload file */}
            <FormField
              control={form.control}
              name="mealImage"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">
                    Upload Picture
                  </FormLabel>
                  <FormControl>
                    <>
                      <label
                        className="mt-2 flex justify-center py-14 bg-[#00000099] rounded-2xl bg-center bg-cover bg-blend-overlay"
                        htmlFor="mealImage"
                        style={{ backgroundImage: `url(${mealImage})` }}
                      >
                        <GalleryImport color="white" />
                      </label>
                      <Input
                        id="mealImage"
                        type="file"
                        className="w-[0.1px] h-[0.1px] p-0 m-0"
                        {...field}
                      />
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="mt-10 flex flex-col gap-4">
            <Button
              type="submit"
              className="w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
            >
              Save
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setIsDeleteModalOpen(true);
              }}
              className="w-full h-12 py-2 px-3 rounded-xl border border-[#FF4444] text-[#FF4444]"
            >
              Delete
            </Button>
          </div>
        </form>
      </Form>

      <AnimatePresence mode="wait" initial={false}>
        {isDeleteModalOpen ? (
          <DeleteFoodModal
            closeModal={() => {
              setIsDeleteModalOpen(false);
            }}
            handleDelete={() => {
              console.log("deleted");
              setIsDeleteModalOpen(false);
            }}
          />
        ) : null}
      </AnimatePresence>
    </main>
  );
};

export { EditMenu };
