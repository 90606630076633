import Select from "react-select";
import {
  UseControllerProps,
  UseControllerReturn,
  useController,
  FieldValues,
} from "react-hook-form";

function ErrorText({ children }: { children?: string }) {
  return (
    <div>
      {children && <p className="pt-1 text-xs text-red-500 ">{children}</p>}
    </div>
  );
}

export const ReactSelect = <T extends FieldValues>(
  prop: UseControllerProps<T> & {
    options: { value: string; label: string }[];
    error?: string;
    label?: string;
    placeHolder: string;
    isMulti?: boolean;
  }
) => {
  const { label, options, error, placeHolder, isMulti, ...controllerProps } =
    prop;
  const {
    field
  } = useController(controllerProps) as UseControllerReturn<T>;

  return (
    <div className="w-full flex flex-col gap-y-3 mt-3 items-start justify-start">
      <label className="font-medium " htmlFor="select">
        {label}
      </label>

      <Select
        placeholder={placeHolder}
        styles={{
            container: (baseStyles) => ({
                ...baseStyles,
                width:"100%"
            }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state?.isFocused ? "#f4f4f4" :"#f4f4f4",
            "&:hover": {
              borderColor: "#f4f4f4",
            },
            height: "2.5rem",
            width:"100%",
            backgroundColor: "#f4f4f4",
            boxShadow: "0px",
            borderRadius:"8px"
          }),

          option: (baseStyles, state) => ({
            ...baseStyles,
            textAlign: "start",
            color: state?.isSelected ? "black" : "black",
            backgroundColor: state?.isFocused ? "#e2e8f0" : "",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            textAlign: "start",
            textDecoration: "capitalize",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            textAlign: "start",
            color: "#888",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: "6px",
            zIndex: 100,
          }),
        }}
        options={options}
        onChange={(newValue) => field.onChange(newValue)}
      />
      <ErrorText>{error}</ErrorText>
    </div>
  );
};
