import { Slot } from "@radix-ui/react-slot";
import type { ButtonHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "../../../lib";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
  type?: "button" | "submit" | "reset";
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, type, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        {...props}
        type={type}
        ref={ref}
        className={cn(
          "rounded-md flex items-center justify-center px-0",
          className
        )}
      />
    );
  }
);

Button.displayName = "button";

export { Button };
