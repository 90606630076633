import { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AnimatedRoutes from "./Routes";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./react-toastify.css";
import { SearchFilterProvider } from "./context/searchContext";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const App: FC = () => {
  return (
    <div className="App text-sm sm:text-[15px] w-full mx-auto text-grey-900 py-4">
      <QueryClientProvider client={queryClient}>
        <Router>
          <SearchFilterProvider>
            <AnimatedRoutes />
          </SearchFilterProvider>

          <ToastContainer
            autoClose={2000}
            hideProgressBar
            theme="colored"
            position="top-center"
          />
        </Router>
      </QueryClientProvider>
    </div>
  );
};

export default App;
