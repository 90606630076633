import { useFetchFoodVendors } from "utils/api";
import { VendorCard } from "components/Customer/_component";
// import { IVendor } from "types/global.interface";

function Restaurant() {
  const { data, isLoading } = useFetchFoodVendors('restaurant');

  return (
    <div id="restaurant" className="py-5 md:px-[100px] px-6">
      <h2 className=" tracking-wider text-primary-100 font-normal text-center mb-3">
        Featured Restaurants
      </h2>
      <div className="flex flex-col justify-center items-center">
        <div className="max-w-[600px] mb-5 text-center md:text-4xl text-2xl font-semibold text-[#464255]">
          Discover Extraordinary Flavors at our Featured Restaurants.
        </div>
        <div className="w-full relative mt-5 mb-10">
          {!isLoading ? (
            <>
              {/* <button
                onClick={next}
                className={`absolute right-[-10px] top-[35%] z-[3]  bg-white rounded-full p-2 shadow-md ${
                  isnext ? "sm:block hidden" : "hidden"
                }`}
              >
                <MdNavigateNext className="text-xl text-[#333947]" />
              </button>
              <button
                onClick={prev}
                className={`absolute left-[-10px] top-[35%] z-[3] bg-white rounded-full p-2 shadow-md ${
                  isprev ? "sm:block hidden" : "hidden"
                }`}
              >
                <MdNavigateBefore className="text-xl text-[#333947]" />
              </button> */}
              <div className="w-full relative h-fit  mx-auto hide-scroll overflow-x-auto">
                <div className="w-full overflow-x-auto no-scrollbar">
                  <div className="min-w-max flex gap-2 ">
                    {data?.items?.map((v, key: number) => (
                      <VendorCard className="w-[300px]" key={key} item={v} />
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-center py-10">Loading....</div>
          )}
        </div>

        {/* <Button
          title="Explore All"
          link="/explore/restaurant"
          icon={<BsArrowRight />}
        /> */}
      </div>
    </div>
  );
}

export default Restaurant;
