import { MealItemsEntity } from "types/global.interface";
import MenuCard from "./MenuCard";

const MenuList = ({ meals }: { meals: MealItemsEntity[] | undefined }) => {
  return (
    <div className="flex flex-col gap-4">
      {meals?.map((meal, key) => (
        <MenuCard  meal={meal} key={key} />
      ))}
    </div>
  );
};

export default MenuList;
