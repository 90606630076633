import Ordersuccess from "assets/Svg/ordersuccess.svg";
import { Link } from "react-router-dom";


export function OrderSuccess() {
    return (
        <div className="bg-black/50 fixed inset-0 z-10 grid place-content-center">
            <div className="bg-white h-[505px] w-[319px] rounded-2xl p-10 gap-6 flex  text-center flex-col items-center justify-center">
                <img src={Ordersuccess} alt="success_order" />
                <h2 className="font-bold">Your order has been successfully placed</h2>
                <p className="text-[10px]">Sit and relax while your orders is being worked on.</p>
                <Link to={'/customer/orders'} className="text-white bg-primary-100 p-2 rounded-xl w-full"> Track Order </Link>
            </div>
        </div>
    );
}
