import { ArrowLeft, GalleryImport } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "../../composables";
import { useForm } from "react-hook-form";
import { AnimatePresence } from "framer-motion";
import { MenuCategory } from "../../composables/menuCategory/MenuCategory";
import { useEffect, useState } from "react";
import {
  RiCheckboxCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import { MealTag } from "../../composables/mealTag/MealTag";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";

import { Buffer } from "buffer";
import { FaSpinner } from "react-icons/fa6";
import { useFileUploadHandler } from "utils/fileUploader";
import { IMeal } from "../../../types/global.interface";
import { useAddMeal } from "utils/api";
import { useSelector } from "react-redux";
import { Success } from "assets/icon";
window.Buffer = Buffer;

export const AddMenu = () => {
  const { currentUser } = useSelector((state: any) => state.user);

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [category, setCategory] = useState<string>();
  const [readyIn, setReadyIn] = useState<string>();
  const [preOrderDays, setPreOrderDays] = useState<string[]>([]);
  const [timeFrame, setTimeFrame] = useState<{
    startTime: string;
    endTime: string;
    [key: string]: string;
  }>({ startTime: "", endTime: "" });

  const [isTagOpen, setIsTagOpen] = useState(false);
  const [mealOption, setMealOption] = useState<"main_meal" | "side_meal">();
  const navigate = useNavigate();
  const form = useForm();

  const time: string[] = [];

  for (let i = 0; i <= 23; i++) {
    const hour = i.toString().padStart(2, "0"); // Convert to string and pad with leading zeros if necessary
    const timeString = `${hour}:00`; // Append ":00" to each hour
    time.push(timeString);
  }
  const weekDays = [
    {
      day: "sunday",
      tag: "Sun",
    },
    {
      day: "monday",
      tag: "Mon",
    },
    {
      day: "tuesday",
      tag: "Tue",
    },
    {
      day: "wednesday",
      tag: "Wed",
    },
    {
      day: "thursday",
      tag: "Thu",
    },
    {
      day: "friday",
      tag: "Fri",
    },
    {
      day: "saturday",
      tag: "Sat",
    },
  ];

  const readyInTimes = [
    "10m",
    "20m",
    "30m",
    "1hr",
    "1hr 30m",
    "2hr",
    "2hr 30m",
    "3hr",
  ];

  function isTimeGreater(time1: string, time2: string) {
    // Convert the time strings to Date objects
    const date1 = new Date(`1970-01-01T${time1}:00`);
    const date2 = new Date(`1970-01-01T${time2}:00`);

    // Compare the hours and minutes
    return (
      date1.getHours() > date2.getHours() ||
      (date1.getHours() === date2.getHours() &&
        date1.getMinutes() > date2.getMinutes())
    );
  }

  function handleSelectedDay(day: string) {
    const selectedDaysClone = [...preOrderDays];
    if (preOrderDays.includes(day)) {
      const updatedData = selectedDaysClone.filter((v) => v !== day);
      setPreOrderDays(updatedData);
    } else {
      setPreOrderDays([...preOrderDays, day]);
    }
  }
  function handleTimeSelection(time: string) {
    if (timeFrame.startTime === "" && timeFrame.endTime === "") {
      setTimeFrame({ ...timeFrame, startTime: time });
    } else {
      if (isTimeGreater(time, timeFrame.startTime)) {
        setTimeFrame({ ...timeFrame, endTime: time });
      } else {
        setTimeFrame({ ...timeFrame, startTime: time });
      }
    }
  }
  function resetTime() {
    setTimeFrame({ startTime: "", endTime: "" });
  }

  useEffect(() => {
    form.setValue("category", category || "");
  }, [category, form]);

  const selectCategory = (value: string) => {
    setCategory(value);
  };

  const { ImageUrl, imageLoader, handleFileUpload, setImageUrl, setDirectory } =
    useFileUploadHandler();
  function onFileChange(e: any) {
    setDirectory(`${currentUser.businessName}/FoodImages`);
    const file = e.target.files[0];
    handleFileUpload(file);
  }

  const { mutateAsync: mutateAsyncAddMeal, isLoading } = useAddMeal();

  async function handleSubmit(data?: any) {
    const payload: IMeal = {
      name: form.getValues("name"),
      description: data.description,
      unitPrice: Number(data.price) * 100,
      category: data.category, // food_stack, meal_pack, meal_kit, pre_order
      preOrderSchedule: {
        days: preOrderDays,
        deliveryExpected: `Ready in ${readyIn}`,
        // "timeFrame": timeFrame
      },
      type: data.mealOptions, // main_meal, side_meal
      tags: data.tag ? [data.tag.toLowerCase()] : [],
      image: {
        url: ImageUrl,
      },
    };
    if (data.category !== "pre_order") {
      delete payload.preOrderSchedule;
    }
    if (!data.tag) {
      delete payload.tags;
    }
    payload.vendorId = currentUser._id;
    try {
      await mutateAsyncAddMeal(payload, {
        onSuccess: (response: any) => {
          navigate(-1);
          toast.success("Meal Uploaded Successfully", { icon: Success });
        },
      });
    } catch (error) {
      errorLogger(error);
    }
  }

  return (
    <main className="p-5">
      {/* header */}
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Add Menu</h1>
      </div>

      {/* add form */}
      <Form {...form}>
        <form
          className="mt-5"
          onSubmit={form.handleSubmit((data) => handleSubmit(data))}
        >
          <div className="flex flex-col gap-4">
            {/*  */}
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">Food Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Rice and Beans"
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Price */}
            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">Price</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="₦1,500"
                      type="number"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Category */}
            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Category</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Choose Food Category"
                      readOnly
                      type="text"
                      onClick={() => {
                        setIsCategoryOpen(!isCategoryOpen);
                      }}
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <AnimatePresence initial={false} mode="wait">
              {isCategoryOpen && (
                <MenuCategory
                  closeCategory={() => {
                    setIsCategoryOpen(false);
                  }}
                  selectCategory={(value) => {
                    form.setValue("category", value || "");
                    selectCategory(value);
                  }}
                ></MenuCategory>
              )}
            </AnimatePresence>

            {/* Main || Side meal */}
            {category === "food_stack" && (
              <FormField
                control={form.control}
                name="mealOptions"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormControl>
                      <div className="flex items-center justify-between gap-x-10">
                        {/* main meal */}
                        <label
                          htmlFor="main_meal"
                          className="flex items-center justify-between basis-0 grow rounded-xl bg-[#F4F4F4] py-1 px-2"
                        >
                          <span>Main Meal</span>
                          <div
                            onClick={() => {
                              setMealOption("main_meal");
                              form.setValue("mealOptions", "main_meal");
                            }}
                          >
                            {mealOption === "main_meal" ? (
                              <RiCheckboxCircleFill
                                size={24}
                                className="text-primary-100"
                              />
                            ) : (
                              <RiCheckboxBlankCircleLine
                                size={24}
                                className="text-gray-300"
                              />
                            )}
                          </div>
                        </label>
                        {/* side meal */}
                        <label
                          htmlFor="side_meal"
                          className="flex items-center justify-between basis-0 grow rounded-xl bg-[#F4F4F4] py-1 px-2"
                        >
                          <span>Side Meal</span>
                          <div
                            onClick={() => {
                              setMealOption("side_meal");
                              form.setValue("mealOptions", "side_meal");
                            }}
                          >
                            {mealOption === "side_meal" ? (
                              <RiCheckboxCircleFill
                                size={24}
                                className="text-primary-100"
                              />
                            ) : (
                              <RiCheckboxBlankCircleLine
                                size={24}
                                className="text-gray-300"
                              />
                            )}
                          </div>
                        </label>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {/* Day */}
            {category === "pre_order" && (
              <FormField
                control={form.control}
                name="day"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>Day(s)</FormLabel>
                    <FormControl>
                      <div className="flex gap-x-3 items-center overflow-x-scroll no-scrollbar">
                        {weekDays.map((day, index) => (
                          <label
                            key={index}
                            className={`flex flex-col items-center border border-[#E9E9E9] py-3 px-4 rounded-lg ${preOrderDays.includes(day.day)
                              ? "bg-primary-100 text-white"
                              : ""
                              }`}
                            htmlFor={day.day}
                          >
                            <input
                              className="w-[0.1px] h-[0.1px] appearance-none"
                              id={day.day}
                              type="radio"
                              name={day.day}
                              value={day.day}
                              onChange={(e) => {
                                handleSelectedDay(e.target.value);
                              }}
                            />
                            <span
                              className={`text-[#999999]  ${preOrderDays.includes(day.day)
                                ? "!text-white"
                                : ""
                                }`}
                            >
                              {day.tag}
                            </span>
                          </label>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {/* time */}
            {category === "pre_order" && (
              <FormField
                control={form.control}
                name="time"
                render={({ field }) => (
                  <FormItem className="w-full mt-5">
                    <FormLabel>
                      {" "}
                      <div className="flex justify-between">
                        <div>
                          Available Time-frame{" "}
                          <span className="text-gray-300 text-xs">
                            ({timeFrame?.startTime ?? "10:00"} -{" "}
                            {timeFrame?.endTime ?? "18:00"})
                          </span>
                        </div>
                        {(timeFrame.startTime || timeFrame.endTime) && (
                          <button
                            type="button"
                            className="text-primary-100 text-xs"
                            onClick={resetTime}
                          >
                            Clear Selection
                          </button>
                        )}
                      </div>{" "}
                    </FormLabel>
                    <FormControl>
                      <div className="flex gap-x-3 items-center overflow-x-scroll no-scrollbar">
                        {time.map((t, index) => (
                          <label
                            key={index}
                            className={`flex flex-col items-center border border-[#E9E9E9] py-3 px-4 rounded-lg ${t === timeFrame.startTime &&
                              "bg-primary-100 text-white"
                              } ${t === timeFrame.endTime && "bg-black text-white"
                              }`}
                            htmlFor={String(index)}
                          >
                            <input
                              className="w-[0.1px] h-[0.1px] appearance-none"
                              id={String(index)}
                              type="radio"
                              name="time"
                              value={t}
                              onChange={(e) => {
                                handleTimeSelection(e.target.value);
                              }}
                            />
                            <span
                              className={`text-[#999999] ${t === timeFrame.startTime &&
                                "bg-primary-100 text-white"
                                } ${t === timeFrame.endTime && "bg-black text-white"
                                }`}
                            >
                              {t}
                            </span>
                          </label>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {/* Ready In */}
            {category === "pre_order" && (
              <div>
                <label className="text-[#0E0E0E] font-semibold mt-10">
                  Ready In
                </label>
                <div className="flex gap-x-3 mt-2 items-center overflow-x-scroll no-scrollbar">
                  {readyInTimes.map((time, index) => (
                    <button
                      key={index}
                      className={`flex justify-center items-center border border-[#E9E9E9]  whitespace-nowrap px-4 py-2  rounded-[30px] ${readyIn === time
                        ? "bg-primary-100 text-white"
                        : "text-[#999999]"
                        }`}
                      type="button"
                      onClick={() => {
                        setReadyIn(time);
                      }}
                    >
                      {time}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {/* meal Tag */}

            {mealOption === "side_meal" && (
              <>
                <FormField
                  control={form.control}
                  name="tag"
                  render={({ field }) => (
                    <FormItem className="w-full mt-5">
                      <FormLabel>Meal Tag</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Add meal Tag"
                          readOnly
                          type="text"
                          onFocus={() => {
                            setIsTagOpen(!isTagOpen);
                          }}
                          className="w-full mt-2 bg-grey-30 rounded-lg border-none"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <AnimatePresence initial={false} mode="wait">
                  {isTagOpen && (
                    <MealTag
                      closeTag={() => {
                        setIsTagOpen(false);
                      }}
                      selectTag={(value) => form.setValue("tag", value)}
                    ></MealTag>
                  )}
                </AnimatePresence>
              </>
            )}
            {/* Description */}
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel className="text-[#0E0E0E]">
                    Description (optional)
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Tell user about the food"
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* upload file */}
            <div>
              <label className="text-[#0E0E0E] font-semibold mt-10">
                Food Image
              </label>
              {ImageUrl ? (
                <div className="h-[200px]">
                  <img
                    src={ImageUrl}
                    className="rounded-2xl w-full h-full object-cover"
                    alt=""
                  />
                </div>
              ) : (
                <>
                  <label
                    className="mt-2 flex justify-center py-14 bg-[#F4F4F4] rounded-2xl"
                    htmlFor="picture"
                  >
                    {imageLoader ? <FaSpinner /> : <GalleryImport />}
                  </label>
                  <input
                    id="picture"
                    type="file"
                    accept="image/*"
                    className="w-[0.1px] h-[0.1px] p-0 m-0"
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                  />
                </>
              )}
            </div>
            {ImageUrl && (
              <button
                type="button"
                onClick={() => {
                  setImageUrl("");
                }}
                className="border border-red-500 bg-white text-red-500 p-3 w-full mt-3 rounded-lg"
              >
                Cancel
              </button>
            )}
          </div>

          <Button
            type="submit"
            disabled={isLoading || imageLoader}
            className={`mt-10 w-full h-12 py-2 px-3 rounded-xl  ${isLoading || imageLoader
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-primary-100"
              }  text-white`}
          >
            {isLoading ? <FaSpinner /> : "Upload Product"}
          </Button>
        </form>
      </Form>
    </main>
  );
};
