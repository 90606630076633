import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import homelogo from "../../assets/Svg/homelogo.svg";
import { Link } from "react-router-dom";


function Navigation({ isNavOpen, close}:{close: () => void, isNavOpen:boolean
}) {
  return (
    <div
      className={`${
        isNavOpen ? "inset-0" : "-left-[10000px]"
      } duration-500 ease-in-out bg-black/70 z-[10] inset-y-0 h-screen w-screen fixed`}
    >
      <div className="duration-500 top-0  bg-white z-[100000] w-full hero_bg px-6 pb-5">
        <div className="flex justify-between">
          <div>
            <img
              src={homelogo}
              alt="logo"
              className="w-full h-full object-cover"
            />
          </div>

          <button
            onClick={close}
            className=""
          >
            <AiOutlineClose size={24} />
          </button>
        </div>
        <div title="header" className="mt-10 px-5 flex flex-col text-[#464255]">
          <div
            className="flex flex-col  space-y-8 "
            onClick={close}
          >
            <a href="#restaurant">Featured Restaurant</a>
            <a href="#service">Service</a>
            <a href="#download">Download App</a>

            <Link to="/auth/signin">Sign in</Link>
            <Link     
            to="/get-started/choose-user-type"
            rel="noreferrer"
            className="text-white w-max flex space-x-3 items-center bg-primary-100 rounded-md md:px-8 px-4 md:py-3 py-2"
            >
              Sign Up
            </Link>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
