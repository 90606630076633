import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "Redux/reducer";
import { BottomNavLink } from "../../../../constants/icon";
import { cn } from "../../../../lib";

export function BottomNav() {
  const { pathname } = useLocation();
  const { cartItems } = useSelector(
    (state: RootState) => state.cart
  );
  //
  return (
    <ul className="w-full bg-white max-w-[600px] mx-auto flex items-center justify-between fixed bottom-0 inset-x-0 px-6 py-3 border-t shadow">
      {BottomNavLink.map(({ name, link, Icon }) => (
        <div className="relative">
          {name.toLowerCase() === 'order' && cartItems.length > 0 &&
            <span className="absolute bg-[#578735] text-white font-normal p-1 size-[12px] rounded-full right-2 top-0 flex items-center justify-center text-[10px]">
              {cartItems.length}
            </span>
          }
          <li className="flex ">
            <Link
              key={name}
              to={link}
              className="flex flex-col items-center justify-center gap-y-1"
            >
              <Icon
                color={pathname.includes(String(link)) ? "#7BBE4A " : " #999999"}
              />
              <span
                className={cn(
                  "text-[#999999]",
                  pathname === link && "text-[#7BBE4A]"
                )}
              >
                {name}
              </span>
            </Link>
          </li>
        </div>
      ))}
    </ul>
  );
}
