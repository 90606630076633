import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';

const ShareButtons = ({ url, title, }: { url: string; title: string }) => {
    return (
        <div className='flex space-x-2 bg-white rounded-lg p-1'>
            <WhatsappShareButton url={url}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <FacebookShareButton
                url={url}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton
                url={url}
                title={title}
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>


        </div>
    );
};

export default ShareButtons;
