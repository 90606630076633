import React from "react";
import chatarrow from "../../assets/Svg/chatarrow.svg";
import { useNavigate } from "react-router-dom";

function TermOfUse() {
  const navigate = useNavigate();
  return (
    <div className="p-4 mt-6">
      <div className="flex items-center justify-between space-x-2 mb-6">
        <img
          onClick={() => {
            navigate(-1);
          }}
          src={chatarrow}
          alt="chat"
        />
        <h1 className="text-center text-primary-100 font-medium ">
          Terms Of Use
        </h1>
        <div></div>
      </div>

      <h1 className="font-bold my-4">
        Terms of Use for Logistics Partners and Delivery Managers
      </h1>

      <h1 className="font-semibold my-4"> 1. Introduction</h1>
      Welcome to JekaEat. These Terms of Use ("Terms") govern your use of the JekaEat platform as a logistics partner or individual food delivery manager ("You", "Your", or "Partner"). By accessing or using the JekaEat platform, you agree to be bound by these Terms.
      <h1 className="font-semibold my-4"> 2. Definitions </h1>
      <ul>
        <li> JekaEat Platform: Refers to the web and mobile applications developed by JekaEat to facilitate the delivery of fresh agricultural produce and food. </li>
        <li> Logistics Partner: Companies providing motorbikes, vans, trucks, bicycles, or any automobile meeting JekaEat’s standards for food and fresh farm produce delivery. </li>
        <li> Delivery Manager: Individual freelance riders listed on JekaEat for delivering food and fresh produce. </li>
        <li> API: Application Programming Interface allowing partners to integrate their technology with JekaEat’s platform. </li>
      </ul>
      <h1 className="font-semibold my-4"> 3. Eligibility and Registration </h1>
      To use the JekaEat platform, partners must:
      <ul>
        <li> Be legally capable of entering into binding contracts. </li>
        <li> Provide accurate and complete information during registration. </li>
        <li> Maintain the confidentiality of their account credentials. </li>
      </ul>
      <h1 className="font-semibold my-4"> 4. Services </h1>
      JekaEat provides a platform for:
      <ul>
        <li>Connecting farmers, restaurants, and food vendors with consumers.</li>
        <li> Enabling logistics partners and delivery managers to deliver food and fresh farm
          produce.</li>
      </ul>

      <h1 className="font-semibold my-4"> 5. Use of Technology </h1>
      <ul><li>Partners may use the JekaEat App directly or integrate their systems via JekaEat's API.</li>
        <li>JekaEat provides a dashboard for partners to manage their deliveries.</li>
        <li>JekaEat reserves the right to block any non-authorized manual technology or
          tools from the platform.</li></ul>
      <h1 className="font-semibold my-4"> 6. Responsibilities and Obligations </h1>
      Terms of Use for Logistics Partners and Delivery Managers
      For Logistics Partners:
      <ul>
        <li>Ensure that all vehicles used meet JekaEat’s standards for transporting food and fresh produce.</li>
        <li>Maintain the vehicles in good working condition.</li>
        <li>Comply with all relevant laws and regulations regarding transportation and
          delivery.</li>
      </ul>
      For Delivery Managers:
      <ul>
        <li>Ensure timely and safe delivery of food and produce to customers’ doorsteps.</li>
        <li>Maintain a professional demeanor and adhere to customer service standards.</li>
        <li>Use JekaEat’s tracking and reporting tools effectively.</li>
      </ul>
      <h1 className="font-semibold my-4"> 7. Training and Collaboration: </h1>
      JekaEat will provide training and tracking tools to logistics partners in collaboration to ensure high-quality service delivery. Partners are expected to participate in these training sessions and utilize the provided tools.
      <h1 className="font-semibold my-4"> 8. Fees and Payments </h1>
      <ul><li>Partners will be compensated as per the agreement outlined during the registration process.</li>
        <li>JekaEat reserves the right to modify the fee structure with prior notice.</li></ul>
      <h1 className="font-semibold my-4"> 9. Termination </h1>
      JekaEat may terminate or suspend access to the platform for any partner that breaches these Terms or engages in conduct that JekaEat deems harmful to its operations or reputation.
      <h1 className="font-semibold my-4"> 10. Limitation of Liability </h1>
      JekaEat will not be liable for any indirect, incidental, or consequential damages arising from the use of the platform. Partners are responsible for ensuring compliance with all applicable laws and regulations.
      <h1 className="font-semibold my-4"> 11. Governing Law</h1>
      These Terms will be governed by and construed in accordance with the laws of Nigeria.
      <h1 className="font-semibold my-4"> 12. Amendments</h1>

      <p> Terms of Use for Logistics Partners and Delivery Managers</p>
      <p>JekaEat reserves the right to amend these Terms at any time. Partners will be notified of any significant changes.</p>
      <h1 className="font-semibold my-4"> 13. Contact Information </h1>
      For any questions or concerns regarding these Terms, please contact JekaEat support.
      <a href="mailto:Corporate.communications@jekaeat.io">Corporate.communications@jekaeat.io</a> - <a href="mailto:Support@jekaeat.io">Support@jekaeat.io</a> - <a href="mailto:Jekaeatcorporate.communications@gmail.com">Jekaeatcorporate.communications@gmail.com</a>
      Check support & FAQ of the app to reach us on our social media Acceptance
      By using the JekaEat platform, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.
      <p className="my-4">Effective Date: July 2024</p>
      <p className="my-4"> Signature: JekaEat Nigeria Limited </p>
      <p className="my-4">Name: JekaEat Nigeria Limited</p>
      <p>Please review these Terms carefully. If you have any questions or need further clarification, feel free to contact us</p>
    </div>
  );
}

export default TermOfUse;
