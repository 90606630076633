import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";

function Privacy() {
  const navigate = useNavigate();
  return (
    <div className="p-4 mt-6">
      <div className="flex items-center justify-between space-x-2 mb-6">
        <ArrowLeft
          onClick={() => {
            navigate(-1);
          }}
          size={20}
        />
        <h1 className="text-center text-primary-100 font-medium ">
          PRIVACY POLICY
        </h1>
        <div></div>
      </div>

      <p className="tracking-wider">
        This website is a product of Jekaeat Ltd, a company limited by shares,
        registered under the Nigerian Companies and Allied Matters Act, 2020. At
        Jekaeat Ltd (described as “Jekaeat,” “we,” “our,” "us"), we care about
        your personal data, so we have prepared this Privacy Policy to explain
        how we collect, use, and share the data you provide us when you use our
        website, mobile application, and the services we provide you through
        them (collectively described as “our service”).
      </p>
      <p className="tracking-wider">
        This Privacy Policy applies to your use of our service, and it details
        the personal data our service collects about you, how we process it and
        your rights and obligations in relation to your personal data. By using
        or accessing our service, you agree to the terms of this Privacy Policy
      </p>

      <div className="text-primary-100 my-6">
        <h1 className="font-bold">TABLE OF CONTENTS</h1>

        <p> Introduction</p>
        <p> 1. Collection of Data</p>
        <p> 2. How we Use Your Data</p>
        <p> 3. How We Share Your Share Your In</p>
        <p> 4. Your Choices and Obligations</p>
        <p> 5. Other Important Information</p>
      </div>

      <h1 className="font-medium mt-8 text-primary-100">Introduction</h1>
      <p className="tracking-wider">
        JekaEat is a food technology, supply chain, logistics, and delivery platform start-up
        company segmented into <span className="font-bold">“Ready To Cook”</span> (fresh farm & agricultural produce delivery
        services) targeted at southern Nigeria from the North and <span className="font-bold"> “Order For Food”</span> (Food
        ordering and delivery to doorstep) targeted at northern Nigeria.
        JekaEat is established to address the challenges facing fresh agricultural produce and
        food accessibility by providing fresh agricultural produce as Harvest pack & meal kits,
        food ordering, logistics, and delivery service platform in Nigeria using web & mobile app
        technology to solve the lack of a reliable platform for farmers, restaurants, and food
        vendors to sell their food and connect with more food buyers & diners in the cities with
        urban consumers.
        The use of our service requires registration which involves the submission of your personal
        data to create an account which can either be a vendor account, rider (logistics
        partner - freelance rider) or a customer account. By creating an account on our
        service, you become a registered member of our community (“Members”), which
        means you have access to our services, depending on the account you have created.
        Visitors on our app who are not registered members (“Non-members”) have very limited
        or no access to our service.
      </p>

      <h1 className="font-medium mt-8 text-primary-100">
        Changes to this Privacy Policy
      </h1>
      <p className="tracking-wider">
        We may update our Privacy Policy to reflect changes to our information
        practices. If we do this and the changes are material, we will post a
        notice that we have made changes to this PrivacyPolicy on our service
        before the changes are made, and we will indicate the date these terms
        were last revised.
      </p>

      <div className="my-5 text-primary-100">
        <h1>1. Data Collection</h1>
        <h1> 1.1 Data you provide us</h1>
        <h1 className="mt-4">Registration Data</h1>
      </div>

      <p className="tracking-wider">
        To become a registered member of our service, you need to provide us
        with your data.
        <p className="font-bold">Customer account</p>
        <ul>
          <li>First Name</li>
          <li>Last Name</li>
          <li>Display Name</li>
          <li>E-mail address</li>
          <li>Password</li>
          <li>Phone Number</li>
        </ul>
        <p className="font-bold">Vendor account</p>
        <ul>
          <li>First Name</li>
          <li>Last Name</li>
          <li>Shop Name</li>
          <li>E-mail address</li>
          <li>Password</li>
          <li>Store Address (Street, City, Zip Code, State, and
            Country)</li>
          <li>Product Name</li>
          <li>Price</li>
          <li>Category</li>
          <li>Product Picture</li>
          <li> Product description</li>
        </ul>

      </p>

      <h1 className="font-medium mt-8 text-primary-100">
        Product Research and Development Data
      </h1>
      <p className="tracking-wider">
        We may contact you to participate in product research activities from
        time to time. These may include surveys, interviews, and other types of
        feedback sessions. When you participate in these research activities,
        you may provide us with data you which will be used to test, improve,
        and develop our products. We will record the video, audio, and text
        transcriptions of data you may provide, including feedbacks, together
        with any additional contact information you may provide.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">1.2 Cookies</h1>
      <p className="tracking-wider">
        We use cookies to collect information. A "cookie" is a small piece of
        information stored by a Web server on a Web browser so it can be later
        read back from that browser. Cookies are useful for enabling the browser
        to remember information specific to a given user. Jekaeat.com places
        both permanent and temporary cookies in your computer's hard drive. Our
        cookies do not contain any of your personally identifiable information
        or personal data. We use technology in the collecting the information in
        order to make our websites and other content or services which we make
        available to you more interesting and useful to you. For instance, when
        you come to one of our websites via your computer or other media device,
        we may combine your visitor session information or other information
        collected through cookies, web beacons and other technical methods with
        personally identifiable information in order to understand and improve
        your online experiences and to determine what products, promotions, and
        services you prefer or are likely to be of interest to you.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">
        1.3 Data from children under the age of digital consent
      </h1>
      <p className="tracking-wider">
        We do not knowingly collect personally identifiable data from anyone
        under the age of 18. If you are a parent or guardian and you are aware
        that your child has provided us with personal data, please contact us.
        If we become aware that we have collected personal data from anyone
        under the age of 18 without verification of parental consent, we take
        steps to remove that data from our servers. If we need to rely on
        consent as a legal basis for processing your information and your
        country requires consent from a parent, we may require your parent's
        consent before we collect and use that information.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">
        1.7 How do we store your personal data?
      </h1>
      <p className="tracking-wider">
        The personal information we collect from you through our platform is
        stored within the Jekaeat Ltd Ecosystem and they are processed at our
        office in Nigeria. We protect your data using physical, technical, and
        administrative security measures to reduce the risks of loss, misuse,
        unauthorised access, disclosure, and alteration. Some of the safeguards
        we use are firewalls and data encryption, physical access controls,
        information access authorisation controls. In the event of an actual or
        suspected data breach capable of causing harm to your rights and
        freedoms, we will notify you without undue delay and use our best effort
        to remedy the violation within one (1) month from the date we notify
        you.
      </p>

      <div className="my-5 text-primary-100">
        <h1>2. How we Use Your Data</h1>
        <h1 className="mt-4">2.1 Our Services</h1>
      </div>

      <h1 className="font-medium mt-8 text-primary-100">
        2.2 Communications, Service Development and Research
      </h1>
      <p className="tracking-wider">
        We may occasionally send you service related and product change
        announcements through the general operation of our service such as
        reminder notifications. We may use or share anonymous data collected
        through our service, including activity data, without limitation. As a
        business, it is critical that we perform our contract with you with the
        best service possible, and it is in our legitimate interests to process
        your data and enable service emails by default to provide you with
        necessary information about our services from time to time. You may opt
        out of any non-essential service e-mails at any time.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">2.4 Marketing</h1>
      <p className="tracking-wider">
        We may also use your contact information to send you notifications
        regarding new services, offers and promotions offered by Jekaeat if you
        affirmatively consent to receive such communications.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">2.5 Customer Support</h1>
      <p className="tracking-wider">
        Profile information is used by Jekaeat to be presented back to and
        edited by you when you access our service. Jekaeat uses this information
        to provide you with support, to send you essential notifications, to
        enforce our terms, conditions, and policies, to communicate with you, to
        administer our service, and for internal operations, including
        troubleshooting, data analysis, testing, research, statistical, and
        survey purposes.
      </p>

      <div className="my-5 text-primary-100">
        <h1>3. How We Share Your Share Your Information</h1>
        <h1> 3.1 Our Services</h1>
        <h1 className="mt-4">Profile</h1>
      </div>
      <p className="tracking-wider">
        When you register on our services with a Vendor account, Members of our
        community can view your profile subject your profile setting. Your
        profile can also be visible to other customers, non-members and visitors
        using third- party search engines. The level visibility of your profile
        depends on factors such as your setting, access channel, and search
        types.
      </p>

      <h1 className="font-medium mt-8 text-primary-100">
        3.2 Sharing your personal data with third parties
      </h1>
      <p className="tracking-wider">
        Jekaeat shares your personal data only when it is necessary to offer the
        service, legally required, or permitted by you. We will provide personal
        data to hosting providers, web service providers, search engine
        providers and support providers. These data processors help us bring you
        the service. For example, we may share your information in order to
        detect where or how you encountered a bug when using our mobile
        application. In connection with these operations, our service providers
        will have access to your personal data for a limited time. When we
        utilize service providers for processing any personal data, we implement
        contractual protections limiting the use of that personal data to the
        provision of services to Jekaeat. We will be required to access and
        disclose personal data in response to lawful requests, such as subpoenas
        or court orders, or in compliance with applicable laws. Additionally, we
        will access and share account or other personal data when we believe it
        is necessary to comply with law, to protect our interests or property,
        to prevent fraud or other illegal activity perpetrated through our
        service or using the Jekaeat name, or to prevent imminent harm. This
        will include accessing and sharing personal data with other companies,
        lawyers, agents, or government agencies. If the ownership of all or
        substantially all of the Jekaeat business, or individual business units
        or assets owned by Jekaeat that are related to our service, were to
        change, your personal data will be transferred to the new owner. In any
        such transfer of information, your personal data would remain subject to
        this section.Jekaeat will share aggregate or anonymous data collected
        through our service, including Activity Data, for purposes such as
        understanding or improving the service.
      </p>

      <h1 className="font-medium mt-8 text-primary-100">
        3.4 Third party websites and links
      </h1>
      <p className="tracking-wider">
        Please note that you may have cookies placed on your computer by third
        party websites that refer you to our service. Although we do not share
        your personal data with these third party websites unless it is
        reasonably necessary to offer the service, they may be able to link
        certain non-personally identifiable information we transfer to them with
        personal data they previously collected from you. Please review the
        privacy policies of each website you visit to better understand their
        privacy practices. In addition, Jekaeat would like to inform you that
        anytime you click on links, which take you to third party websites, you
        will be subject to the third parties’ privacy policies.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">3.5 Legal Disclosures</h1>
      <p className="tracking-wider">
        We may need to disclose your data to an entity to which we are under a
        duty to disclose or share your information in order to complywith any
        legal obligation, or in order to enforce or apply our Terms and other
        legal requirements; or to protect our rights, property, or safety, our
        users, or others. This includes exchanging information with other
        companies and organizations for the purposes of fraud protection and
        credit risk reduction.
      </p>

      <div className="my-5 text-primary-100">
        <h1>4. Your Choices and Obligations</h1>
        <h1> 4.1 Data Retention</h1>
      </div>
      <p className="tracking-wider">
        Jekaeat will retain your data for as long as your account is open and
        until your account is deleted, after which point, we will retain
        anonymous data collected through our service, including Activity Data,
        which may be used by Jekaeat and shared with third parties in any
        manner.
      </p>

      <h1 className="font-medium mt-8 text-primary-100">
        4.2 Your rights as a data Subject
      </h1>
      <p className="tracking-wider">
        As a data subject, you have the right under this Privacy Policy and by
        law to manage your account settings at the settings on our service. You
        also have the following rights in relation to the personal data we hold
        about you, unless provided otherwise by local law:
        <ol>
          <li>
            To request access to, or erasure of, the personal data we hold about
            you.
          </li>
          <li>
            To request us to restrict the processing of the personal data we
            hold about you.
          </li>
          <li>To object to us processing personal data relating to you.</li>
          <li>
            Where you have given us consent to process your personal data, you
            have the right to withdraw that consent at any time.
          </li>
          <li>
            To export the personal data you have provided to Jekaeat in a format
            that can be transferred electronically to a third party.
          </li>
          <li>
            To delete your account with Jekaeat by following the instructions
            available through our service.
          </li>
        </ol>
        Please note that some of these rights are not absolute. In some cases,
        we may refuse a request to exercise particular rights if complying with
        it means that we are no longer able to meet our contractual obligation
        to provide you with particular products and services. We will keep you
        informed as to the actions that we can take when you make your request.
      </p>

      <div className="my-5 text-primary-100">
        <h1>5. Other Important Information</h1>
        <h1 className="mt-4">5.1 Data Security</h1>
      </div>

      <p className="tracking-wider">
        Jekaeat has implemented administrative and technical safeguards it
        believes are appropriate to protect the confidentiality, integrity and
        availability of your personal data, Testing ID, User Photo, and access
        credentials. However, given sufficient resources, a determined attacker
        could defeat those safeguards and may, as a result, gain access to the
        data we seek to protect. In the unfortunate event of a data breach, we
        have put in place internal breach detection, investigation and reporting
        procedures and if the breach is likely to result in a high risk of
        adversely affecting any of your right as a data subject, we shall notify
        you of this breach within a reasonable period of time. Also, if there is
        any violation or likely violation of this privacy policy or any of your
        rights as a data subject under this privacy policy, you can contact our
        data compliance officer (see data compliance officer below) and such
        violation will be remedied within 14 days of complaint.
      </p>

      <h1 className="font-medium mt-8 text-primary-100">
        5.2 Data processing Principles
      </h1>
      <p className="tracking-wider">
        The principles below guide our use of your personal data:
        <ol>
          <li>
            Your personal data will be processed in a lawful, fair, and
            transparent manner.
          </li>
          <li>
            Your personal data will be processed for a specific purpose and not
            in a way that is incompatible with the purpose for which it is
            collected.
          </li>
          <li>
            Processing of your personal data will be adequate, relevant and
            limited to what is necessary for the purposes it is processed.
          </li>
          <li>
            Your personal data will be kept accurate and, where necessary, kept
            up to date.
          </li>
          <li>
            Your personal data will be held for no longer than it is required
            for the purposes for which it is processed.
          </li>
          <li>Your personal data will be kept secure.</li>
        </ol>
      </p>
      <h1 className="font-medium mt-8 text-primary-100">
        5.3 Lawful Bases for Data Processing
      </h1>
      <p className="tracking-wider">
        Where the use of your personal data is necessary to perform our
        obligations under any contract with you (including providing you with
        access to Our Services for which you have registered and/or made a
        requested).
        <ol>
          <li>
            Where the use of your personal data is necessary to perform our
            obligations under any contract with you (including providing you
            with access to Our Services for which you have registered and/or
            made a requested).
          </li>
          <li>
            Where we have your explicit consent to collect, store, and use your
            data for a particular purpose, including where you consent to
            receiving marketing and promotional communications for products on
            our app or third party websites.
          </li>
          <li>
            Where use of your personal information is necessary for purposes of
            pursuing Jekaeat’s legitimate interests or the legitimate interests
            of a third party, as long as these interests are not outweighed by
            your own interests, fundamental rights, or freedoms.
          </li>
        </ol>
      </p>

      <h1 className="font-medium mt-8 text-primary-100">5.4 Links on the website</h1>
      <p className="tracking-wider">
        The app may contain links to other websites. We are not responsible for
        the privacy practices of other websites. We encourage users to be aware
        when they leave our service to read the privacy statements of other
        websites that collect personally identifiable information. This Privacy
        Policy applies only to information collected by Jekaeat via our service.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">5.5 Contact Us</h1>

      <p className="tracking-wider">
        For data privacy inquiries, contact us at <a className="text-blue-600" href="mailto:corporate.communications@jekaeat.io">corporate.communications@jekaeat.io</a> . For
        support, visit support and FAQ in settings under your profile.
      </p>
      <h1 className="font-medium mt-8 text-primary-100">
        5.6 Data Compliance Officer
      </h1>
      <p className="tracking-wider">
        You can also contact our Data Compliance Officer on
        <a className="text-blue-600" href="mailto:support@jekaeat.io">support@jekaeat.io</a> and Phone:
        +234 802 313 9125 or use this form to submit a question.
      </p>

      <h1 className="font-medium mt-8 text-primary-100">Changes to This Privacy Policy
      </h1>
      <p className="tracking-wider">
        We may update this Privacy Policy to reflect changes in our practices. We will notify you
        of any material changes and indicate the last revision date.
      </p>
      <p className="tracking-wider">
        <h1 className="font-medium mt-8 text-primary-100">Jekaeat Ltd</h1>
        <p> 2, Seriki Street, Off Odunsi, Bariga, Lagos, Nigeria - PMB 65, Federal University Of
          Technology Minna, Niger State.</p>

        <p><span className="font-bold">Phone</span>: +234 818 4583 454</p>
        <p><span className="font-bold">Data Privacy Inquiries</span>: <a href="mailto:corporate.communications@jekaeat.io">corporate.communications@jekaeat.io</a> or <a href="mailto:support@jekaeat.io">support@jekaeat.io</a> </p>
        <p><span className="font-bold">Support</span>: Jekaeat Support - check settings under your profile</p>


        <p className="mt-8">This comprehensive Privacy Policy aims to ensure transparency and trust, detailing how
          Jekaeat Ltd handles your personal data while using our services.</p>
      </p>

    </div>
  );
}

export default Privacy;
