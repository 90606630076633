import illustration from "../../../assets/Svg/onboarding2.svg";
import { motion } from "framer-motion";
import { pageAnimation } from "../../../utils/pageTransition";

const Onboarding2 = () => {
  return (
    <motion.div
      variants={pageAnimation}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      className="w-full sm:max-w-[600px] mx-auto px-5 pt-10  flex flex-col  items-center bg-white"
    >
      <div className="my-10">
        <img
          src={illustration}
          alt="Get Fresh Produce and Meal s"
          className="h-[300px]"
        />
      </div>
      {/* onboarding step indicator */}
      <div className="mt-10 w-56 flex gap-2 items-center">
        <div className="h-1 bg-grey-30 rounded-full grow"></div>
        <div className="h-1 bg-primary-100 rounded-full basis-16 grow"></div>
        <div className="h-1 bg-grey-30 rounded-full grow"></div>
        <div className="h-1 bg-grey-30 rounded-full grow"></div>
      </div>
      <div className="my-5">
        <h1 className="px-5 text-2xl font-bold text-center text-grey-900">
          Stress-Free Ready To Cook Services🍅 🛒
        </h1>
        <p className="text-center text-grey-200 text-sm">
          Skip the foodstuff market wahala! Save time and money. Order your cooking ingredients, just a tap away & enjoy quick doorstep delivery to make your next meal!
        </p>
      </div>
    </motion.div>
  );
};

export default Onboarding2;
