import { ArrowLeft, Edit2 } from "iconsax-react";
import { useNavigate } from "react-router";
import coverImage from "../../../assets/images/restaurantBg.jpg";
import ngflag from "../../../assets/Svg/ngflag.svg";

import { Input } from "../../composables";
// import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { landmarks } from "utils/AlphaData";
import { RootState } from "Redux/reducer";

const RestaurantDetails = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [coverImgURL] = useState(currentUser?.profileImage?.url ?? coverImage);
  const navigate = useNavigate();

  let landMarks: { label: string; value: string }[] = [];
  // eslint-disable-next-line array-callback-return
  landmarks.map((v) => {
    landMarks.push({ label: v.location, value: v.zone });
  });
  return (
    <main>
      {/* Header */}
      <div
        className="fixed z-0 top-0 inset-x-0 h-72 pt-5 px-5 text-white bg-center bg-cover bg-blend-overlay"
        style={{
          backgroundImage: `url(${coverImgURL}) , linear-gradient(#00000066, #00000000)`,
        }}
      >
        <div className="mt-5 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <div className="h-8 w-8 flex items-center justify-center rounded-full bg-white">
                <ArrowLeft color="#292D32" size={20} />
              </div>
            </button>
            <h1 className="font-semibold">Edit Vendor Details</h1>
          </div>
          <Link to={"/vendor/edit-profile"}>
            <div className="h-8 w-8 flex items-center justify-center rounded-full bg-white">
              <Edit2 color="#292D32" size={20} />
            </div>
          </Link>
        </div>
      </div>

      {/* Details */}
      <div className="mt-72 px-5 z-20 bg-white relative py-10 bottom-12 rounded-[36px]">
        {/* Profile Image */}
        <div
          className="absolute top-0 -translate-y-1/2 right-1/2 translate-x-1/2 w-20 h-20 rounded-full overflow-hidden bg-center bg-cover"
          style={{ backgroundImage: `url(${coverImgURL})` }}
        ></div>

        {/* Profile edit form */}

        <form className="mt-5">
          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Vendor Name</label>
            <Input
              value={currentUser?.businessName ?? ""}
              type="text"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              readOnly
            />
          </div>

          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Email</label>
            <Input
              value={currentUser?.email ?? ""}
              type="email"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              readOnly
            />
          </div>

          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Location</label>
            <Input
              value={currentUser?.location ?? "Address"}
              type="email"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              readOnly
            />
          </div>
          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Address</label>
            <Input
              value={currentUser?.address?.streetAddress ?? ""}
              type="email"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              readOnly
            />
          </div>
          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Landmark</label>
            <Input
              value={currentUser?.address.nearestLandmark?.location ?? ""}
              type="email"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              readOnly
            />
          </div>

          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Category</label>
            <Input
              value={currentUser?.category}
              type="text"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E] capitalize"
              readOnly
            />
          </div>

          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">CAC Number</label>
            <Input
              value={currentUser?.cacNumber}
              type="text"
              className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              readOnly
            />
          </div>

          <div className="w-full mt-5">
            <label className="text-xs text-[#999]">Phone Number</label>
            <div className="relative w-full h-10">
              <div className="absolute top-2 left-2">
                <img className="px-3 py-2" src={ngflag} alt="" />
              </div>
              <Input
                value={currentUser?.phoneNumber}
                type="tel"
                readOnly
                className="w-full mt-2 pl-14 pr-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
              />
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export { RestaurantDetails };
