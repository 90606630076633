import { motion } from "framer-motion";

type ItemCategoryProp = {
  closeCategory: () => void;
  selectCategory: (value: string) => void;
};
const ItemCategory = ({ closeCategory, selectCategory }: ItemCategoryProp) => {
  const categories = [
    {
      name: "Meal Kit",
      value: "meal_kit",
    },
    {
      name: "Meal Packs",
      value: "meal_packs",
    },
  ];
  const animate = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: "100%",
    },
  };
  return (
    <motion.div
      variants={animate}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed sm:max-w-[600px] mx-auto z-10 bottom-0 inset-x-0 py-10 px-5 bg-white border rounded-t-[40px] overflow-hidden"
    >
      <div className="fixed inset-x-0 top-3 -translate-x-1/2 left-1/2 w-14 h-1.5 rounded-full bg-grey-30"></div>
      <h2 className="font-bold mb-3">Category</h2>
      {categories.map((category, index) => (
        <label
          htmlFor={String(index)}
          key={index}
          className="my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center"
        >
          <p>{category.name}</p>

          <input
            id={String(index)}
            name="category"
            type="radio"
            value={category.name}
            onChange={() => selectCategory(category.value)}
            onClick={closeCategory}
          />
        </label>
      ))}
    </motion.div>
  );
};

export { ItemCategory };
