import { FaSpinner } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ItemsEntity } from "types/global.interface";
import { useAcceptOrder } from "../../../utils/api";
// import vendorbg from "assets/images/vendorbg.jpg";
import amala from "../../../assets/images/amala.png";
import { getNairaEquivalent } from "utils/helper";
import moment from "moment";
import { Success } from "assets/icon";
import { errorLogger } from '../../../utils/helper';

const Order = ({ order }: { order: ItemsEntity }) => {
  const { mutateAsync, isLoading } = useAcceptOrder();
  const handleOrderUpdate = async (orderId: string) => {
    try {
      await mutateAsync(orderId, {
        onSuccess: () => {
          toast.success("Order Accepted", {
            icon: Success,
          });
        },
      });
    } catch (error) {
      errorLogger(error)
    }
  }


  return (
    <Link
      to={`/rider/orders/${order._id}`}
      className="border border-grey-50 bg-[#fdfdfd] px-3 py-4 rounded-2xl"
    >
      <div className="flex justify-between">
        <div className="flex">
          {/* vendor img */}
          <div className="size-10 rounded-xl object-cover bg-gray-500">
            <img
              src={order?.vendor?.profileImage?.url ?? amala}
              className="w-full h-full rounded-xl object-cover"
              alt="vendor_image"
            />
          </div>
          {/* vendor name and order details */}
          <div className="ml-3 flex-col flex justify-between w-full">
            <h2 className="font-semibold">{order.vendor.businessName} </h2>
            <div className="flex items-center text-xs w-full">
              <div className="w-2 h-2 rounded-full bg-[#54DA8A] shadow-[0_0_1px_2px_#E6FAEE]"></div>
              <span className="px-2.5">
                Delivery: ₦{getNairaEquivalent(order.deliveryInfo.ridersFee).toFixed(2)}
              </span>
              {/* <span className="px-2.5">20 - 25 Min</span> */}
            </div>
          </div>
        </div>

        {/* price */}
        <div className="flex flex-col whitespace-nowrap">
          <h1 className="font-bold text-xl text-right">
            ₦{getNairaEquivalent(order.total)}
          </h1>

          <span className="text-xs ">
            {moment(order?.createdAt).format("llll")}
          </span>
        </div>
      </div>

      {/* Addresses */}
      <div className="mt-4 relative">
        {/* vendor address */}
        <div className="relative flex gap-3 pb-4">
          <div className="my-[3px] w-2.5 h-2.5 rounded-full border border-[#54DA8A] flex items-center justify-center">
            <div className="w-1.5 h-1.5 rounded-full bg-[#bababa]"></div>
          </div>
          <div>
            <h3 className="text-xs text-grey-200">Vendor Address</h3>
            <h4 className="font-semibold">
              {order?.vendor?.address?.streetAddress}
            </h4>
          </div>
          {/* line */}
          <div className="absolute w-[1px] bg-black left-1 top-4 -bottom-0"></div>
        </div>

        <div className="flex gap-3">
          <div className="my-[3px] w-2.5 h-2.5 rounded-full border border-primary-100 flex items-center justify-center">
            <div className="w-1.5 h-1.5 rounded-full bg-primary-100"></div>
          </div>
          <div className="truncate">
            <h3 className="text-xs text-grey-200">Customer Address</h3>
            <h4 className="font-semibold ">
              {" "}
              {order.deliveryInfo?.address?.streetAddress}{" "}
            </h4>
          </div>
        </div>
      </div>

      {/* Decline/Accept */}
      {order.status === "pending" && (
        <div className="mt-6 flex gap-5">
          <button className="py-3 grow basis-0 border-secondary-red border text-secondary-red rounded-xl">
            Decline
          </button>
          <button
            onClick={() => {
              handleOrderUpdate(order._id)
            }}
            className="py-3 grow basis-0 bg-primary-100 text-white rounded-xl"
          >
            {
              isLoading ? (
                <FaSpinner className="animate-spin" size={20} />
              ) : (
                "Accept"
              )
            }
          </button>
        </div>
      )
      }
    </Link >
  );
};

export default Order;
